import loadable from '@loadable/component';

export const TimerBar = loadable(() => import('./timer-bar/timer-bar'));
export const ComparisonTable = loadable(() => import('./comparison-table/comparison-table'));
export const Tooltip = loadable(() => import('./tooltip/tooltip'));
export const SEO = loadable(() => import('./seo/seo'));
export const PageContentWrapper = loadable(() => import('./layout/page-content-wrapper'));
export const ShapeWrapper = loadable(() => import('./shape-wrapper/shape-wrapper'));
export const AppsLinks = loadable(() => import('./apps-links/apps-links'));
export const AvailablePlatforms = loadable(() =>
  import('./available-platforms/available-platforms')
);
export const ContentBlockSmall = loadable(() => import('./content-blocks/content-block-small'));
export const TextBlock = loadable(() => import('./content-blocks/components/text-block'));
export const CardList = loadable(() => import('./card-list/card-list'));
export const DealsPlanInfoCard = loadable(() =>
  import('./deals/deals-plan-info-card/deals-plan-info-card')
);
export const DealsReviewCard = loadable(() =>
  import('./deals/deals-review-card/deals-review-card')
);
export const DealsTimer = loadable(() => import('./deals/deals-timer/deals-timer'));
export const DealsTimerRedesigned = loadable(() =>
  import('./deals/deals-timer-redesigned/deals-timer')
);
export const RatingStars = loadable(() => import('./rating-stars/rating-stars'));
export const ArrowLink = loadable(() => import('./arrow-link/arrow-link'));
export const Tabs = loadable(() => import('./tabs/tabs'));
export const TabsSlider = loadable(() => import('./tabs/tabs-slider'));
export const Loader = loadable(() => import('./loader/loader'));
export const LoaderWithText = loadable(() => import('./loader/loader-with-text'));
export const SocialShareLinks = loadable(() => import('./social-share-links/social-share-links'));
export const ServerListTable = loadable(() =>
  import('./servers/server-list-table/server-list-table')
);
export const BasePicker = loadable(() => import('./pickers/base-picker/base-picker'));
export const LanguagePicker = loadable(() => import('./pickers/language-picker/language-picker'));
export const CurrencyPicker = loadable(() => import('./pickers/currency-picker/currency-picker'));
export const CTAFeatures = loadable(() => import('./cta-features/cta-features'));
export const DoubleTextButton = loadable(() => import('./double-text-button/double-text-button'));
export const ChatWidget = loadable(() => import('./chat-widget/chat-widget'));
export const Flag = loadable(() => import('./flag/flag'));
export const BlackFridayBlogBanner = loadable(() =>
  import('./banners/black-friday-blog-banner/black-friday-blog-banner')
);
export const ChristmasBlogBanner = loadable(() =>
  import('./banners/christmas-blog-banner/christmas-blog-banner')
);
export const SummerBlogBanner = loadable(() =>
  import('./banners/summer-blog-banner/summer-blog-banner')
);
export const DefaultBlogBanner = loadable(() =>
  import('./banners/default-blog-banner/default-blog-banner')
);
export const PlanCard = loadable(() => import('./plan-picker/plan-card'));

export { Modal } from './modal';
export { IPBar } from './ip-bar';
export { Layout } from './layout';
export { LinkWrapper } from './link';
export { SectionContainerSC } from './section-container';
export { ImageCore, LOADING_OPTIONS } from './image';
export { ContentBlock } from './content-blocks';
export { Text } from './typography';
export { Table } from './table';
export { Icon, ArrowIcon } from './icon';
export { Animation } from './animations';
export { Divider } from './divider';
export { Shape } from './shape';
export { Card } from './cards';
export { StoreRatings, StoreRatingsWithStoreName, StoreRatingsWithCount } from './store-ratings';
export { Toast } from './toasts';
