export const TRACK_EVENTS = {
  category: {
    popUp: 'pop up',
    buyApp: 'buy app',
    downloadApp: 'download app',
    getPurchaseFunnel: 'GET purchase funnel',
  },
  action: {
    view: 'view',
    close: 'close',
    clickToBuy: 'click to buy',
    clickToDownload: 'click to download',
    selectedPlan: 'Plan selected',
    clickedPaymentMethod: 'Clicked on Payment method',
  },
  hitType: {
    pageView: 'pageview',
  },
};

export const GA4_EVENTS = {
  accordionSlide: (currentPage) => `accordion-slide-${currentPage}`,
  accordionTabClick: (item) => `accordion-tab-${item}-click`,
  appDownload: 'app_download',
  atlasLogoClick: 'atlas_logo_click',
  bannerClick: 'banner_click',
  bannerView: 'banner_view',
  benefitsItemClick: (item) => `benefits_${item}_click`,
  benefitsMainCtaClick: 'benefits_maincta_click',
  blogBannerGenericClick: 'blog_banner_generic_click',
  blogBannerSecurityClick: 'blog_banner_security_click',
  blogBannerSpeedClick: 'blog_banner_speed_click',
  blogBannerStreamingClick: 'blog_banner_streaming_click',
  buyAtlasVpnClick: 'buyatlasvpn_click',
  checkoutAddOnAddClick: 'checkout_add_on_add_click',
  checkoutAddOnSkipClick: 'checkout_add_on_skip_click',
  checkoutProceedClick: 'checkout_step1_proceed_click',
  checkoutStep1Click: 'checkout_step1_click',
  checkoutStep2Click: 'checkout_step2_click',
  checkoutStep3Click: 'checkout_step3_click',
  comparisonTableCtaClick: 'comparisontable_cta_click',
  cookieNoticeAccept: 'cookies_notice_accept',
  cookieNoticeCustomize: 'cookies_notice_customize',
  cookieNoticeView: 'cookies_notice_view',
  couponButtonClick: 'coupon_code_cta_click',
  couponClick: 'coupon_code_click',
  couponFocusOut: 'coupon_code_focus_out',
  crimeRatingTableExpandClick: 'crime-rating-table-expand-click',
  ctaClick: 'cta_click',
  currencyChange: 'currency_change',
  devicesBuyAtlasVpnClick: 'devices_buyatlasvpn_click',
  devicesCompatibleAndroidClick: 'devices_compatible_android_click',
  devicesCompatibleAndroidTvClick: 'devices_compatible_androidtv_click',
  devicesCompatibleAppleTvClick: 'devices_compatible_appletv_click',
  devicesCompatibleFireTvClick: 'devices_compatible_firetv_click',
  devicesCompatibleIOsClick: 'devices_compatible_ios_click',
  devicesCompatibleLinuxClick: 'devices_compatible_linux_click',
  devicesCompatibleMacOsClick: 'devices_compatible_macos_click',
  devicesCompatibleWindowsClick: 'devices_compatible_windows_click',
  downloadGetAtlasVpnClick: 'download_getatlasvpn_click',
  downloadPopUpClick: 'download_pop_up_click',
  emailFieldBlur: 'email_field_focus_out',
  emailFieldFocus: 'email_field_focus',
  experiencesTabClick: (item) => `experiences_tab_${item}_click`,
  faqItemClick: (item) => `faq_${item}_click`,
  featuresBlockCtaClick: 'features_block_cta_click',
  fieldFocus: (name) => `${name}_field_focus`,
  fieldFocusOut: (name) => `${name}_field_focus_out`,
  footerBuyAtlasVpnClick: 'footer_buyatlasvpn_click',
  footerCookiesPreferenceClick: 'footer_cookies_preference_click',
  footerCtaClick: 'footer_cta_click',
  footerPricingCtaClick: 'footerpricing_cta_click',
  footerPrivacyPolicyClick: 'footer_privacypolicy_click',
  footerReviewsClick: 'footer_reviews_click',
  footerTermsConditionsClick: 'footer_termsconditions_click',
  fullFeaturesClick: 'fullfeatures_click',
  getPremiumClick: 'getpremium_click',
  getVpnClick: 'get_vpn_click',
  infobarUnprotectedClick: 'infobar_unprotected_click',
  languageChange: 'language_change',
  learnMoreClick: 'learn_more_click',
  logoClick: 'logo_click',
  methodologyDescriptionClick: 'methodology-description-click',
  modalClose: 'popup_close',
  modalCTAClick: 'popup_cta_click',
  navbarBlogClick: 'navbar_blog_click',
  navbarCtaClick: 'navbar_cta_click',
  navbarFeaturesClick: 'navbar_features_click',
  navbarHelpClick: 'navbar_help_click',
  navbarLoginClick: 'navbar_login_click',
  navbarPricingClick: 'navbar_pricing_click',
  navbarVpnAppsClick: 'navbar_vpnapps_click',
  orderAddonAddClick: 'order_add_on_add_click',
  orderAddonRemoveClick: 'order_add_on_remove_click',
  pageNotExists: 'page_not_exists',
  paymentDetailsFocus: 'payment_details_focus',
  paymentDetailsFocusOut: 'payment_details_focus_out',
  paymentMethodClick: 'payment_method_click',
  periodPlanClick: (period) => `${period}_plan_click`,
  planClick: 'plan_click',
  plansDownloadClick: 'plans_download_click',
  purchaseClick: (paymentMethod) => `${paymentMethod}_purchase_click`,
  purchaseFail: 'purchase_fail',
  readMoreClick: 'readmore_click',
  securityAuditClick: 'securityaudit_click',
  serversCtaClick: 'servers_cta_click',
  stepsGetPremiumClick: 'steps_getpremium_click',
  stickyHeaderCtaClick: 'stickyheader_cta_click',
  successfulPurchase: 'successful_purchase',
  supportBubbleClick: 'support_bubble_click',
  tabClick: (label) => `${label}_tab_click`,
  waysToUseVpnClick: 'waystousevpn_click',
};

export const ERROR_TYPES = {
  stripePay: 'stripe/pay',
  payPal: 'paypal',
};

export const APP_PARAMS = {
  appleTv: { app: 'appleTv' },
  windows: { app: 'windows' },
  macOS: { app: 'macOS' },
  linux: { app: 'linux' },
  android: { app: 'android' },
  iOS: { app: 'iOS' },
  androidTv: { app: 'androidTv' },
  fireTv: { app: 'fireTv' },
  universalLink: { app: 'universal_link' },
};
