import theme from './theme';

const blogTheme = {
  ...theme,
  fonts: {
    ...theme.fonts,
    title: theme.fonts.default,
  },
};
export default blogTheme;
