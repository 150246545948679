import React from 'react';
import styled, { css } from 'styled-components';
import { layout } from 'styled-system';

import { filteredProps, query } from '@shared/style-helpers';

export const QUERIES = {
  // Up to
  Mobile: query.maxMobile,
  TabletSmall: query.maxTabletSmall,
  TabletLarge: query.maxTabletLarge,
  Desktop: query.maxDesktop,

  // Only
  TabletSmallOnly: query.tabletSmall,
  TabletLargeOnly: query.tabletLarge,
  TabletOnly: query.tablet,
  DesktopOnly: query.desktop,
  WidescreenOnly: query.widescreen,

  // Above
  TabletSmallAndUp: query.minTabletSmall,
  TabletLargeAndUp: query.minTabletLarge,
  DesktopAndUp: query.minDesktop,
  WidescreenAndUp: query.minWidescreen,
  FullHDAndUp: query.fullHd,
};

const ShownWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !filteredProps.includes(prop) && defaultValidatorFn(prop),
})`
  ${({ elementQuery }) =>
    elementQuery &&
    css`
      display: none;
      @media ${elementQuery} {
        display: block;

        ${layout}
      }
    `}
`;

const Show = ({ children, ...props }) => <ShownWrapper {...props}>{children}</ShownWrapper>;

Object.keys(QUERIES).forEach((key) => {
  const elementQuery = QUERIES[key];

  Show[key] = ({ children, ...props }) => (
    <ShownWrapper elementQuery={elementQuery} {...props}>
      {children}
    </ShownWrapper>
  );
});

export default Show;
