import theme from './theme';

const LIGHT_GREEN = '#CBDFC3';

const dealsThemeLight = {
  ...theme,
  colors: {
    ...theme.colors,
    fontDefault: theme.colors.darkBlue,
    backgroundColor: LIGHT_GREEN,

    primary: theme.colors.danger,
    timer: {
      backgroundColor: '#84A46E',
      borderColor: '#6E9455',
      numberBackground: theme.colors.primary_20,
      numberColor: theme.colors.darkBlue,
      titleColor: theme.colors.white,
      numberLabelColor: theme.colors.white,
    },
    cardBackground: LIGHT_GREEN,
    planCardBackground: theme.colors.white,
    footerGradientColor: LIGHT_GREEN,
    footerLight: LIGHT_GREEN,
  },
  buttons: {
    ...theme.buttons,
    default: {
      primary: {
        color: theme.colors.white,
        default: theme.colors.danger,
        hover: theme.colors.danger_80,
        active: theme.colors.danger_120,
        disabled: theme.colors.darkBlue_40,
      },
    },
  },
  variants: {
    timerBarLogo: 'default',
    navBarLogo: 'default',
    storeRatings: 'dark',
    availablePlatforms: 'dark',
    footer: 'light',
  },
  newsOutlets: {
    titleColor: theme.colors.darkBlue_80,
    logoColor: theme.colors.darkBlue_80,
  },
};

export default dealsThemeLight;
