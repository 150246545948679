import React from 'react';

import { Box } from '@core';
import { ImageCore, RatingStars, Text } from '@components';
import { theme } from '@theme';

const textColor = {
  dark: 'darkBlue',
  light: 'white',
};

export const StoreRatings = ({ logoImage, rating, variant }) => (
  <Box display="flex" alignItems="center" columnGap={12}>
    <Box display="flex" alignItems="center">
      <ImageCore height={27} src={logoImage.src} alt={logoImage.alt} />
    </Box>
    <Box display="flex" alignItems="center" columnGap={8}>
      <Text.Body3Strong
        fontFamily="title"
        fontSize={20}
        lineHeight="25px"
        color={textColor[variant]}
      >
        {rating.toString().replace('.', ',')}
      </Text.Body3Strong>
      <Box>
        <RatingStars rating={rating} fillColor="#E8D07D" emptyColor={theme.colors.darkBlue_20} />
      </Box>
    </Box>
  </Box>
);

export const StoreRatingsWithStoreName = ({ logoImage, rating, storeName, variant }) => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="flex-start">
    <Box display="flex" alignItems="center" columnGap={8}>
      <Text.Body3Strong
        fontFamily="title"
        fontSize={20}
        lineHeight="25px"
        color={textColor[variant]}
      >
        {rating.toString().replace('.', ',')}
      </Text.Body3Strong>
      <Box>
        <RatingStars rating={rating} fillColor="#E8D07D" emptyColor={theme.colors.darkBlue_20} />
      </Box>
    </Box>
    <Box display="flex" alignItems="center" mt={10} columnGap={14}>
      <ImageCore height={27} src={logoImage.src} alt={logoImage.alt} />
      <Text.Body2Strong color={textColor[variant]} whiteSpace="nowrap">
        {storeName}
      </Text.Body2Strong>
    </Box>
  </Box>
);

export const StoreRatingsWithCount = ({ logoImage, rating, ratingCount }) => (
  <Box display="flex" flexDirection="column">
    <Box display="flex">
      <ImageCore height={47} src={logoImage.src} alt={logoImage.alt} />
    </Box>
    <Box display="flex" mt={24} columnGap={16}>
      <RatingStars rating={rating} fillColor="#E8D07D" emptyColor={theme.colors.darkBlue_20} />
      <Text.Body3>{`${rating} • ${ratingCount} Ratings`}</Text.Body3>
    </Box>
  </Box>
);
