module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-smoothscroll/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["ar","cs","da","de","en","es","fr","el","hu","ja","ko","id","it","nl","no","pl","pt","pt-br","ru","sv","tr","zh","zh-tw"],"defaultLanguage":"en","siteUrl":"https://atlasvpn.com/","i18nextOptions":{"lng":"en","fallbackLng":"en","interpolation":{"escapeValue":false},"react":{"useSuspense":false,"transKeepBasicHtmlNodesFor":["br","strong","i"]},"keySeparator":".","nsSeparator":":"},"pages":[{"matchPath":"/:lang(ar|cs|da|de|en|es|fr|el|hu|ja|ko|id|it|nl|no|pl|pt|pt-br|ru|sv|tr|zh|zh-tw)?(/features|/features/hide-ip|/free-trial|/free-vpn|/plans|/refer-a-friend|/servers/france|/servers/germany|/servers/spain|/use-cases|/use-cases/unblock-websites|/use-cases/unblock-websites/facebook|/use-cases/unblock-websites/youtube|/use-cases/vpn-for-gaming|/what-is-vpn)","getLanguageFromPath":true},{"matchPath":{},"languages":["en"]},{"matchPath":{},"languages":["de","en","es","fr"]},{"matchPath":{},"languages":["de","en","es","fr","ru"]},{"matchPath":{},"languages":["de","en","fr","it"]},{"matchPath":{},"languages":["de","en","es","fr","ru","pt","pl","it","sv","ja","zh","da","no","pt-br","zh-tw"]},{"matchPath":{},"languages":["ar","cs","da","de","en","es","fr","el","hu","ja","ko","id","it","nl","no","pl","pt","pt-br","ru","sv","tr","zh","zh-tw"]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://atlasvpn.com","noTrailingSlash":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["AW-696810055","UA-148236428-1","G-M6E7XTMSDR"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-K6X7M3W","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":760,"linkImagesToOriginal":false,"quality":100,"withWebp":true,"withAvif":{"quality":80},"wrapperStyle":"margin: 62px auto;","srcSetBreakpoints":[760],"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
