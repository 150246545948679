import React from 'react';
import { useTranslation } from 'react-i18next';

import { Box, Show } from '@core';

import { FOOTER_ELEMENTS } from '@shared/constants/constants';

import { FooterDesktop, FooterMobile } from './layouts';
import { backgroundVariants } from '@containers/footer/layouts/components/variants';
import { LinkWrapper, SectionContainerSC } from '@components';
import { formatLink } from '@shared/format-helpers';
import Link from '@components/link/link';

const Footer = ({ elements = FOOTER_ELEMENTS, variant = 'default', compact = false }) => {
  const { t } = useTranslation();

  return (
    <div data-section-id="Footer">
      <SectionContainerSC wrapperProps={{ py: 30, px: 16 }}>
        <Box display="flex" justifyContent="end">
          <Link link="/privacy-policy" variant="light">
            Privacy Notice
          </Link>
        </Box>
      </SectionContainerSC>
    </div>
  );
};

export default Footer;
