import React from 'react';

import { Box, Button, Grid } from '@core';
import { ImageCore, LOADING_OPTIONS, LinkWrapper } from '@components';
import atlasLogo from '@images/logos/atlas-logo.svg';

export const LogoBarStatic = () => (
  <Grid.Container data-section-id="LogoBar">
    <Grid.Row
      px={{ _: 24, md: 40 }}
      py={16}
      maxWidth={1280}
      alignItems="center"
      justifyContent="center"
    >
      <LinkWrapper link="/">
        <ImageCore src={atlasLogo} alt="Atlas VPN" loading={LOADING_OPTIONS.eager} />
      </LinkWrapper>
    </Grid.Row>
  </Grid.Container>
);

export const LogoBar = ({ button, backgroundColor }) => (
  <Box
    data-section-id="LogoBar"
    position="sticky"
    top="0"
    backgroundColor={backgroundColor ? backgroundColor : 'white'}
    zIndex={100}
  >
    <Grid.Container>
      <Grid.Row
        px={{ _: 24, md: 40 }}
        py={8}
        maxWidth={1280}
        m="auto"
        alignItems="center"
        justifyContent={{ _: 'space-around', sm: 'space-between' }}
        flexWrap="wrap"
      >
        <Box height={28} mr={8} my={3}>
          <ImageCore src={atlasLogo} alt="Atlas VPN" loading={LOADING_OPTIONS.eager} />
        </Box>
        {button?.title && button?.link && (
          <Box my={3}>
            <LinkWrapper link={button.link} withParams>
              <Button type="button">{button.title}</Button>
            </LinkWrapper>
          </Box>
        )}
      </Grid.Row>
    </Grid.Container>
  </Box>
);
