import loadable from '@loadable/component';

export { Icon } from './icon';
export { NewsLogos } from './custom-icons';

export const ArrowIcon = loadable(() => import('./custom-icons/arrow-icon'));
export const StreamingIcon = loadable(() => import('./custom-icons/features-tabs/streaming-icon'));
export const SecurityIcon = loadable(() => import('./custom-icons/features-tabs/security-icon'));
export const PrivacyIcon = loadable(() => import('./custom-icons/features-tabs/privacy-icon'));
export const SpeedIcon = loadable(() => import('./custom-icons/features-tabs/speed-icon'));
export const StarWithBorderIcon = loadable(() => import('./custom-icons/star-with-border-icon'));
export const Shield = loadable(() => import('./custom-icons/shield'));
export const Close = loadable(() => import('./custom-icons/close'));
