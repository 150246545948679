import loadable from '@loadable/component';

import { CheckoutCard } from './plan-cards';

export const FeatureCard = loadable(() => import('./feature-card/feature-card'));
export const PerkCard = loadable(() => import('./perk-card/perk-card'));
export const DeviceCard = loadable(() => import('./device-card/device-card'));
export const IconLinkCard = loadable(() => import('./icon-link-card/icon-link-card'));
export const DealsPlanCard = loadable(() => import('./deals-plan-card/deals-plan-card'));
export const ReviewCard = loadable(() => import('./review-card/review-card'));
export const MentionCard = loadable(() => import('./mention-card/mention-card'));
export const InfluencerCard = loadable(() => import('./influencer-card/influencer-card'));
export const PricingCard = loadable(() => import('./pricing-card/pricing-card'));
export const ComparisonCard = loadable(() => import('./comparison-card/comparison-card'));

export const Card = {
  Feature: FeatureCard,
  Perk: PerkCard,
  Device: DeviceCard,
  IconLink: IconLinkCard,
  DealsPlanCard: DealsPlanCard,
  Review: ReviewCard,
  Mention: MentionCard,
  Influencer: InfluencerCard,
  Pricing: PricingCard,
  Comparison: ComparisonCard,
  CheckoutCard: CheckoutCard,
};
