import React from 'react';
import styled from 'styled-components';

import { Box, Show } from '@core';
import { query } from '@shared/style-helpers';
import { getNewsLogos } from '@shared/constants/constants';
import { COLORS } from '@theme';

const Container = styled.div`
  font-family: ${({ theme }) => theme?.fonts?.default};
  text-align: center;

  background-color: ${({ backgroundColor }) => backgroundColor};
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 1160px;
  padding: ${({ padding }) => padding};
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h2`
  && {
    color: #4a4a4a;
    color: ${({ theme }) => theme.colors.darkBlue};
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 15px;

    @media ${query.minTabletLarge} {
      font-size: 26px;
    }
  }
`;

const Divider = styled.hr`
  border: none;
  height: 2px;
  width: 80%;
  margin: 0 auto 10px;
  background-color: #f5f5f5;
`;

const ImageWrapper = styled.div`
  width: 221px;
  height: 50px;
  display: flex;
  justify-content: center;
  margin: 10px 5px;

  @media ${query.maxTabletSmall} {
    margin: 3px 5px;
  }
`;

const NewsOutlets = ({
  title,
  logos = getNewsLogos(),
  backgroundColor,
  padding,
  logoColor = COLORS.darkBlue,
}) => (
  <Container data-section-id="NewsOutlets" backgroundColor={backgroundColor}>
    <ContentWrapper padding={padding}>
      {title && <Title>{title}</Title>}

      <Show.TabletSmall>
        {title && <Divider />}
        <Box display="flex" justifyContent="center" flexWrap="wrap" color={logoColor}>
          {logos.mobile.map((Logo, index) => (
            <ImageWrapper key={index}>
              <Logo />
            </ImageWrapper>
          ))}
        </Box>
      </Show.TabletSmall>

      <Show.TabletLargeAndUp>
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          {logos.desktop.map((Logo, index) => (
            <ImageWrapper key={index}>
              <Logo color={logoColor} />
            </ImageWrapper>
          ))}
        </Box>
      </Show.TabletLargeAndUp>
    </ContentWrapper>
  </Container>
);

export default NewsOutlets;
