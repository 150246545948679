import React from 'react';

import { Box } from '@core';
import { Text } from '@components';

const TextBlock = ({ title, description, textAlign, size }) => {
  const HeaderComponent =
    size === 'small' ? (
      <Text.Subheading2 textAlign={textAlign} mb={24}>
        {title}
      </Text.Subheading2>
    ) : (
      <Text.Heading2 whiteSpace="pre-line" textAlign={textAlign} mb={32}>
        {title}
      </Text.Heading2>
    );

  return title || description ? (
    <Box width="100%" my={16}>
      {title && HeaderComponent}
      {description && (
        <Text.Body1 as="div" whiteSpace="pre-line" textAlign={textAlign}>
          {description}
        </Text.Body1>
      )}
    </Box>
  ) : null;
};

export default TextBlock;
