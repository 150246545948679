const getPathsPatternForLanguageSet = (localesObject, languageArray) =>
  Object.keys(localesObject)
    .filter((path) => JSON.stringify(localesObject[path]) === JSON.stringify(languageArray))
    .join('|');

const LANGUAGES = [
  {
    value: 'ar',
    displayValue: 'اَلْعَرَبِيَّةُ',
    locale: 'ar_AE',
  },
  {
    value: 'cs',
    displayValue: 'Čeština',
    locale: 'cs_CZ',
  },
  {
    value: 'da',
    displayValue: 'Dansk',
    locale: 'da_DK',
  },
  {
    value: 'de',
    displayValue: 'Deutsch',
    locale: 'de_DE',
  },
  {
    value: 'en',
    displayValue: 'English',
    locale: 'en_US',
  },
  {
    value: 'es',
    displayValue: 'Español',
    locale: 'es_ES',
  },
  {
    value: 'fr',
    displayValue: 'Français',
    locale: 'fr_FR',
  },
  {
    value: 'el',
    displayValue: 'Ελληνικά',
    locale: 'el_GR',
  },
  {
    value: 'hu',
    displayValue: 'Magyar',
    locale: 'hu_HU',
  },
  {
    value: 'ja',
    displayValue: '日本語',
    locale: 'ja_JP',
  },
  {
    value: 'ko',
    displayValue: '한국어',
    locale: 'ko_KR',
  },
  {
    value: 'id',
    displayValue: 'Bahasa Indonesia',
    locale: 'id_ID',
  },
  {
    value: 'it',
    displayValue: 'Italiano',
    locale: 'it_IT',
  },
  {
    value: 'nl',
    displayValue: 'Nederlands',
    locale: 'nl_NL',
  },
  {
    value: 'no',
    displayValue: 'Norsk',
    locale: 'no_NO',
  },
  {
    value: 'pl',
    displayValue: 'Polski',
    locale: 'pl_PL',
  },
  {
    value: 'pt',
    displayValue: 'Português',
    locale: 'pt_PT',
  },
  {
    value: 'pt-BR',
    displayValue: 'Português Brasileiro',
    locale: 'pt_BR',
  },
  {
    value: 'ru',
    displayValue: 'Русский',
    locale: 'ru_RU',
  },
  {
    value: 'sv',
    displayValue: 'Svenska',
    locale: 'sv_SE',
  },
  {
    value: 'tr',
    displayValue: 'Türkçe',
    locale: 'tr_TR',
  },
  {
    value: 'zh',
    displayValue: '简体中文',
    locale: 'zh_CN',
  },
  {
    value: 'zh-TW',
    displayValue: '繁體中文',
    locale: 'zh_TW',
  },
];

// used language sets. these language arrays need to be lowercased, as they are also used for creating page paths, so it would be /pt-br instead of /pt-BR
const allLanguages = LANGUAGES.map((lang) => lang.value.toLowerCase());
const baseLanguages = ['de', 'en', 'es', 'fr'].filter((language) =>
  allLanguages.includes(language)
);
const extendedLanguages = [...baseLanguages, 'ru'].filter((language) =>
  allLanguages.includes(language)
);
const customLanguageSet = ['de', 'en', 'fr', 'it'].filter(
  (language) => allLanguages.includes(language) //temporarily hardcoding language set, because it doesn't fall into baseLanguages or extendedLanguage sets
);

const seoPagesLanguages = [
  ...extendedLanguages,
  'pt',
  'pl',
  'it',
  'sv',
  'ja',
  'zh',
  'da',
  'no',
  'pt-br',
  'zh-tw',
].filter((language) => allLanguages.includes(language));

const CUSTOM_PAGES_LOCALES = {
  '/deals': allLanguages,
  '/deals/f1': allLanguages,
  '/deals/free-vpn': allLanguages,
  '/summer-holiday-deal': customLanguageSet,
  '/get': allLanguages,
  '/checkout': allLanguages,
  '/payment/success': allLanguages,
  '/payment/failure': allLanguages,
  '/affiliate': baseLanguages,
  '/download': seoPagesLanguages,
  '/servers': baseLanguages,
  '/pricing': extendedLanguages,
  '/newdeals': allLanguages,
  '/vpn-adoption-index': baseLanguages,
  '/vpn-for-android': extendedLanguages,
  '/vpn-for-iphone': extendedLanguages,
  '/vpn-for-mac': extendedLanguages,
  '/vpn-for-windows': seoPagesLanguages,
  '/vpn-for-android-tv': extendedLanguages,
  '/vpn-for-fire-tv': extendedLanguages,
  '/vpn-for-linux': extendedLanguages,
  '/vpn-for-apple-tv': extendedLanguages,
  '/what-is-my-ip': seoPagesLanguages,
  '/': seoPagesLanguages,
};

const PATTERNS = {
  languagePath: `/(${allLanguages.join('|')})`, // /(de|en|pt-br|...)
  pages: {
    // patterns for paths of custom pages to be reused in gatsby-config to avoid repetition mistakes when adding new pages (pattern for translated page builder landers is retrieved dynamically in gatsby-node.esm.js)
    allLanguages: getPathsPatternForLanguageSet(CUSTOM_PAGES_LOCALES, allLanguages) + '|/deals/.+', // + partner pages from page builder - e.g. /deals/tyturner
    baseLanguages: getPathsPatternForLanguageSet(CUSTOM_PAGES_LOCALES, baseLanguages),
    extendedLanguages: getPathsPatternForLanguageSet(CUSTOM_PAGES_LOCALES, extendedLanguages),
    customLanguageSet: getPathsPatternForLanguageSet(CUSTOM_PAGES_LOCALES, customLanguageSet),
    seoPagesLanguages: getPathsPatternForLanguageSet(CUSTOM_PAGES_LOCALES, seoPagesLanguages),
  },
};

module.exports = {
  LANGUAGES,
  LANGUAGES_CONFIG: {
    defaultLanguage: 'en',
    LANGUAGE_SETS: {
      allLanguages,
      baseLanguages,
      extendedLanguages,
      customLanguageSet,
      seoPagesLanguages,
    },
    PATTERNS,
  },
  CUSTOM_PAGES_LOCALES,
};
