import loadable from '@loadable/component';

const Cybernews = loadable(() => import('./cybernews'));
const CNET = loadable(() => import('./cnet'));
const Forbes = loadable(() => import('./forbes'));
const NewYorkTimes = loadable(() => import('./new-york-times'));
const TechRadar = loadable(() => import('./tech-radar'));
const VpnMentor = loadable(() => import('./vpn-mentor'));
const TwentyMinutes = loadable(() => import('./20-minutes'));
const Bild = loadable(() => import('./bild'));
const Chip = loadable(() => import('./chip'));
const Focus = loadable(() => import('./focus'));
const Clubic = loadable(() => import('./clubic'));
const OuestFrance = loadable(() => import('./ouest-france'));
const PCMag = loadable(() => import('./pc-mag'));
const TechCrunch = loadable(() => import('./tech-crunch'));

export const NewsLogos = {
  Cybernews,
  CNET,
  Forbes,
  NewYorkTimes,
  TechRadar,
  VpnMentor,
  TwentyMinutes,
  Bild,
  Chip,
  Focus,
  Clubic,
  OuestFrance,
  PCMag,
  TechCrunch,
};
