import { graphql, useStaticQuery } from 'gatsby';

const translatedLandersQuery = graphql`
  {
    translatedLandersData {
      LANDER_PAGES_LOCALES {
        slug
        langs
      }
    }
  }
`;

export const useTranslatedLandersQuery = () => {
  const translatedLandersData =
    useStaticQuery(translatedLandersQuery).translatedLandersData.LANDER_PAGES_LOCALES;

  return translatedLandersData.reduce((data, { slug, langs }) => {
    data[slug] = langs;

    return data;
  }, {});
};
