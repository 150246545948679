import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { useTranslation } from 'react-i18next';

import { trackCustomGA4Event } from '@shared/google-utils';
import { isPageTranslated } from '@shared/constants/languages';
import { useTranslatedLandersQuery } from '@data/landers';
import { useGlobalStateContext } from '@context';

const Wrapper = styled.span`
  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;

      & > a {
        pointer-events: none;
      }
    `}
`;

const toConvertedLink = (link) =>
  link.startsWith('https://atlasvpn.com') ? link.replace('https://atlasvpn.com', '') : link;

const updateLink = (link, withParams, selectedLanguage, LANDER_PAGES_LOCALES) => {
  let newLink = link;
  if (withParams && typeof window !== 'undefined') {
    newLink += window.location.search;
  }
  if (
    isPageTranslated({
      language: selectedLanguage,
      link: link.split('?')[0],
      LANDER_PAGES_LOCALES,
    }) &&
    selectedLanguage !== 'en'
  ) {
    newLink = `/${selectedLanguage.toLowerCase()}${newLink}`;
  }
  return newLink;
};

const LinkWrapperComponent = ({
  link,
  target,
  title,
  rel,
  children,
  withParams,
  disabled,
  className,
  removeAtlasDomain = true,
  customEvent,
  LANDER_PAGES_LOCALES,
  ...rest
}) => {
  // Can't use useI18next() here because of netlify cms, so retrieving language value from useTranslation.
  const {
    i18n: { language: selectedLanguage },
  } = useTranslation();

  if (!link) {
    return children;
  }

  const convertedLink = removeAtlasDomain ? toConvertedLink(link) : link;

  return (
    <Wrapper
      disabled={disabled}
      onClick={customEvent ? () => trackCustomGA4Event(customEvent) : null}
    >
      {convertedLink[0] === '/' ? (
        // adding language to a path if page is translated, otherwise it will be redirected to english page
        <Link
          to={updateLink(convertedLink, withParams, selectedLanguage, LANDER_PAGES_LOCALES)}
          className={className}
          {...rest}
        >
          {children}
        </Link>
      ) : (
        <a href={convertedLink} title={title} target={target} rel={rel} className={className}>
          {children}
        </a>
      )}
    </Wrapper>
  );
};

const LinkWrapperWithQuery = (props) => {
  const LANDER_PAGES_LOCALES = useTranslatedLandersQuery();

  return <LinkWrapperComponent {...props} LANDER_PAGES_LOCALES={LANDER_PAGES_LOCALES} />;
};

const LinkWrapper = (props) => {
  const { isInBuilder } = useGlobalStateContext();

  // rendering component with static query only when not in cms preview
  return !isInBuilder ? <LinkWrapperWithQuery {...props} /> : <LinkWrapperComponent {...props} />;
};

export default LinkWrapper;
