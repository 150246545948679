import styled from 'styled-components';

export default styled('div')`
  width: 100%;
  border-radius: 7px;
  color: #ffffff;
  background-color: #3b3b4d;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  position: relative;

  font-family: ${({ theme }) => theme?.fonts?.default};
`;
