import React from 'react';
import styled from 'styled-components';
import { keyframes } from 'styled-components';

export const float = keyframes`
  20% {
    transform: translateY(-30px) translateX(12px);
  }
  50% {
    transform: translateY(30px) translateX(-4px);
  }
  80% {
    transform: translateY(-30px) translateX(-20px);
  }
  100% {
    transform: translateY(0px) translateX(0px);
  }
`;

const AnimationWrapper = styled.div`
  animation-name: ${float};
  animation-duration: ${({ duration }) => `${duration}ms`};
  animation-timing-function: linear;
  animation-iteration-count: infinite;
`;

const FloatAnimation = ({ children, duration = 10000 }) => {
  return <AnimationWrapper duration={duration}>{children}</AnimationWrapper>;
};

export default FloatAnimation;
