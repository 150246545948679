import { HIGHLIGHTED_PLANS, ONE_MONTH_PLAN } from './constants/checkout';
import { CURRENCIES } from './constants/currencies';

// This is needed to deal with JS floating point number precision problem (Try to sum up 1.83 and 0.49 in JS) Or just to format number with 2 decimals
export const fixFloatingNumber = (number, currency) => {
  const NON_DECIMAL_CURRENCIES = [CURRENCIES.KRW.code, CURRENCIES.JPY.code];
  return (
    number &&
    number.toFixed(currency && NON_DECIMAL_CURRENCIES.includes(currency.toUpperCase()) ? 0 : 2)
  );
};

const getPlanOrder = {
  desktop: ({ highestPricePerMonth, lowestPricePerMonth, pricePerMonth }) => {
    if (pricePerMonth === highestPricePerMonth) {
      return 0;
    } else if (pricePerMonth === lowestPricePerMonth) {
      return 1;
    }
    return 2;
  },
  mobile: ({ highestPricePerMonth, lowestPricePerMonth, pricePerMonth }) => {
    if (pricePerMonth === lowestPricePerMonth) {
      return 0;
    } else if (pricePerMonth === highestPricePerMonth) {
      return 1;
    }
    return 2;
  },
  alternativeOrder: ({ highestPricePerMonth, lowestPricePerMonth, pricePerMonth }) => {
    if (pricePerMonth === lowestPricePerMonth) {
      return 0;
    } else if (pricePerMonth === highestPricePerMonth) {
      return 2;
    }
    return 1;
  },
};

export const remapPlans = ({ plans: { products: plans }, addons: { products: addons } }) => {
  if (plans) {
    // first plan period from HIGHLIGHTED_PLANS that is equal to period that one of the plans has
    const highlightedPlanPeriod = HIGHLIGHTED_PLANS.find((subscriptionPeriod) =>
      plans.some((plan) => plan.subscription_period === subscriptionPeriod)
    );

    const monthlyPlan = plans.find(
      ({ subscription_period }) => subscription_period === ONE_MONTH_PLAN
    );
    const basePricePerMonth = monthlyPlan && monthlyPlan.price;

    const planPricesPerMonth = plans.map((plan) => {
      const updatedPlan = plan.coupon_discounts;
      return updatedPlan ? updatedPlan.price_per_month : plan.price_per_month;
    });

    const highestPricePerMonth = Math.max(...planPricesPerMonth);
    const lowestPricePerMonth = Math.min(...planPricesPerMonth);

    return plans.map((plan, index) => {
      const updatedPlan = plan.coupon_discounts;
      const addon =
        addons && addons.find((el) => el.subscription_period === plan.subscription_period);
      const updatedAddon = addon.coupon_discounts;

      const planDiscountPercentage = updatedPlan
        ? formatNumber(updatedPlan.discount * 100, 0)
        : formatNumber(plan.discount * 100, 0);

      const addonDiscountPercentage = updatedAddon
        ? formatNumber(updatedAddon.discount * 100, 0)
        : 0; //TODO can't use addon.discount because backend returns wrong number there. So just setting it to 0 as it suppose to be. Anyway, we are not planing to use discount value from addon.discount at least for now.

      const planDiscountAmountNumber = updatedPlan
        ? updatedPlan.comparative_final_price - updatedPlan.price
        : plan.comparative_final_price - plan.price;

      const addonDiscountAmountNumber = updatedAddon
        ? updatedAddon.comparative_final_price - updatedAddon.price
        : addon.comparative_final_price - addon.price;

      const pricePerMonth = planPricesPerMonth[index];

      return {
        id: plan.id,
        identifier: plan.identifier,
        store: plan.store,
        title: plan.title.replace('Stripe ', ''),
        recurrenceType: plan.recurrence_type,
        price: fixFloatingNumber(updatedPlan ? updatedPlan.price : plan.price, plan.currency),
        subscriptionPeriod: plan.subscription_period,
        offering: plan.offering,
        discountPercentage: planDiscountPercentage,
        currency: plan.currency,
        badgeAmount: updatedPlan ? updatedPlan.badge_amount : plan.badge_amount,
        // regular prices only set when there is discount, fallback to 0 to bypass unset value graphql error
        regularPrice: planDiscountPercentage
          ? fixFloatingNumber(
              updatedPlan ? updatedPlan.comparative_final_price : plan.comparative_final_price,
              plan.currency
            )
          : 0,
        regularPricePerMonth: planDiscountPercentage
          ? fixFloatingNumber(basePricePerMonth, plan.currency)
          : 0,
        pricePerMonth: fixFloatingNumber(pricePerMonth, plan.currency),
        discountAmount: planDiscountPercentage
          ? fixFloatingNumber(planDiscountAmountNumber, plan.currency)
          : '',
        // base price for checkout summary
        basePrice: fixFloatingNumber(
          updatedPlan ? updatedPlan.comparative_final_price : plan.comparative_final_price,
          plan.currency
        ),
        extraTime: updatedPlan && updatedPlan.extra_time,
        coupon: updatedPlan && updatedPlan.coupon.toUpperCase(),
        order: {
          desktop: getPlanOrder.desktop({
            highestPricePerMonth,
            lowestPricePerMonth,
            pricePerMonth,
          }),
          mobile: getPlanOrder.mobile({ highestPricePerMonth, lowestPricePerMonth, pricePerMonth }),
          alternativeOrder: getPlanOrder.alternativeOrder({
            highestPricePerMonth,
            lowestPricePerMonth,
            pricePerMonth,
          }),
        },
        isHighlighted: plan.subscription_period === highlightedPlanPeriod,
        addon: {
          title: addon.title,
          price: fixFloatingNumber(updatedAddon ? updatedAddon.price : addon.price, addon.currency),
          pricePerMonth: fixFloatingNumber(
            updatedAddon ? updatedAddon.price_per_month : addon.price_per_month,
            addon.currency
          ),
          subscriptionPeriod: addon.subscription_period,
          discountPercentage: addonDiscountPercentage,
          regularPrice: addonDiscountPercentage
            ? fixFloatingNumber(updatedAddon.comparative_final_price, addon.currency)
            : 0,
          regularPricePerMonth: addonDiscountPercentage
            ? fixFloatingNumber(addon.price_per_month, addon.currency)
            : 0,
          discountAmount: addonDiscountPercentage
            ? fixFloatingNumber(addonDiscountAmountNumber, addon.currency)
            : '',
          currency: addon.currency,
          identifier: addon.identifier,
          coupon: updatedAddon && updatedAddon.coupon,
        },
      };
    });
  }
  return null;
};

const formatNumber = (value, numberOfDecimals) => parseInt(value.toFixed(numberOfDecimals));
