import Typography from '@atlas/core-atlas-vpn/dist/typography';

import { DEFAULT_FONT, TITLE_FONT } from '@theme/theme';

const DEFAULT_COLOR = 'fontDefault';

export const CONFIG = {
  Heading0: {
    tag: 'h1',
    props: {
      $fontFamily: TITLE_FONT,
      $fontSize: { desktop: 56, mobile: 40 },
      $lineHeight: { desktop: 71, mobile: 50 },
      $fontWeight: 700,
      color: DEFAULT_COLOR,
    },
  },
  Heading1: {
    tag: 'h1',
    props: {
      $fontFamily: TITLE_FONT,
      $fontSize: { desktop: 48, mobile: 32 },
      $lineHeight: { desktop: 60, mobile: 40 },
      $fontWeight: 700,
      color: DEFAULT_COLOR,
    },
  },
  Heading2: {
    tag: 'h2',
    props: {
      $fontFamily: TITLE_FONT,
      $fontSize: { desktop: 40, mobile: 28 },
      $lineHeight: { desktop: 50, mobile: 35 },
      $fontWeight: 700,
      color: DEFAULT_COLOR,
    },
  },
  Heading3: {
    tag: 'h3',
    props: {
      $fontFamily: TITLE_FONT,
      $fontSize: { desktop: 32, mobile: 32 },
      $lineHeight: { desktop: 40, mobile: 40 },
      $fontWeight: 700,
      color: DEFAULT_COLOR,
    },
  },

  Subheading1: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 24, mobile: 24 },
      $lineHeight: { desktop: '1.25em', mobile: '1.25em' },
      $fontWeight: 600,
      color: DEFAULT_COLOR,
    },
  },
  Subheading2: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 20, mobile: 20 },
      $lineHeight: { desktop: '1.25em', mobile: '1.25em' },
      $fontWeight: 600,
      color: DEFAULT_COLOR,
    },
  },

  Body1: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 18, mobile: 18 },
      $lineHeight: { desktop: 32, mobile: 32 },
      $fontWeight: 400,
      color: DEFAULT_COLOR,
    },
  },
  Body1Strong: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 18, mobile: 18 },
      $lineHeight: { desktop: 32, mobile: 32 },
      $fontWeight: 600,
      color: DEFAULT_COLOR,
    },
  },
  Body2: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 16, mobile: 16 },
      $lineHeight: { desktop: 28, mobile: 28 },
      $fontWeight: 400,
      color: DEFAULT_COLOR,
    },
  },
  Body2Strong: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 16, mobile: 16 },
      $lineHeight: { desktop: 28, mobile: 28 },
      $fontWeight: 600,
      color: DEFAULT_COLOR,
    },
  },
  Body3: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 14, mobile: 14 },
      $lineHeight: { desktop: 22, mobile: 22 },
      $fontWeight: 400,
      color: DEFAULT_COLOR,
    },
  },
  Body3Strong: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 14, mobile: 14 },
      $lineHeight: { desktop: 22, mobile: 22 },
      $fontWeight: 600,
      color: DEFAULT_COLOR,
    },
  },
  Body4: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 12, mobile: 12 },
      $lineHeight: { desktop: 20, mobile: 20 },
      $fontWeight: 400,
      color: DEFAULT_COLOR,
    },
  },
  Body4Strong: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 12, mobile: 12 },
      $lineHeight: { desktop: 20, mobile: 20 },
      $fontWeight: 600,
      color: DEFAULT_COLOR,
    },
  },
  Body5: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 10, mobile: 10 },
      $lineHeight: { desktop: 16, mobile: 16 },
      $fontWeight: 400,
      color: DEFAULT_COLOR,
    },
  },
  Body5Strong: {
    tag: 'p',
    props: {
      $fontFamily: DEFAULT_FONT,
      $fontSize: { desktop: 10, mobile: 10 },
      $lineHeight: { desktop: 16, mobile: 16 },
      $fontWeight: 600,
      color: DEFAULT_COLOR,
    },
  },
};

export default Typography(CONFIG);
