import React from 'react';
import styled from 'styled-components';

import { SOCIAL_LINKS } from '@shared/constants/constants';
import { ImageCore } from '@components';

import Twitter from '@images/social-icons/twitter.svg';
import TwitterWhite from '@images/social-icons/twitter-white.svg';
import Instagram from '@images/social-icons/instagram.svg';
import InstagramWhite from '@images/social-icons/instagram-white.svg';
import Facebook from '@images/social-icons/facebook.svg';
import FacebookWhite from '@images/social-icons/facebook-white.svg';
import Youtube from '@images/social-icons/youtube.svg';
import YoutubeWhite from '@images/social-icons/youtube-white.svg';

const whiteLogos = [
  { logo: TwitterWhite, alt: 'twitter', link: SOCIAL_LINKS.twitter },
  { logo: InstagramWhite, alt: 'instagram', link: SOCIAL_LINKS.instagram },
  { logo: FacebookWhite, alt: 'facebook', link: SOCIAL_LINKS.facebook },
  { logo: YoutubeWhite, alt: 'youtube', link: SOCIAL_LINKS.youtube },
];

const coloredLogos = [
  { logo: Twitter, alt: 'twitter', link: SOCIAL_LINKS.twitter },
  { logo: Instagram, alt: 'instagram', link: SOCIAL_LINKS.instagram },
  { logo: Facebook, alt: 'facebook', link: SOCIAL_LINKS.facebook },
  { logo: Youtube, alt: 'youtube', link: SOCIAL_LINKS.youtube },
];

const logoVariants = {
  default: whiteLogos,
  light: coloredLogos,
};

const LinkWrapper = styled.a.attrs({ target: '_blank', rel: 'noreferrer' })`
  display: inline-flex;
  margin-right: 16px;
  margin-bottom: 16px;
`;

const SocialLinks = ({ variant }) => (
  <React.Fragment>
    {logoVariants[variant].map(({ logo, alt, link }) => (
      <LinkWrapper href={link} key={alt}>
        <ImageCore src={logo} alt={alt} />
      </LinkWrapper>
    ))}
  </React.Fragment>
);

export default SocialLinks;
