import React from 'react';

import { ContentBlock } from '@components';
import { Box } from '@core';

const ContentBlockList = ({ data = [] }) => (
  <React.Fragment>
    {data?.map(
      (
        {
          image,
          title,
          description,
          textSize,
          button,
          link,
          showAvailablePlatforms,
          isReversed,
          mirrorImage,
          children,
        },
        index
      ) => (
        <Box key={index} mt={index > 0 && 80}>
          <ContentBlock
            image={image}
            title={title}
            description={description}
            textSize={textSize}
            button={button}
            link={link}
            showAvailablePlatforms={showAvailablePlatforms}
            isReversed={isReversed}
            mirrorImage={mirrorImage}
            children={children}
            index={index}
          />
        </Box>
      )
    )}
  </React.Fragment>
);

export default ContentBlockList;
