import styled from 'styled-components';

export default styled('div')`
  width: 100%;
  height: 40px;
  z-index: 100;
  background: ${({ isWhite }) => (isWhite ? 'white' : '#3b3b4d')};

  direction: ltr;
`;
