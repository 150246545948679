import theme from './theme';

const iDealTheme = {
  buttons: {
    ...theme.buttons,
    default: {
      primary: {
        color: '#FFFFFF',
        default: '#CC0066',
        hover: '#EA0B7A',
        active: '#A80054',
        disabled: theme.colors.darkBlue_40,
      },
    },
  },
};

export default iDealTheme;
