import React from 'react';

import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

export const BingScript = () => (
  <script
    type="text/plain"
    data-cookieconsent="marketing"
    dangerouslySetInnerHTML={{
      __html: `(function(w,d,t,r,u)
    {
      var f,n,i;
      w[u]=w[u]||[],f=function()
    {
      var o={ti:"${process.env.BING_UET_ID}"};
      o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")
    },
      n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function()
    {
      var s=this.readyState;
      s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)
    },
      i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)
    })
    (window,document,"script","//bat.bing.com/bat.js","uetq");`,
    }}
  />
);

export const trackCookiebot = () => {
  if (typeof window !== 'undefined' && window.Cookiebot) {
    // Will be triggered on first user visit when consent is not yet accepted.
    if (window.Cookiebot.consent && !window.Cookiebot.consent.method) {
      trackCustomGA4Event({ eventName: GA4_EVENTS.cookieNoticeView });
    }
    // Will be triggered when details modal is shown.
    window.addEventListener('CookiebotOnDetailsShow', () => {
      trackCustomGA4Event({ eventName: GA4_EVENTS.cookieNoticeCustomize });
    });
    // Will be triggered if the user accepts the use of cookies.
    window.addEventListener('CookiebotOnAccept', () => {
      // Since this event is also triggered if the user has consented at an earlier visit to the website.
      // Firing GA4 event only when cookiebot consent state changed.
      if (window.Cookiebot.changed) {
        const { marketing, preferences, statistics } = window.Cookiebot.consent;
        trackCustomGA4Event({
          eventName: GA4_EVENTS.cookieNoticeAccept,
          params: { marketing, preferences, statistics },
        });
      }
    });
    // Will be triggered if the user declines the use of cookies (all checkboxes are unmarked).
    window.addEventListener('CookiebotOnDecline', () => {
      // Since this event is also triggered if the user has declined at an earlier visit to the website.
      // Firing GA4 event only when cookiebot consent state changed.
      const { marketing, preferences, statistics } = window.Cookiebot.consent;
      if (window.Cookiebot.changed) {
        trackCustomGA4Event({
          eventName: GA4_EVENTS.cookieNoticeAccept,
          params: { marketing, preferences, statistics },
        });
      }
    });
  }
};
