import styled from 'styled-components';
import { color } from 'styled-system';

import { filteredProps } from '@shared/style-helpers';

const CrossedSpan = styled.span.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !filteredProps.includes(prop) && defaultValidatorFn(prop),
})`
  text-decoration: line-through;

  ${color}
`;

export default CrossedSpan;
