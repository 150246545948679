import styled from 'styled-components';

export default styled('div')`
  flex: 1;
  display: flex;
  padding: 54px 36px;
  overflow: hidden;
  position: relative;
  border-radius: 7px;
`;
