import React from 'react';
import styled from 'styled-components';

import { ImageCore } from '@components';
import closeButton from '@images/deals/close-button.svg';

const StyledButton = styled('button')`
  padding: 14px;
  cursor: pointer;
  border: none;
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  &:focus {
    outline: 0;
  }
`;

const ModalCloseButton = ({ hideModalHandler }) => (
  <StyledButton onClick={hideModalHandler}>
    <ImageCore src={closeButton} alt="close modal" />
  </StyledButton>
);

export default ModalCloseButton;
