import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid, Show } from '@core';
import { DoubleTextButton, LinkWrapper, StoreRatings, Text } from '@components';
import { ImageCore } from '@components/image';
import { usePlansValuesContext } from '@context';
import { isClient } from '@shared/helpers';

const Image = styled(ImageCore)`
  align-self: flex-start; // to keep image aspect ratio on safari
`;

const ButtonsWrapper = styled(Grid.Row)`
  column-gap: 24px;
`;

const HeaderTextBlock = ({ data }) => {
  const { t } = useTranslation();

  const {
    isLoading: isLoadingFromContext,
    suggestedPlan: { discountPercentage } = {}, // default value for cms preview to work while loading plans
  } = usePlansValuesContext();

  const isLoading = !isClient || isLoadingFromContext;

  const { title, description, buttons } = data.text({
    isLoading,
    discountPercentage,
    t,
  });

  return (
    <Grid.Container>
      {title && <Text.Heading1 textAlign="center">{title}</Text.Heading1>}

      {description && (
        <Text.Body1 as="div" textAlign="center" mt={48}>
          {description}
        </Text.Body1>
      )}

      {buttons && (
        <ButtonsWrapper justifyContent="center">
          {buttons.map((button, index) => (
            <Box width={280} key={index} mt={24}>
              <LinkWrapper link={button.link} withParams>
                {button.subtitle ? (
                  <DoubleTextButton
                    type="button"
                    size="large"
                    fullWidth
                    title={button.title}
                    subtitle={button.subtitle}
                    {...button.buttonProps}
                  />
                ) : (
                  <Button type="button" size="large" fullWidth {...button.buttonProps}>
                    {button.title}
                  </Button>
                )}
              </LinkWrapper>
            </Box>
          ))}
        </ButtonsWrapper>
      )}
    </Grid.Container>
  );
};

const HeaderMobile = ({ data, showRatingsAndPlatforms }) => {
  const theme = useTheme();

  const { mobile, tablet } = data.image();

  return (
    <Grid.Container px={{ _: 24, md: 40 }}>
      <Box width="100%" maxWidth={590}>
        <HeaderTextBlock data={data} />

        {showRatingsAndPlatforms && (
          <Grid.Row justifyContent="space-evenly" mt={56} columnGap={16} rowGap={24}>
            <StoreRatings.Apple variant={theme.variants?.storeRatings} />
            <StoreRatings.Google variant={theme.variants?.storeRatings} />
          </Grid.Row>
        )}
      </Box>

      {mobile && (
        <Show.Mobile width="100%">
          <Grid.Row justifyContent="center">
            <Image
              src={mobile?.src}
              alt={mobile?.alt}
              loading={mobile?.loading}
              {...mobile?.position}
            />
          </Grid.Row>
        </Show.Mobile>
      )}
      {tablet && (
        <Show.TabletOnly width="100%">
          <Grid.Row justifyContent="center">
            <Image
              src={tablet?.src}
              alt={tablet?.alt}
              loading={tablet?.loading}
              {...tablet?.position}
            />
          </Grid.Row>
        </Show.TabletOnly>
      )}
    </Grid.Container>
  );
};

export default HeaderMobile;
