import React, { useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import { theme } from '@theme';

import Wrapper from './layouts/components/wrapper';

const scrollHandler = (setScrolledDown) => () => setScrolledDown(window.scrollY >= 100);

const NavBar = ({
  initialTheme = theme,
  scrolledTheme = theme,
  backgroundColor: backgroundColorProp = 'white',
}) => {
  const [scrolledDown, setScrolledDown] = useState(false);

  useEffect(() => {
    const scrollListener = scrollHandler(setScrolledDown);
    scrollListener();

    window.addEventListener('scroll', scrollListener);

    return () => window.removeEventListener('scroll', scrollListener);
  }, []);

  return (
    <ThemeProvider theme={!scrolledDown ? initialTheme : scrolledTheme}>
      <Wrapper />
    </ThemeProvider>
  );
};

export default NavBar;
