import React, { useRef } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';

import { trackCustomGA4Event } from '@shared/google-utils';

const CoreButton = loadable(() => import('@atlas/core-atlas-vpn/dist/button'));

const handleClick = (props) => {
  if (props.customEvent) {
    const {
      customEvent: { eventName, params },
      children: elementTitle,
      parentSectionId,
    } = props;
    trackCustomGA4Event({
      eventName,
      params: {
        clicked_element_title: typeof elementTitle === 'string' ? elementTitle : 'not_a_string',
        clicked_element_parent: parentSectionId,
        ...params,
      },
    });
  }

  props.onClick && props.onClick();
};

const findParentSectionId = (element) => {
  const parent = element.parentNode;

  if (!parent.hasAttribute) {
    return null;
  }

  if (parent.hasAttribute('data-section-id')) {
    return parent.getAttribute('data-section-id');
  }
  return findParentSectionId(parent);
};

const ButtonWrapper = styled.div`
  display: inherit;
`;

const ButtonWithEvent = (props) => {
  const wrapperRef = useRef();

  return (
    // @TODO update core button to accept ref instead?
    <ButtonWrapper ref={wrapperRef}>
      <CoreButton
        {...props}
        onClick={() =>
          handleClick({ ...props, parentSectionId: findParentSectionId(wrapperRef.current) })
        }
      />
    </ButtonWrapper>
  );
};

const Button = (props) => {
  if (props.customEvent) {
    return <ButtonWithEvent {...props} />;
  }
  return <CoreButton {...props} />;
};

export default Button;
