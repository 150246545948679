import theme from './theme';

const checkoutTheme = {
  ...theme,
  buttons: {
    ...theme.buttons,
    default: {
      ...theme.buttons.default,
      secondary: {
        color: theme.colors.white,
        default: theme.colors.success,
        hover: theme.colors.lightBlue_40,
        active: theme.colors.success,
        disabled: theme.colors.lightBlue_40,
      },
    },
  },
};

export default checkoutTheme;
