import React from 'react';
import { Trans } from 'react-i18next';

import { Button, toast } from '@core';
import { LinkWrapper } from '@components';
import { ERROR_MESSAGES as COUPON_ERROR_MESSAGES } from '@shared/constants/coupons';

const BoldLinkWrapper = ({ children, link }) => (
  <LinkWrapper link={link}>
    <Button type="button" variant="text">
      <strong>{children}</strong>
    </Button>
  </LinkWrapper>
);

export const defaultErrorHandler = ({ t }) => toast.error(t('checkout:errors.somethingWentWrong'));

export const isCouponError = (error) =>
  error && Object.values(COUPON_ERROR_MESSAGES).includes(error.code);

export const paymentErrorHandler = ({
  error,
  commonErrorHandler = defaultErrorHandler,
  t,
} = {}) => {
  if (isCouponError(error)) {
    toast.error(
      <Trans
        t={t}
        i18nKey="checkout:errors.couponInvalidNotification"
        components={[<BoldLinkWrapper link="/deals" key={0} />]}
      />
    );
  } else {
    commonErrorHandler({ t });
  }
};
