exports.components = {
  "component---src-page-builder-templates-deals-js": () => import("./../../../src/page-builder/templates/deals.js" /* webpackChunkName: "component---src-page-builder-templates-deals-js" */),
  "component---src-page-builder-templates-landing-page-js": () => import("./../../../src/page-builder/templates/landing-page.js" /* webpackChunkName: "component---src-page-builder-templates-landing-page-js" */),
  "component---src-page-builder-templates-partners-js": () => import("./../../../src/page-builder/templates/partners.js" /* webpackChunkName: "component---src-page-builder-templates-partners-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-affiliate-js": () => import("./../../../src/pages/affiliate.js" /* webpackChunkName: "component---src-pages-affiliate-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-deals-f-1-js": () => import("./../../../src/pages/deals/f1.js" /* webpackChunkName: "component---src-pages-deals-f-1-js" */),
  "component---src-pages-deals-free-vpn-js": () => import("./../../../src/pages/deals/free-vpn.js" /* webpackChunkName: "component---src-pages-deals-free-vpn-js" */),
  "component---src-pages-deals-js": () => import("./../../../src/pages/deals.js" /* webpackChunkName: "component---src-pages-deals-js" */),
  "component---src-pages-download-js": () => import("./../../../src/pages/download.js" /* webpackChunkName: "component---src-pages-download-js" */),
  "component---src-pages-get-js": () => import("./../../../src/pages/get.js" /* webpackChunkName: "component---src-pages-get-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newdeals-js": () => import("./../../../src/pages/newdeals.js" /* webpackChunkName: "component---src-pages-newdeals-js" */),
  "component---src-pages-payment-failure-js": () => import("./../../../src/pages/payment/failure.js" /* webpackChunkName: "component---src-pages-payment-failure-js" */),
  "component---src-pages-payment-index-js": () => import("./../../../src/pages/payment/index.js" /* webpackChunkName: "component---src-pages-payment-index-js" */),
  "component---src-pages-payment-success-js": () => import("./../../../src/pages/payment/success.js" /* webpackChunkName: "component---src-pages-payment-success-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-servers-js": () => import("./../../../src/pages/servers.js" /* webpackChunkName: "component---src-pages-servers-js" */),
  "component---src-pages-summer-holiday-deal-js": () => import("./../../../src/pages/summer-holiday-deal.js" /* webpackChunkName: "component---src-pages-summer-holiday-deal-js" */),
  "component---src-pages-vpn-adoption-index-js": () => import("./../../../src/pages/vpn-adoption-index.js" /* webpackChunkName: "component---src-pages-vpn-adoption-index-js" */),
  "component---src-pages-vpn-for-android-js": () => import("./../../../src/pages/vpn-for-android.js" /* webpackChunkName: "component---src-pages-vpn-for-android-js" */),
  "component---src-pages-vpn-for-android-tv-js": () => import("./../../../src/pages/vpn-for-android-tv.js" /* webpackChunkName: "component---src-pages-vpn-for-android-tv-js" */),
  "component---src-pages-vpn-for-apple-tv-js": () => import("./../../../src/pages/vpn-for-apple-tv.js" /* webpackChunkName: "component---src-pages-vpn-for-apple-tv-js" */),
  "component---src-pages-vpn-for-fire-tv-js": () => import("./../../../src/pages/vpn-for-fire-tv.js" /* webpackChunkName: "component---src-pages-vpn-for-fire-tv-js" */),
  "component---src-pages-vpn-for-iphone-js": () => import("./../../../src/pages/vpn-for-iphone.js" /* webpackChunkName: "component---src-pages-vpn-for-iphone-js" */),
  "component---src-pages-vpn-for-linux-js": () => import("./../../../src/pages/vpn-for-linux.js" /* webpackChunkName: "component---src-pages-vpn-for-linux-js" */),
  "component---src-pages-vpn-for-mac-js": () => import("./../../../src/pages/vpn-for-mac.js" /* webpackChunkName: "component---src-pages-vpn-for-mac-js" */),
  "component---src-pages-vpn-for-windows-js": () => import("./../../../src/pages/vpn-for-windows.js" /* webpackChunkName: "component---src-pages-vpn-for-windows-js" */),
  "component---src-pages-what-is-my-ip-js": () => import("./../../../src/pages/what-is-my-ip.js" /* webpackChunkName: "component---src-pages-what-is-my-ip-js" */),
  "component---src-templates-blog-blog-js": () => import("./../../../src/templates/blog/blog.js" /* webpackChunkName: "component---src-templates-blog-blog-js" */),
  "component---src-templates-blog-post-blog-post-js": () => import("./../../../src/templates/blog-post/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-blog-post-js" */),
  "component---src-templates-special-discounts-special-discounts-js": () => import("./../../../src/templates/special-discounts/special-discounts.js" /* webpackChunkName: "component---src-templates-special-discounts-special-discounts-js" */),
  "component---src-templates-terms-terms-js": () => import("./../../../src/templates/terms/terms.js" /* webpackChunkName: "component---src-templates-terms-terms-js" */)
}

