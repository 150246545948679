export const trackPageView = (pageUrl) => {
  if (typeof window !== `undefined` && window.gtag) {
    window.gtag(`event`, `page_view`, { page_path: pageUrl });
  }
};

export const trackCustomEvent = ({ category, action, label, value }) => {
  if (typeof window !== `undefined` && window.gtag) {
    window.gtag(`event`, action, {
      send_to: process.env.GOOGLE_ANALYTICS_ID,
      event_category: category,
      event_label: label,
      value,
    });
  }
};

export const trackCustomGA4Event = ({ eventName, params }) => {
  if (typeof window !== `undefined` && window.gtag) {
    window.gtag(`event`, eventName, {
      ...params,
      send_to: process.env.GOOGLE_ANALYTICS_GA4_ID,
    });
  }
};
