import React, { useEffect, useRef } from 'react';
import { globalHistory, navigate } from '@reach/router';

import styled, { StyleSheetManager } from 'styled-components';
import { color } from 'styled-system';
import { useTranslation } from 'react-i18next';
import stylisRTLPlugin from 'stylis-plugin-rtl';

import { Footer, NavBar } from '@containers';
import { mobileTermsPagesRegex } from '@containers/page-wrapper/page-wrapper'; // TODO refactor in the future. Quick solution to hide ipBar and chatWidget for /mobile terms pages.
import { IPBar } from '@components';
import {
  PAGES_WITH_WHITE_IP_BAR,
  getIPBarButtonProps,
  pagesWithNoIpBarRegex,
} from '@shared/constants/ip-bar';
import { getNavItems } from '@shared/constants/constants';
import { replaceLanguageFromSlug } from '@shared/constants/languages';
import { filteredProps } from '@shared/style-helpers';
import { isLTR } from '@shared/translation-helpers';

import firebaseConfig from '../../../firebase.json';

const LayoutWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !filteredProps.includes(prop) && defaultValidatorFn(prop),
})`
  min-height: 100vh;
  background-color: #ffffff;
  width: 100%;

  direction: ltr;

  ${color}
`;
//
// const handleRedirect = (location) => {
//   firebaseConfig.hosting.redirects.forEach((redirects) => {
//     location.pathname.includes(redirects.source) &&
//       navigate(redirects.destination, { replace: true });
//   });
//   return false;
// };

const Layout = ({ location, footerProps = {}, navProps = {}, children, wrapperProps }) => {
  const {
    t,
    i18n: { language },
  } = useTranslation();

  if (typeof window !== 'undefined') {
    // adding smooth-scroll to all anchor elements with id value in href attribute
    require('smooth-scroll')('a[href*="#"]');
  }

  // Updating duplicated data-section-id attributes, appending counter if there is more than one duplicated section id.
  let pageRef = useRef();
  useEffect(() => {
    // globalHistory.listen(({ action, location }) => {
    //   console.log(action, location);
    //   if (action === 'PUSH') {
    //     handleRedirect(location);
    //   }
    // });
    const elements = pageRef.current.querySelectorAll('*[data-section-id]');

    const elementsArray = [...elements];
    const counterObj = {};
    const elementCountArray = elementsArray.map((element) => {
      const { sectionId } = element.dataset;
      counterObj[sectionId] = !counterObj[sectionId] ? 1 : counterObj[sectionId] + 1;
      return counterObj[sectionId];
    });
    elementsArray.forEach((element, index) => {
      const { sectionId } = element.dataset;
      const sectionIdWithCounter = `${index + 1} - ${sectionId}`;
      elements[index].setAttribute(
        'data-section-id',
        counterObj[sectionId] !== 1
          ? `${sectionIdWithCounter} - ${elementCountArray[index]}`
          : sectionIdWithCounter
      );
    });
  }, []);

  const { isHidden: isNavHidden } = navProps;
  const { isHidden: isFooterHidden } = footerProps;

  return (
    <StyleSheetManager stylisPlugins={isLTR(language) ? [] : [stylisRTLPlugin]}>
      <React.Fragment>
        <LayoutWrapper {...wrapperProps} ref={pageRef}>
          {!isNavHidden && <NavBar navItems={getNavItems({ t })} {...navProps} />}
          <main>{children}</main>
          {!isFooterHidden && <Footer {...footerProps} />}
        </LayoutWrapper>
      </React.Fragment>
    </StyleSheetManager>
  );
};

export default Layout;
