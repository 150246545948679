import React from 'react';

import { Box, Grid, Show } from '@core';
import { Animation, Text } from '@components';

import CheckMarkBullet from './check-mark-bullet';
import StepIndicator from '@containers/checkout/components/step-indicator/step-indicator';

const CheckoutHeader = ({ currentStep, setCurrentStep, t, isClientSide, steps }) => {
  const getTitle = (step) => {
    switch (step) {
      case 1:
        return (
          <Box>
            <Text.Subheading2 as="h1" mt={8} $fontFamily="title">
              {t('checkout:header.addOn.title')}
            </Text.Subheading2>
            <Text.Body4 color="darkBlue_80" mt={16}>
              {t('checkout:header.addOn.description')}
            </Text.Body4>
          </Box>
        );
      case 2:
        return (
          <Box>
            <Text.Subheading2 as="h1" mt={8} $fontFamily="title">
              {t('checkout:header.payment.title')}
            </Text.Subheading2>
          </Box>
        );
      default:
        return (
          <Box>
            <Text.Subheading2 as="h1" mt={8} $fontFamily="title">
              {t('checkout:header.choosePlan.title')}
            </Text.Subheading2>
            <Show.DesktopAndUp>
              <Box pt={8}>
                <CheckMarkBullet
                  title={t('checkout:header.choosePlan.features.unlimitedDevices')}
                />
                <CheckMarkBullet title={t('checkout:header.choosePlan.features.encryption')} />
                <CheckMarkBullet title={t('checkout:header.choosePlan.features.streaming')} />
              </Box>
            </Show.DesktopAndUp>
          </Box>
        );
    }
  };

  return (
    <Grid.Container mt={24}>
      <Box width="100%" px={{ _: 16, xl: 0 }} pb={16} minHeight={24}>
        {isClientSide && (
          <Animation.FadeIn>
            <StepIndicator
              steps={steps}
              externalCurrentStep={currentStep}
              onStepChange={(i) => {
                setCurrentStep(i);
              }}
            />
          </Animation.FadeIn>
        )}
      </Box>
      <Grid.Row>
        <Grid.Col px={{ _: 16, xl: 0 }} size={{ _: 12, lg: 5 }}>
          {getTitle(currentStep)}
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default CheckoutHeader;
