import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { setI18n } from 'react-i18next';
import queryString from 'query-string';
import { useCookies } from 'react-cookie';
import i18n from 'i18next';

import { ToastContainer } from '@core';
import { ChatWidget } from '@components';
import { PlansProvider } from '@context/plans-context';
import { CURRENCIES } from '@shared/constants/currencies';
import { DEALS_COUPON_CODE, DEFAULT_CAMPAIGN_CODE } from '@shared/constants/coupons';
import { getAffiliateOffering } from '@shared/constants/offerings';
import { trackCookiebot } from '@shared/third-party-scripts';
import { query } from '@shared/style-helpers';
import {
  getItemsFromLocalStorage,
  getUUID,
  setLocalStorageTracking,
} from '@shared/local-storage-tracking';

import { usePlansQuery } from '../../data/plans';
// Importing only `common`, `navigation` and `checkout` JSON to minimize pageWrapper bundle size. Now only chatWidget and Toast EN translations are used in this component. So creating i18n instance only with those JSON files which are used.
import common from '../../../locales/en/common.json';
import navigation from '../../../locales/en/navigation.json'; // Navigation translations is used in chatWidget
import checkout from '../../../locales/en/checkout.json';

// TODO check how to get rid of i18n initialization here. i18n is not provided in `wrapPageElement` function so additional setting is needed. Either stop using translations in `wrapPageElement` or try gatsby-plugin-layout.
// IP bare is moved to layout component, but we still need to have i18n provider here because of chatWidget. Only English translations will be used in chat widget, so it would work fine now.

// skip this when in storybook, because this would fire later than initialization of i18n in storybook preview and override configuration
if (!process.env.RUNNING_STORYBOOK) {
  i18n.init({
    lng: 'en',
    resources: {
      en: { common, checkout, navigation },
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    },
  });
  setI18n(i18n);
}

const toastContainerTopStyles = css`
  top: 140px;
  z-index: 101;

  @media ${query.tablet} {
    top: 110px;
  }

  @media ${query.maxMobile} {
    top: 100px;
  }
`;

export const StyledToastContainer = styled(ToastContainer)`
  &.Toastify__toast-container--top-left {
    ${toastContainerTopStyles}
  }
  &.Toastify__toast-container--top-center {
    ${toastContainerTopStyles}
  }
  &.Toastify__toast-container--top-right {
    ${toastContainerTopStyles}
  }
`;

export const mobileTermsPagesRegex =
  /^(\/(([a-z]{2}-[a-z]{2})|[a-z]{2}))?\/(mobile\/privacy-policy|mobile\/terms-of-service)\/?$/;

const dealsPagePattern =
  /^(\/(([a-z]{2}-[a-z]{2})|[a-z]{2}))?\/(deal\/.+|deals|deals\/.+|newdeals|summer-holiday-deal)\/?$/;

const getCustomDefaultCoupon = (pathname) => {
  if (dealsPagePattern.test(pathname)) {
    return DEALS_COUPON_CODE;
  }
};

const getDefaultCampaignCoupon = (pathname, cookieCoupon) => {
  if (
    !dealsPagePattern.test(pathname) &&
    cookieCoupon?.toLowerCase() !== DEALS_COUPON_CODE.toLowerCase()
  ) {
    return DEFAULT_CAMPAIGN_CODE;
  }
};
// Pass prop to PlansProvider:
// initialCoupon={parsedCoupon || getDefaultCampaignCoupon(pathname, atlas_coupon)}

const { utm_medium: localStorageUtmMedium } = getItemsFromLocalStorage();

const PageWrapper = ({ children, location }) => {
  const [{ atlas_coupon }] = useCookies(['name']);

  useEffect(() => {
    trackCookiebot();
    getUUID();
  }, []);

  useEffect(() => {
    setLocalStorageTracking(queryString.parse(location.search));
  }, [location.search]);

  const {
    data: { defaultPlans: prefetchedPlans },
  } = usePlansQuery();

  const {
    coupon: parsedCoupon,
    offering: parsedOffering,
    currency: parsedCurrency,
    utm_medium: parsedUtmMedium,
  } = queryString.parse(location.search);

  const { pathname } = location;

  return (
    <React.Fragment>
      <StyledToastContainer />
      <PlansProvider
        initialPlans={prefetchedPlans}
        initialOffer={
          parsedOffering ||
          getAffiliateOffering({
            utmMedium: parsedUtmMedium || localStorageUtmMedium,
          })
        }
        initialCoupon={parsedCoupon || getDefaultCampaignCoupon(pathname, atlas_coupon)}
        initialCurrency={CURRENCIES[parsedCurrency?.toUpperCase()]?.code}
        customDefaultCoupon={getCustomDefaultCoupon(pathname)}
      >
        {children}
      </PlansProvider>
    </React.Fragment>
  );
};

export default PageWrapper;
