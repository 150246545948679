import loadable from '@loadable/component';
import IconBase from '@atlas/core-atlas-vpn/dist/icon-base';

const burger = loadable(() => import('./default-icons'), {
  resolveComponent: (components) => components.burger,
});

const closeIcon = loadable(() => import('./default-icons'), {
  resolveComponent: (components) => components.closeIcon,
});

const check = loadable(() => import('./default-icons'), {
  resolveComponent: (components) => components.check,
});

const checkSolid = loadable(() => import('./default-icons'), {
  resolveComponent: (components) => components.checkSolid,
});

const left = loadable(() => import('./arrow-icons'), {
  resolveComponent: (components) => components.left,
});

const right = loadable(() => import('./arrow-icons'), {
  resolveComponent: (components) => components.right,
});

const down = loadable(() => import('./arrow-icons'), {
  resolveComponent: (components) => components.down,
});

const star = loadable(() => import('./checkout-icons'), {
  resolveComponent: (components) => components.star,
});

const shield = loadable(() => import('./checkout-icons'), {
  resolveComponent: (components) => components.shield,
});

export const Icon = {
  Left: IconBase(left),
  Down: IconBase(down),
  Right: IconBase(right),
  Burger: IconBase(burger),
  Close: IconBase(closeIcon),
  Check: IconBase(check),
  CheckSolid: IconBase(checkSolid),
  Shield: IconBase(shield),
  Star: IconBase(star),
};
