import React from 'react';
import { endOfDay } from 'date-fns';

import { useMediaQuery, useTimer } from '@hooks';

import { DesktopLayout, MobileLayout } from './modal-layouts';

const ProductsModal = (props) => {
  const timer = useTimer({ toDate: endOfDay(new Date()), doubleDigits: true });

  const { isDesktopAndUp } = useMediaQuery();

  return (
    <React.Fragment>
      {isDesktopAndUp ? (
        <DesktopLayout timer={timer} {...props} />
      ) : (
        <MobileLayout timer={timer} {...props} />
      )}
    </React.Fragment>
  );
};

export default ProductsModal;
