import Height from './height';
import Rotate from './rotate';
import Float from './float';
import FadeIn from './fade-in';

export const Animation = {
  Height,
  Rotate,
  Float,
  FadeIn,
};
