import React from 'react';
import styled from 'styled-components';

import { Box, Button, Grid } from '@core';
import { ArrowLink, AvailablePlatforms, LinkWrapper } from '@components';
import { ImageCore } from '@components/image';

import TextBlock from './components/text-block';

const ImageWrapper = styled(ImageCore)`
  object-fit: contain;
  transform: ${({ $mirrorImage }) => ($mirrorImage ? 'scaleX(-1)' : undefined)};
`;

const ContentBlock = ({
  image,
  title,
  description,
  textSize,
  button,
  link,
  showAvailablePlatforms = false,
  isReversed = false,
  children,
  mirrorImage = false,
}) => {
  return (
    <Grid.Container data-section-id={`ContentBlockItem`}>
      <Grid.Row flexDirection={isReversed ? 'row-reverse' : 'row'} alignItems="center">
        <Grid.Col
          size={{ _: 12, md: 6 }}
          px={{ _: 24, md: 0 }}
          display="flex"
          justifyContent={{ _: 'flex-start', md: 'center' }}
          alignItems={{ _: 'flex-start', md: 'center' }}
        >
          <ImageWrapper
            src={image?.src || image}
            alt={image?.alt || title}
            height={{ _: 240, md: 400 }}
            $mirrorImage={mirrorImage}
            imgStyle={{ objectFit: 'contain', width: 'auto', maxWidth: '100%' }} // to fix image alignment on safari
          />
        </Grid.Col>
        <Grid.Col size={{ _: 12, md: 6 }}>
          <Box m={24}>
            <TextBlock title={title} description={description} size={textSize} />
            {showAvailablePlatforms && <AvailablePlatforms />}
            {children}
            {button?.link && button?.title && (
              <Box width={{ _: '100%', sm: 280 }} mt={32}>
                <LinkWrapper link={button.link} withParams>
                  <Button
                    type="button"
                    size="large"
                    variant={button.variant}
                    {...button.buttonProps}
                    fullWidth
                  >
                    {button.title}
                  </Button>
                </LinkWrapper>
              </Box>
            )}
            {link?.link && link?.title && (
              <ArrowLink link={link.link} customEvent={link?.customEvent}>
                {link.title}
              </ArrowLink>
            )}
          </Box>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default ContentBlock;
