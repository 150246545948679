import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

const usePortal = (
  { container: ModalContrainer, content: ModalContent, onShowCallback, onCloseCallback } = {},
  { root = 'portal' } = {}
) => {
  const [show, setShow] = useState(false);
  const rootRef = useRef(null);

  // to prevent - error "document" is not available during server side rendering.
  useEffect(() => {
    if (typeof document !== `undefined`) {
      rootRef.current = document.getElementById(root);
    }
  }, [root]);

  const Modal = (props) => (
    <ModalContrainer
      hideModal={() => {
        setShow(false);
        onCloseCallback && onCloseCallback();
      }}
    >
      <ModalContent
        hideModal={() => {
          setShow(false);
          onCloseCallback && onCloseCallback();
        }}
        {...props}
      />
    </ModalContrainer>
  );

  return {
    showModal: useCallback(() => {
      setShow(true);
      onShowCallback && onShowCallback();
    }, [onShowCallback]),
    hideModal: useCallback(() => {
      setShow(false);
      onCloseCallback && onCloseCallback();
    }, [onCloseCallback]),
    Modal: useCallback(
      (props) =>
        show && rootRef !== null ? createPortal(<Modal {...props} />, rootRef.current) : null,
      [show]
    ),
  };
};

export default usePortal;
