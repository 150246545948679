import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Button } from '@core';
import { ImageCore } from '@components';
import trustPilotRatingLight from '@images/trustpilot-color-light.svg';

import { ModalContent, ModalHeader, ModalWrapper } from './components';

const DesktopWrapper = styled(ModalWrapper)`
  max-width: 1000px;
`;

const ContentLeft = styled('div')`
  flex: 1;
  max-width: 487px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const ContentRight = styled('div')`
  flex: 1;
  z-index: 1;
  max-width: 324px;
  margin-left: 62px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

const ContentDescription = styled('p')`
  font-size: 35px;
  font-weight: bold;
`;

const DealsPageLayout = ({ hideModal, timer, buttonLink, buttonLabel, buttonProps, content }) => (
  <DesktopWrapper>
    <ModalHeader timer={timer} label={content.headerLabel} hideModalHandler={hideModal} />

    <ModalContent>
      <ContentLeft>
        <ImageCore src={content.imageDesktop} alt={'discount'} />
      </ContentLeft>
      <ContentRight>
        <div>
          <ImageCore src={trustPilotRatingLight} alt={'trustpilot'} />
        </div>
        <ContentDescription>{content.description}</ContentDescription>
        {/* TODO discuss with UX about the different style button */}
        <Link to={buttonLink}>
          <Button type="button" {...buttonProps}>
            {buttonLabel}
          </Button>
        </Link>
      </ContentRight>
    </ModalContent>
  </DesktopWrapper>
);

export default DealsPageLayout;
