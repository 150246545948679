import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Button } from '@core';
import { ImageCore } from '@components';
import greyOval from '@images/oval-grey.svg';
import dealsModalPerson from '@images/deals/deals-modal-person.svg';

import { ModalCloseButton, ModalContent, ModalWrapper } from './components';

const DesktopWrapper = styled(ModalWrapper)`
  max-width: 1000px;
  min-height: 356px;
`;

const PersonImage = styled('img')`
  width: 303px;
  position: absolute;
  top: 53%;
  left: 51%;
  transform: translate(-70%, -50%);
`;

const OvalImage = styled('img')`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(35%, -50%);
`;

const ContentDescription = styled('p')`
  font-size: 35px;
  font-weight: bold;
`;

const ContentSublabel = styled('p')`
  font-size: 15px;
  font-weight: 600;
  text-align: center;
`;

const ContentLeft = styled('div')`
  flex: 1;
  max-width: 375px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`;

const ContentRight = styled('div')`
  flex: 1;
  z-index: 1;
  max-width: 345px;
  margin-left: 200px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
`;

const ProductsPageLayout = ({
  hideModal,
  timer,
  buttonLink,
  buttonLabel,
  buttonProps,
  content,
}) => {
  const { hours, minutes, seconds } = timer || {};
  const timeLeft = `${hours} : ${minutes} : ${seconds}`;

  return (
    <DesktopWrapper>
      <ModalCloseButton hideModalHandler={hideModal} />

      <ModalContent>
        <ContentLeft>
          <ContentDescription>{content.description}</ContentDescription>
          {/* TODO discuss with UX about the different style button */}
          <Link to={buttonLink}>
            <Button type="button" {...buttonProps}>
              {buttonLabel}
            </Button>
          </Link>
        </ContentLeft>

        <OvalImage src={greyOval} />
        <PersonImage src={dealsModalPerson} alt={'person grabbing a good deal'} />

        <ContentRight>
          <div>
            <ImageCore src={content.imageDesktop} alt={'discount'} width={350} />
            <ContentSublabel>{content.desktopSubLabel || content.subLabel}</ContentSublabel>
          </div>
          <Link to={buttonLink}>
            <Button type="button" {...buttonProps}>
              Limited time offer {timeLeft}
            </Button>
          </Link>
        </ContentRight>
      </ModalContent>
    </DesktopWrapper>
  );
};

export default ProductsPageLayout;
