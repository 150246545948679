import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

export default styled.div`
  animation-name: ${fadeIn};
  animation-duration: ${({ duration = 500 }) => `${duration}ms`};
  animation-timing-function: ${({ animationTimingFunction }) => animationTimingFunction};
`;
