import React from 'react';
import styled from 'styled-components';

import { Box } from '@core';
import { Icon, Text } from '@components';

const Wrapper = styled.div`
  border-radius: 18px;
  display: inline-block;
  background-color: ${({ theme }) => theme.colors.darkBlue};
  color: ${({ theme }) => theme.colors.white};
`;

const Badge = ({ title }) => (
  <Wrapper>
    <Box display="flex" alignItems="center" mx={8} my={4}>
      <Icon.Shield size={14} color="white" />
      <Text.Body4 ml={8}>{title}</Text.Body4>
    </Box>
  </Wrapper>
);

export default Badge;
