import axios from 'axios';

axios.interceptors.request.use(
  (config) => {
    if (config.url.includes(process.env.API_DOMAIN)) {
      config.headers['X-Client-ID'] = 'Web';
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axios;
