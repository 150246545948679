// Storybook fails when importing LinkWrapper from @components
// News outlets logo components
import { NewsLogos } from '@components/icon';
// PlatformIcons
import android from '@images/platform-icons/android-black.svg';
import androidWhite from '@images/platform-icons/android-light.svg';
import windows from '@images/platform-icons/windows-black.svg';
import windowsWhite from '@images/platform-icons/windows-light.svg';
import iOS from '@images/platform-icons/ios-black.svg';
import iOSWhite from '@images/platform-icons/ios-light.svg';
import androidTv from '@images/platform-icons/android-tv-black.svg';
import androidTvWhite from '@images/platform-icons/android-tv-light.svg';
import macOs from '@images/platform-icons/mac-os-black.svg';
import macOsWhite from '@images/platform-icons/mac-os-light.svg';
import fireTv from '@images/platform-icons/fire-tv-black.svg';
import fireTvWhite from '@images/platform-icons/fire-tv-light.svg';
import linux from '@images/platform-icons/linux-black.svg';
import linuxWhite from '@images/platform-icons/linux-light.svg';
import appleTv from '@images/platform-icons/apple-tv-black.svg';
import appleTvWhite from '@images/platform-icons/apple-tv-light.svg';
import { trackCustomGA4Event } from '@shared/google-utils';
import { formatLink } from '@shared/format-helpers';

import { GA4_EVENTS } from './google';

export const DOMAIN = {
  mainUrl: 'https://atlasvpn.com',
};

export const SOCIAL_LINKS = {
  twitter: 'https://twitter.com/atlas_vpn',
  facebook: 'https://www.facebook.com/atlasvpncom',
  instagram: 'https://www.instagram.com/atlasvpn/',
  linkedin: 'https://www.linkedin.com/company/atlas-vpn/',
  youtube: 'https://www.youtube.com/channel/UCNf6mTAMtvP3PE4tYPyjQ6w',
};

export let PRICING_LINK = '/blog/announcement';
const NEW_PRICING_LINK = '/pricing';

export const SUPPORT_EMAIL = 'mailto:support@atlasvpn.com';
export const HELP_CENTER_LINK = 'https://help.atlasvpn.com/hc/en-us/';

export const FOOTER_ELEMENTS = (t) => ({
  top: [
    {
      title: 'Atlas VPN',
      links: [
        {
          label: t('navigation:footer.features'),
          link: formatLink(t('navigation:paths.features')),
        },
        { label: t('navigation:footer.servers'), link: formatLink(t('navigation:paths.servers')) },
        {
          label: t('navigation:footer.useCases'),
          link: formatLink(t('navigation:paths.use-cases')),
        },
        { label: t('navigation:footer.freeVpn'), link: formatLink(t('navigation:paths.free-vpn')) },
        {
          label: t('navigation:footer.specialDiscounts'),
          link: formatLink(t('navigation:paths.special-discounts')),
        },
        {
          label: t('navigation:footer.whatIsVpn'),
          link: formatLink(t('navigation:paths.what-is-vpn')),
        },
        {
          label: t('navigation:footer.whatIsMyIp'),
          link: formatLink(t('navigation:paths.what-is-my-ip')),
        },
      ],
    },
    {
      title: t('navigation:footer.information'),
      links: [
        { label: t('navigation:footer.aboutUs'), link: formatLink(t('navigation:paths.about-us')) },
        { label: t('navigation:footer.blog'), link: formatLink(t('navigation:paths.blog')) },
        {
          label: t('navigation:footer.affiliate'),
          link: formatLink(t('navigation:paths.affiliate')),
        },
        {
          label: t('navigation:footer.mediaCenter'),
          link: formatLink(t('navigation:paths.media-kit')),
        },
        {
          label: t('navigation:footer.forMediaPartners'),
          link: formatLink(t('navigation:paths.media-partners')),
        },
        {
          label: t('navigation:footer.partnershipProgram'),
          link: formatLink(t('navigation:paths.partnership')),
        },
      ],
    },
    {
      title: t('navigation:footer.apps'),
      links: [
        {
          label: t('navigation:footer.windows'),
          link: formatLink(t('navigation:paths.vpn-for-windows')),
        },
        {
          label: t('navigation:footer.macOS'),
          link: formatLink(t('navigation:paths.vpn-for-mac')),
        },
        {
          label: t('navigation:footer.linux'),
          link: formatLink(t('navigation:paths.vpn-for-linux')),
        },
        {
          label: t('navigation:footer.android'),
          link: formatLink(t('navigation:paths.vpn-for-android')),
        },
        {
          label: t('navigation:footer.iOS'),
          link: formatLink(t('navigation:paths.vpn-for-iphone')),
        },
        {
          label: t('navigation:footer.androidTv'),
          link: formatLink(t('navigation:paths.vpn-for-android-tv')),
        },
        {
          label: t('navigation:footer.fireTv'),
          link: formatLink(t('navigation:paths.vpn-for-fire-tv')),
        },
        {
          label: t('navigation:footer.appleTv'),
          link: formatLink(t('navigation:paths.vpn-for-apple-tv')),
        },
      ],
    },
  ],
  bottom: [
    {
      label: t('navigation:footer.termsAndConditions'),
      link: formatLink(t('navigation:paths.terms-of-service')),
      customEvent: { eventName: GA4_EVENTS.footerTermsConditionsClick },
    },
    {
      label: t('navigation:footer.privacyPolicy'),
      link: formatLink(t('navigation:paths.privacy-policy')),
      customEvent: { eventName: GA4_EVENTS.footerPrivacyPolicyClick },
    },
    {
      label: t('navigation:footer.cookiePreferences'),
      onClick: () => {
        typeof window.Cookiebot?.renew === 'function' && window.Cookiebot.renew();
        trackCustomGA4Event({ eventName: GA4_EVENTS.footerCookiesPreferenceClick });
      },
    },
  ],
});

export const getNavItems = ({ t } = {}) => [
  {
    title: t('navigation:navBar.home'),
    link: '/',
    displayOnMobile: true,
    hideOnDesktop: true,
  },
  {
    title: t('navigation:navBar.features'),
    link: formatLink(t('navigation:paths.features')),
    displayOnMobile: true,
    customEvent: { eventName: GA4_EVENTS.navbarFeaturesClick },
  },
  {
    title: t('navigation:navBar.vpnApps'),
    link: formatLink(t('navigation:paths.download')),
    displayOnMobile: true,
    categories: [
      {
        title: t('navigation:navBar.desktopAndLaptops'),
        items: [
          {
            title: t('navigation:navBar.windows'),
            link: formatLink(t('navigation:paths.vpn-for-windows')),
            img: { white: windowsWhite, blue: windows },
          },
          {
            title: t('navigation:navBar.macOS'),
            link: formatLink(t('navigation:paths.vpn-for-mac')),
            img: { white: macOsWhite, blue: macOs },
          },
          {
            title: t('navigation:navBar.linux'),
            link: formatLink(t('navigation:paths.vpn-for-linux')),
            img: { white: linuxWhite, blue: linux },
          },
        ],
      },
      {
        title: t('navigation:navBar.phonesAndTablets'),
        items: [
          {
            title: t('navigation:navBar.android'),
            link: formatLink(t('navigation:paths.vpn-for-android')),
            img: { white: androidWhite, blue: android },
          },
          {
            title: t('navigation:navBar.iOS'),
            link: formatLink(t('navigation:paths.vpn-for-iphone')),
            img: { white: iOSWhite, blue: iOS },
          },
        ],
      },
      {
        title: t('navigation:navBar.tvs'),
        items: [
          {
            title: t('navigation:navBar.androidTv'),
            link: formatLink(t('navigation:paths.vpn-for-android-tv')),
            img: { white: androidTvWhite, blue: androidTv },
          },
          {
            title: t('navigation:navBar.fireTv'),
            link: formatLink(t('navigation:paths.vpn-for-fire-tv')),
            img: { white: fireTvWhite, blue: fireTv },
          },
          {
            title: t('navigation:navBar.appleTv'),
            link: formatLink(t('navigation:paths.vpn-for-apple-tv')),
            img: { white: appleTvWhite, blue: appleTv },
          },
        ],
      },
    ],
    customEvent: { eventName: GA4_EVENTS.navbarVpnAppsClick },
  },
  {
    title: t('navigation:navBar.useCases'),
    link: formatLink(t('navigation:paths.use-cases')),
    displayOnMobile: true,
  },
  {
    title: t('navigation:navBar.blog'),
    link: formatLink(t('navigation:paths.blog')),
    displayOnMobile: true,
    customEvent: { eventName: GA4_EVENTS.navbarBlogClick },
  },
  {
    title: t('navigation:navBar.help'),
    link: HELP_CENTER_LINK,
    displayOnMobile: true,
    customEvent: { eventName: GA4_EVENTS.navbarHelpClick },
  },
];

export const getFilteredNavItems = ({ pricingLink, t } = {}) =>
  getNavItems({ pricingLink, t }).filter(({ isFiltered }) => !isFiltered);

export const META_IMAGE = '/assets/atlas-meta.png';

export const getNewsLogos = () => ({
  desktop: [
    NewsLogos.NewYorkTimes,
    NewsLogos.Forbes,
    NewsLogos.VpnMentor,
    NewsLogos.TechRadar,
    NewsLogos.CNET,
  ],
  mobile: [
    NewsLogos.NewYorkTimes,
    NewsLogos.Forbes,
    NewsLogos.VpnMentor,
    NewsLogos.TechRadar,
    NewsLogos.CNET,
  ],
});

export const LOCALIZED_OUTLETS = {
  de: [
    {
      link: 'https://www.chip.de/news/Atlas-VPN-im-Check-Das-kann-der-kleine-Bruder-von-NordVPN_184229811.html',
      logo: NewsLogos.Chip,
    },
    {
      link: 'https://www.focus.de/finanzen/gastbeitrag-von-gabor-steingart-kjkkj_id_13192231.html',
      logo: NewsLogos.Focus,
    },
    {
      link: 'https://www.computerbild.de/artikel/cb-News-Sicherheit-VPN-Coronavirus-VPN-Zahlen-Anstieg-25393499.html',
      logo: NewsLogos.Bild,
    },
    {
      link: 'https://www.cnet.com/tech/services-and-software/vpn-use-surges-during-the-coronavirus-lockdown-but-so-do-security-risks/',
      logo: NewsLogos.CNET,
    },
    {
      link: 'https://techcrunch.com/2022/06/01/report-shows-that-safari-reaches-one-billion-worldwide-users-still-behind-google-chrome/',
      logo: NewsLogos.TechCrunch,
    },
  ],
  fr: [
    {
      link: 'https://www.20minutes.fr/high-tech/3101083-20210811-cryptomonnaies-comment-demi-milliard-dollars-argent-virtuel-pu-etre-derobe',
      logo: NewsLogos.TwentyMinutes,
    },
    {
      link: 'https://www.ouest-france.fr/shopping/quel-est-le-meilleur-vpn-voici-notre-top-5-ultime-a-lire-absolument-avant-de-le-choisir-b8a85a0e-150a-11ed-8a78-078da6dc529c',
      logo: NewsLogos.OuestFrance,
    },
    {
      link: 'https://www.clubic.com/antivirus-securite-informatique/vpn/actualite-410793-l-usage-du-vpn-a-presque-double-en-france-l-annee-derniere.html',
      logo: NewsLogos.Clubic,
    },
    {
      link: 'https://www.cnet.com/tech/services-and-software/vpn-use-surges-during-the-coronavirus-lockdown-but-so-do-security-risks/',
      logo: NewsLogos.CNET,
    },
    {
      link: 'https://techcrunch.com/2022/06/01/report-shows-that-safari-reaches-one-billion-worldwide-users-still-behind-google-chrome/',
      logo: NewsLogos.TechCrunch,
    },
  ],
  default: [
    {
      link: 'https://techcrunch.com/2022/06/01/report-shows-that-safari-reaches-one-billion-worldwide-users-still-behind-google-chrome/',
      logo: NewsLogos.TechCrunch,
    },
    {
      link: '', // empty because unused
      logo: NewsLogos.Cybernews,
    },
    {
      link: 'https://www.cnet.com/tech/services-and-software/vpn-use-surges-during-the-coronavirus-lockdown-but-so-do-security-risks',
      logo: NewsLogos.CNET,
    },
    {
      link: 'https://www.techradar.com/news/this-challenger-vpn-now-lets-you-connect-to-multiple-servers-at-once',
      logo: NewsLogos.TechRadar,
    },
    {
      link: 'https://www.forbes.com/sites/petersuciu/2022/03/15/vpn-usage-has-skyrocketed-as-russians-scramble-to-finds-ways-to-reach-social-media/?sh=532d0a353026',
      logo: NewsLogos.Forbes,
    },
  ],
};

export const getNewsLogosRow = (language) =>
  LOCALIZED_OUTLETS[language] || LOCALIZED_OUTLETS.default;
