import { CUSTOM_PAGES_LOCALES, LANGUAGES_CONFIG } from '../../../languages-config';

export { LANGUAGES } from '../../../languages-config';

export const PAGES_LOCALES = {
  ...CUSTOM_PAGES_LOCALES,
};

export const isPageTranslated = ({ language, link, LANDER_PAGES_LOCALES }) => {
  const PAGES_LOCALES_WITH_LANDERS = {
    ...PAGES_LOCALES,
    ...(LANDER_PAGES_LOCALES && LANDER_PAGES_LOCALES), // not passed when in cms preview
  };

  return (
    PAGES_LOCALES_WITH_LANDERS[link] &&
    PAGES_LOCALES_WITH_LANDERS[link].includes(language.toLowerCase())
  );
};

export const replaceLanguageFromSlug = (slug) => {
  const pathPattern = new RegExp(
    String.raw`(^${LANGUAGES_CONFIG.PATTERNS.languagePath})((\/.+)+)$`
  );
  const languagePathPattern = new RegExp(String.raw`^${LANGUAGES_CONFIG.PATTERNS.languagePath}$`);

  // checks if path is homepage, e.g. /, /de, /pt-br
  if (slug === '/' || slug.match(languagePathPattern)) {
    return '/';
  }
  return slug.replace(pathPattern, '$3'); // when pagePath = /fr/deals or /pt-br/deals, '/fr' or /pt-br will be removed;
};

export const getPageLanguagesBySlug = (slug) => {
  const slugWithoutLanguage = replaceLanguageFromSlug(slug);
  return PAGES_LOCALES[slugWithoutLanguage];
};
