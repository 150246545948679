import React from 'react';
import { Link } from 'gatsby';

import { Box, Button, Grid } from '@core';
import { ImageCore } from '@components';

import Badge from './components/badge';
import TextBlock from './components/text-block';

const ContentBlockSmall = ({
  image,
  title,
  description,
  textSize,
  textAlign,
  button,
  badgeText,
  isReversed = false,
}) => {
  return (
    <Grid.Container minWidth="100%">
      <Grid.Row flexDirection={isReversed ? 'row-reverse' : 'row'} alignItems="center">
        <Grid.Col height={160} overflow="hidden">
          <ImageCore src={image} alt={title} />
        </Grid.Col>
        <Grid.Col>
          <Box m={24}>
            <TextBlock
              title={title}
              description={description}
              size={textSize}
              textAlign={textAlign}
            />
            {badgeText && <Badge title={badgeText} />}
            {button && (
              <Box minWidth={200}>
                <Link to={button.link}>
                  <Button type="button" fullWidth>
                    {button.title}
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Grid.Col>
      </Grid.Row>
    </Grid.Container>
  );
};

export default ContentBlockSmall;
