// Don't use aliased imports here because build will fail. gatsby node doesn't resolve aliases because it runs before webpack.
import axios from './axios';
import { remapPlans } from '../shared/plans';

const SHOP = 'https://shop.' + process.env.API_DOMAIN;
const USER = 'https://user.' + process.env.API_DOMAIN;

const PRODUCTS_URL = SHOP + '/v4/plans';
const PAYMENT_URL = USER + '/v3/stripe/pay';
const PAYPAL_URL_V1 = USER + '/v1/paypal';

export const OFFERINGS = {
  default: 'regular_6',
};

export const STORES = {
  default: 'stripe',
  stripe: 'stripe',
  paypal: 'paypal',
};

const getConfig = (token) => ({
  headers: { Authorization: `Bearer ${token}` },
});

export const getPlans = (
  offering = OFFERINGS.default,
  coupon,
  store = STORES.default,
  currency,
  config = {}
) =>
  axios
    .get(PRODUCTS_URL, {
      ...config,
      params: {
        tmp_view: 1,
        store,
        offering,
        coupon,
        currency,
      },
    })
    .then((response) => response.data)
    .then((response) => {
      // @TODO remove first check when this endpoint is updated to return object named "error" instead
      if (response.plans.error_object) {
        throw response.plans.error_object;
      }
      if (response.plans.error) {
        throw response.plans.error;
      }
      return remapPlans(response);
    });

export const signUpAndPay = (requestBody) => {
  return axios.post(PAYMENT_URL, requestBody).then((response) => response.data);
};

export const paypalOnConfirmSubscription = ({ token }, callback) => {
  return axios
    .post(`${PAYPAL_URL_V1}/subscription/confirm`, {}, getConfig(token))
    .then(() => callback());
};
