import React from 'react';
import styled from 'styled-components';

const AnimationWrapper = styled.div`
  > * {
    transform: ${({ $rotate, deg }) => ($rotate ? `rotate(${deg}deg)` : 'rotate(0deg)')};
    transition-property: transform;
    transition-duration: ${({ duration }) => `${duration}ms`};
  }
`;

const RotateAnimation = ({ children, rotate, deg = -180, duration = 300 }) => (
  <AnimationWrapper $rotate={rotate} deg={deg} duration={duration}>
    {children}
  </AnimationWrapper>
);

export default RotateAnimation;
