import React from 'react';

import { Box, Button } from '@core';
import { LinkWrapper, Text } from '@components';
import { PRICING_LINK } from '@shared/constants/constants';

import { CardContainer, CardSticker, CheckBox } from './components';

const CheckoutCard = ({
  dataTestId,
  onClick,
  isActive,
  isHighlighted,
  button,
  isLoading,
  price,
  currency,
  pricePeriod,
  billingDescription,
  subscriptionPeriod,
  stickerLabel,
  highlightLabel,
  title,
  couponText,
  highlightLabelDanger,
  moneyBackGuaranteeText,
}) => (
  <CardContainer
    isHighlighted={isHighlighted}
    isActive={isActive}
    isLoading={isLoading}
    onClick={onClick}
    dataTestId={dataTestId}
  >
    <Box pt={24} pb={button ? 8 : 16} px={isActive ? 22 : 24}>
      <CardSticker isHighlighted={isHighlighted}>{stickerLabel}</CardSticker>
      <Box display="flex" alignItems="center">
        {!button && <CheckBox isActive={isActive} />}
        <Text.Body3Strong ml={16} color="darkBlue">
          {title}
        </Text.Body3Strong>
      </Box>
      <Box mx={12} minHeight={{ _: 'unset', xl: 142 }}>
        <Box
          mt={button ? 16 : 32}
          display="flex"
          alignItems="flex-end"
          data-testid={`plan-card-price-${subscriptionPeriod}`}
          columnGap={8}
          maxWidth={292}
          flexWrap="wrap"
        >
          <Text.Heading1
            as="p"
            color="darkBlue"
            $fontFamily="title"
            fontSize={40}
            lineHeight="50px"
          >
            {price}
          </Text.Heading1>
          <Text.Body1Strong color="darkBlue">
            {currency?.toUpperCase()} {pricePeriod}
          </Text.Body1Strong>
        </Box>

        <Box mt={{ _: 16, lg: 24 }} mb={!isHighlighted && { md: 24 }}>
          {couponText && (
            <Text.Body3 color="darkBlue_80" fontSize={{ _: 12, md: 14 }}>
              {couponText}
            </Text.Body3>
          )}
          <Text.Body3
            data-testid={`plan-card-amount-billed-${subscriptionPeriod}`}
            color="darkBlue_80"
            fontSize={{ _: 12, md: 14 }}
            pt={couponText ? 8 : 28}
          >
            {billingDescription}
          </Text.Body3>
          {!button && !isHighlighted && moneyBackGuaranteeText && (
            <Text.Body3 fontSize={{ _: 12, md: 14 }} pt={8}>
              {moneyBackGuaranteeText}
            </Text.Body3>
          )}
        </Box>
      </Box>
    </Box>
    {button && (
      <Box mx={{ _: 24, xl: 36 }} mb={highlightLabel ? 24 : 28}>
        <LinkWrapper link={`${PRICING_LINK}?step=2&selectedPlanPeriod=${subscriptionPeriod}`}>
          <Button
            type="button"
            variant={isHighlighted ? 'default' : 'outlined'}
            fullWidth
            customEvent={button.buttonProps?.customEvent}
          >
            {button.title}
          </Button>
        </LinkWrapper>
        {moneyBackGuaranteeText && (
          <Text.Body3 fontSize={{ _: 12 }} pt={8} textAlign="center">
            {moneyBackGuaranteeText}
          </Text.Body3>
        )}
      </Box>
    )}
    {isHighlighted && highlightLabel && (
      <Box
        backgroundColor="success"
        borderBottomLeftRadius="sm"
        borderBottomRightRadius="sm"
        pt={12}
        pb={18}
      >
        <Text.Body3Strong
          data-testid="highlighted-money-back"
          as="div"
          textAlign="center"
          color="white"
        >
          {highlightLabel}
        </Text.Body3Strong>
      </Box>
    )}
    {!button && highlightLabelDanger && (
      <Box position="relative" mx={-4}>
        <Box
          position={{ _: 'unset', xl: 'absolute' }}
          backgroundColor="danger_20"
          borderRadius="md"
          border="3px solid"
          borderColor="danger"
          py={16}
          px={32}
          width="100%"
          mb={-4}
        >
          <Text.Body3 color="danger">{highlightLabelDanger}</Text.Body3>
        </Box>
      </Box>
    )}
  </CardContainer>
);

export default CheckoutCard;
