export const breakpoints = {
  maxMobile: '575px',

  tabletSmall: '576px',
  maxTabletSmall: '767px',

  tablet: '768px',
  maxTablet: '1023px',

  desktop: '1024px',
  maxDesktop: '1215px',

  widescreen: '1216px',
  maxWidescreen: '1407px',

  fullHd: '1408px',
};

export const query = {
  maxMobile: `(max-width: ${breakpoints.maxMobile})`,

  minTabletSmall: `(min-width: ${breakpoints.tabletSmall})`,
  tabletSmall: `(min-width: ${breakpoints.tabletSmall}) and (max-width: ${breakpoints.maxTabletSmall})`,
  maxTabletSmall: `(max-width: ${breakpoints.maxTabletSmall})`,

  minTabletLarge: `(min-width: ${breakpoints.tablet})`,
  tabletLarge: `(min-width: ${breakpoints.tablet}) and (max-width: ${breakpoints.maxTablet})`,
  maxTabletLarge: `(max-width: ${breakpoints.maxTablet})`,

  minTablet: `(min-width: ${breakpoints.tabletSmall})`,
  tablet: `(min-width: ${breakpoints.tabletSmall}) and (max-width: ${breakpoints.maxTablet})`,
  maxTablet: `(max-width: ${breakpoints.maxTablet})`,

  minDesktop: `(min-width: ${breakpoints.desktop})`,
  desktop: `(min-width: ${breakpoints.desktop}) and (max-width: ${breakpoints.maxDesktop})`,
  maxDesktop: `(max-width: ${breakpoints.maxDesktop})`,

  minWidescreen: `(min-width: ${breakpoints.widescreen})`,
  widescreen: `(min-width: ${breakpoints.widescreen}) and (max-width: ${breakpoints.maxWidescreen})`,
  maxWidescreen: `(max-width: ${breakpoints.maxWidescreen})`,

  fullHd: `(min-width: ${breakpoints.fullHd})`,
};

export const getVariant =
  (variants) =>
  ({ variant }) =>
    variants[variant] ? variants[variant] : variants.default;

// props from styled system functions - layout and color - that should be filtered from appearing on DOM element as attributes
export const filteredProps = ['display', 'width', 'height', 'overflow', 'size', 'color', 'opacity'];
