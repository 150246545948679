import React from 'react';
import styled from 'styled-components';

const ButtonWrapper = styled.button`
  text-align: center;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  margin: auto;
  font-family: ${({ theme }) => theme?.fonts?.default};
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth || '175px'};

  background: ${({ background, primary }) => background || (primary ? '#4c8ff9' : '#ffffff')};
  color: ${({ color, primary }) => color || (primary ? '#ffffff' : '#4c8ff9')};
  border: ${({ border }) => border || '2px solid #4c8ff9'};
  font-size: ${({ fontSize }) => fontSize || '15px'};
  font-weight: ${({ fontWeight }) => fontWeight || '600'};
  border-radius: ${({ radius }) => radius || '30px'};
  margin: ${({ margin }) => margin || 'auto'};
  padding: ${({ padding }) => padding || '12px 25px'};

  &:hover {
    box-shadow: inset 0 0 100px 100px rgba(1, 1, 1, 0.05);
  }

  &:active {
    box-shadow: inset 0 0 100px 100px rgba(1, 1, 1, 0.07);
  }

  &::after {
    margin: 20px;
  }

  &:focus,
  &:hover,
  &:active {
    outline: unset;
  }
`;

const Button = (props) => <ButtonWrapper {...props}>{props.children}</ButtonWrapper>;

export default Button;
