import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Grid, Show } from '@core';
import { DealsTimer, Icon, ImageCore, LinkWrapper, Text } from '@components';
import { Close } from '@components/icon';
import { TimerProvider } from '@context';
import modalImage from '@images/deals/modal/modal-hero.png';
import { dealsTheme } from '@theme';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

const StyledButton = styled('button')`
  margin: 8px;
  cursor: pointer;
  border: none;
  background: none;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;

  &:focus {
    outline: 0;
  }
`;

const ModalCloseButton = ({ hideModalHandler }) => (
  <StyledButton onClick={hideModalHandler}>
    <Close />
  </StyledButton>
);

const DealModal = ({ hideModal, discountPercentage, buttonLink }) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={dealsTheme}>
      <Grid.Container
        backgroundColor="darkBlue_140"
        position="relative"
        px={{ _: 16, md: 64 }}
        py={{ _: 40, md: 80 }}
        width="90%"
        maxWidth={900}
        borderRadius="md"
        overflow="hidden"
      >
        <ModalCloseButton hideModalHandler={hideModal} />
        <Grid.Row alignItems="center">
          <Grid.Col size={{ _: 12, md: 7 }} zIndex={1}>
            <Text.Heading1
              textAlign={{ _: 'center', md: ' unset' }}
              $fontFamily="title"
              $fontSize={{ mobile: 30, desktop: 40 }}
              $lineHeight={{ mobile: '34px', desktop: '50px' }}
            >
              <Trans
                i18nKey="modals:dealModal.header"
                values={{ discountPercentage }}
                components={[
                  <Text.Body2
                    $lineHeight="inherit"
                    $fontSize="inherit"
                    $fontFamily="inherit"
                    $fontWeight="inherit"
                    color="primary"
                    key={0}
                  />,
                ]}
              />
            </Text.Heading1>
            <Box
              my={16}
              alignItems={{ _: 'center', md: ' unset' }}
              display="flex"
              flexDirection="column"
            >
              <Box width={215} my={{ _: 24, lg: 32 }}>
                <TimerProvider milliseconds={1723000} doubleDigits>
                  <DealsTimer />
                </TimerProvider>
              </Box>
              <Box width={{ _: '100%', md: '80%' }}>
                <LinkWrapper link={buttonLink} withParams>
                  <Button
                    fullWidth
                    size="large"
                    onClick={() =>
                      trackCustomGA4Event({
                        eventName: GA4_EVENTS.modalCTAClick,
                      })
                    }
                  >
                    {t('common:actions.getTheDeal')}
                  </Button>
                </LinkWrapper>
              </Box>
            </Box>
            <Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={{ _: 'center', md: 'unset' }}
                mt={16}
              >
                <Box display="flex" mr={12}>
                  <Icon.Check size={16} color="success" />
                </Box>
                <Text.Body2 $fontSize={{ mobile: 14, desktop: 16 }}>
                  {t('common:30dayMoneyBackGuarantee')}
                </Text.Body2>
              </Box>
            </Box>
          </Grid.Col>
          <Show.TabletLargeAndUp>
            <Box
              position="absolute"
              bottom="15%"
              left={{ md: '25%', lg: '30%' }}
              width={{ md: 700, lg: 800 }}
            >
              <ImageCore src={modalImage} alt="modal-hero" />
            </Box>
          </Show.TabletLargeAndUp>
        </Grid.Row>
      </Grid.Container>
    </ThemeProvider>
  );
};

export default DealModal;
