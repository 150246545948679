import React from 'react';
import styled from 'styled-components';
import { layout, position } from 'styled-system';
import { useTranslation } from 'react-i18next';

import { Box } from '@core';
import { SectionContainerSC as SectionContainer } from '@components';
import { filteredProps } from '@shared/style-helpers';
import { isLTR } from '@shared/translation-helpers';

import { ContentBlockList } from './content-block-list';

const ShapeWrapper = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !filteredProps.includes(prop) && defaultValidatorFn(prop),
})`
  /**
  * adding styles to image itself as otherwise positioning breaks on firefox,
  * because this parent div doesn't get the width of child image
  */
  & > svg,
  & > img {
    position: absolute;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 50%;
    transform: ${({ language }) => `translateX(-50%) ${isLTR(language) ? '' : 'scaleX(-1)'}`};

    ${position}
    ${layout}
  }
`;

const ContentBlockSection = ({
  title,
  description,
  data,
  shape: Shape,
  blockComponent: BlockComponent = ContentBlockList,
  shapeProps,
  wrapperProps,
  sectionProps,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  return (
    <SectionContainer
      data-section-id="ContentBlock"
      title={title}
      description={description}
      // To prevent shapes going outside the bounds and resizing the screen
      containerProps={{ overflowX: 'hidden' }}
      {...sectionProps}
    >
      <Box px={{ _: 16, md: 40 }} position="relative" {...wrapperProps}>
        <BlockComponent data={data} />
        {Shape && (
          <ShapeWrapper {...shapeProps} language={language}>
            <Shape />
          </ShapeWrapper>
        )}
      </Box>
    </SectionContainer>
  );
};

export default ContentBlockSection;
