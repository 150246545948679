import React from 'react';

import { Text } from '@components';

const ErrorText = ({ children, dataTestId }) => (
  <Text.Body3 color="danger" data-testid={dataTestId}>
    {children}
  </Text.Body3>
);

export default ErrorText;
