import React from 'react';

import GooglePlayLogoWhite from '@images/stores/google-play-white.svg';
import AppStoreLogoWhite from '@images/stores/app-store-white.svg';
import GooglePlayLogoDark from '@images/stores/google-play-dark.svg';
import AppStoreLogoDark from '@images/stores/app-store-dark.svg';

import GooglePlayLogoWithText from '@images/stores/google-play-with-text-black-bold.svg';
import AppStoreLogoWithText from '@images/stores/app-store-with-text-black-bold.svg';

import {
  StoreRatings as StoreRatingsBase,
  StoreRatingsWithCount as StoreRatingsBaseWithCount,
  StoreRatingsWithStoreName as StoreRatingsBaseWithStoreName,
} from './store-ratings';

// When updating store ratings in the web, make sure update is done in our emails and apps as well (Just inform PO about it before making any changes here)
const RATINGS = {
  googlePlay: 4.6,
  appStore: 4.7,
};

const RATING_COUNT = {
  googlePlay: '70.4K',
  appStore: '7.9K',
};

const LOGOS = {
  dark: {
    googlePlay: GooglePlayLogoDark,
    appStore: AppStoreLogoDark,
  },
  light: {
    googlePlay: GooglePlayLogoWhite,
    appStore: AppStoreLogoWhite,
  },
};

export const StoreRatings = {
  Google: ({ variant = 'dark' }) => (
    <StoreRatingsBase
      logoImage={{ src: LOGOS[variant].googlePlay, alt: 'Google Play' }}
      rating={RATINGS.googlePlay}
      variant={variant}
    />
  ),
  Apple: ({ variant = 'dark' }) => (
    <StoreRatingsBase
      logoImage={{ src: LOGOS[variant].appStore, alt: 'App Store' }}
      rating={RATINGS.appStore}
      variant={variant}
    />
  ),
};

export const StoreRatingsWithStoreName = {
  Google: ({ variant = 'dark' }) => (
    <StoreRatingsBaseWithStoreName
      logoImage={{ src: LOGOS[variant].googlePlay, alt: 'Google Play' }}
      rating={RATINGS.googlePlay}
      storeName="Google Play Store"
      variant={variant}
    />
  ),
  Apple: ({ variant = 'dark' }) => (
    <StoreRatingsBaseWithStoreName
      logoImage={{ src: LOGOS[variant].appStore, alt: 'App Store' }}
      rating={RATINGS.appStore}
      storeName="iOS App Store"
      variant={variant}
    />
  ),
};

export const StoreRatingsWithCount = {
  Google: () => (
    <StoreRatingsBaseWithCount
      logoImage={{ src: GooglePlayLogoWithText, alt: 'Google Play' }}
      rating={RATINGS.googlePlay}
      ratingCount={RATING_COUNT.googlePlay}
    />
  ),
  Apple: () => (
    <StoreRatingsBaseWithCount
      logoImage={{ src: AppStoreLogoWithText, alt: 'App Store' }}
      rating={RATINGS.appStore}
      ratingCount={RATING_COUNT.appStore}
    />
  ),
};
