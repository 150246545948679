import theme from './theme';

const LIGHT_BLUE = '#C0E2FF';

const dealsThemeHoliday = {
  ...theme,
  colors: {
    ...theme.colors,
    fontDefault: theme.colors.darkBlue,
    backgroundColor: LIGHT_BLUE,
    pickerBackground: theme.colors.darkBlue,
    timer: {
      backgroundColor: '#000720',
      borderColor: '#273858',
      numberBackground: theme.colors.primary_20,
      numberColor: theme.colors.darkBlue,
      titleColor: theme.colors.white,
      numberLabelColor: theme.colors.white,
    },
    cardBackground: LIGHT_BLUE,
    planCardBackground: theme.colors.white,
    footerGradientColor: LIGHT_BLUE,
    footerBlue: LIGHT_BLUE,
  },
  buttons: {
    ...theme.buttons,
    default: {
      primary: {
        color: theme.colors.white,
        default: theme.colors.primary,
        hover: theme.colors.primary_80,
        active: theme.colors.primary_120,
        disabled: theme.colors.darkBlue_40,
      },
    },
  },
  variants: {
    timerBarLogo: 'white',
    navBarLogo: 'white',
    storeRatings: 'dark',
    availablePlatforms: 'dark',
    featuresTabs: 'light',
    footer: 'blue',
  },
  newsOutlets: {
    titleColor: theme.colors.darkBlue_60,
    logoColor: theme.colors.darkBlue_60,
  },
};

export default dealsThemeHoliday;
