import { v4 as uuidv4 } from 'uuid';

export const getItemsFromLocalStorage = () => {
  const localStorageItems = {};
  if (typeof window !== 'undefined') {
    Object.keys(localStorage).forEach((key) => {
      try {
        const localStorageItem = JSON.parse(localStorage.getItem(key));
        if (localStorageItem) {
          if (localStorageItem.expiryDate && new Date().getTime() > localStorageItem.expiryDate) {
            // removes expired items from local storage
            return localStorage.removeItem(key);
          }
          if (localStorageItem.value) {
            localStorageItems[key] = localStorageItem.value;
          }
        }
      } catch {
        return;
      }
    });
  }
  return localStorageItems;
};

const UTM_PARAMETERS = [
  'offer_id',
  'transaction_id',
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'aff_sub',
];

// This function will return uuid local storage item value. If it's expired or missing, new one will be created and returned. Should be used everywhere as UUID getter.
export const getUUID = () => {
  // Adding maxAge of 1740 seconds which is 29 minutes. Order expires after 30 minutes in the backend side, which would cause all following request to fail. After 29 minutes, new uuid will be created.
  setLocalStorageItem({ name: 'uuid', value: uuidv4(), maxAge: 1740, shouldOverride: false });
  return getLocalStorageItem('uuid');
};

export const removeUUID = () => {
  localStorage.removeItem('uuid');
};

// TODO reuse setLocalStorageItem in setLocalStorageTracking
export const setLocalStorageItem = ({ name, value, maxAge = 2600000, shouldOverride = true }) => {
  if (!shouldOverride && getLocalStorageItem(name)) {
    return;
  }

  const expiryDate = new Date(new Date().getTime() + maxAge * 1000).getTime();
  localStorage.setItem(name, JSON.stringify({ value: value, expiryDate }));
};

// TODO reuse getLocalStorageItem in getItemsFromLocalStorage
export const getLocalStorageItem = (name) => {
  const localStorageItem = JSON.parse(localStorage.getItem(name));
  if (localStorageItem) {
    if (localStorageItem.expiryDate && new Date().getTime() > localStorageItem.expiryDate) {
      // removes expired items from local storage
      return localStorage.removeItem(name);
    }
    if (localStorageItem.value) {
      return localStorageItem.value;
    }
  }
};

export const setLocalStorageTracking = (props) => {
  const maxAge = 2600000;
  const expiryDate = new Date(new Date().getTime() + maxAge * 1000).getTime();

  UTM_PARAMETERS.forEach((parameter) => {
    if (props && props[parameter]) {
      localStorage.setItem(parameter, JSON.stringify({ value: props[parameter], expiryDate }));
    }
  });
};
