import React from 'react';
import styled from 'styled-components';

import { query } from '@shared/style-helpers';
import { Loader } from '@components';

const Wrapper = styled('div')`
  width: 100%;
  min-width: 280px;
  height: fit-content;
  border-style: solid;
  border-radius: 8px;
  min-height: 195px;
  margin-top: 16px;

  cursor: ${({ onClick }) => onClick && 'pointer'};

  position: relative;
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.primary : theme.colors.primary_20};
  border-width: ${({ isActive }) => (isActive ? '4px' : '0')};

  &:hover {
    border-color: ${({ theme, onClick }) => onClick && theme.colors.primary};
  }

  @media ${query.minWidescreen} {
    border-width: ${({ isActive }) => (isActive ? '4px' : '2px')};
    // These margins is needed to avoid plan flicker on isActive state change. Flicker happens because of different width of the border for active and non active cards.
    margin-top: ${({ isActive }) => (isActive ? '0px' : '2px')};
    margin-bottom: ${({ isActive }) => (isActive ? '0px' : '2px')};
    width: ${({ isHighlighted }) => (isHighlighted ? '432px' : '368px')};
  }
`;

const LoaderWrapper = styled('div')`
  display: flex;
  height: 100%;
  flex: 1;
  align-items: center;
  justify-content: center;

  @media ${query.minDesktop} {
    height: 240px;
  }
`;

const CardContainer = ({ children, isLoading, isActive, isHighlighted, onClick, dataTestId }) => (
  <Wrapper
    isActive={isActive}
    isHighlighted={isHighlighted}
    onClick={onClick}
    data-testid={dataTestId}
  >
    {isLoading ? (
      <LoaderWrapper>
        <Loader isLoading />
      </LoaderWrapper>
    ) : (
      children
    )}
  </Wrapper>
);

export default CardContainer;
