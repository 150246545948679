import loadable from '@loadable/component';

export const ArrowIcon = loadable(() => import('./arrow-icon'));
export const StarWithBorderIcon = loadable(() => import('./star-with-border-icon'));
export const Shield = loadable(() => import('./shield'));
export const Close = loadable(() => import('./close'));

export { NewsLogos } from './news-logos';

export { StreamingIcon, PrivacyIcon, SecurityIcon, SpeedIcon } from './features-tabs';
