import { useEffect, useMemo, useState } from 'react';

const useDebounce = (delay = 500) => {
  const [callback, setCallback] = useState();

  useEffect(() => {
    if (callback) {
      const handler = setTimeout(() => {
        callback();
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    }
  }, [callback, delay]);

  return {
    debounceCallback: useMemo(() => (callback) => setCallback(() => callback), []),
  };
};

export default useDebounce;
