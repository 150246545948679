import React from 'react';

import { Grid } from '@core';
import { Divider } from '@components';

import { Copyright, FooterAccordion } from './components';
import { backgroundVariants, dividerVariants } from './components/variants';

const FooterMobile = ({ elements = {}, variant, compact }) => (
  <Grid.Container px={24} backgroundColor={backgroundVariants[variant]}>
    {!compact && elements.top && (
      <Grid.Row pt={32} mb={16}>
        <FooterAccordion elements={elements.top} variant={variant} />
      </Grid.Row>
    )}
    <Grid.Row pb={16}>
      <Divider
        data-testid="footer-divider-mobile"
        mt={0}
        mb={24}
        backgroundColor={dividerVariants[variant]}
      />
      <Copyright elements={elements.bottom} variant={variant} />
    </Grid.Row>
  </Grid.Container>
);

export default FooterMobile;
