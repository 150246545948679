import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { Button } from '@core';

import { ModalHeader, ModalWrapper } from './components';

const MobileWrapper = styled(ModalWrapper)`
  max-width: 270px;
`;

const ModalContentMobile = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;

  & > a {
    z-index: 2;
  }
`;

const DiscountImage = styled('img')`
  width: 140px;
  z-index: 2;
`;

const ContentLabel = styled('p')`
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 10px;
`;

const MobileLayout = ({ hideModal, timer, buttonLink, buttonLabel, buttonProps, content }) => (
  <MobileWrapper>
    <ModalHeader timer={timer} hideModalHandler={hideModal} />

    <ModalContentMobile>
      <DiscountImage src={content.image} alt={'discount'} />
      <ContentLabel>{content.subLabel}</ContentLabel>

      {/* TODO discuss with UX about the different style button */}
      <Link to={buttonLink}>
        <Button type="button" {...buttonProps}>
          {buttonLabel}
        </Button>
      </Link>
    </ModalContentMobile>
  </MobileWrapper>
);

export default MobileLayout;
