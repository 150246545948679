export const APPS_LINKS = {
  android: 'https://atlasvpn.onelink.me/Vesa/5ncbp5xq',
  ios: 'https://atlasvpn.onelink.me/Vesa/25z8n0ib',
  // windows: 'https://atlasv.pn/get-app-for-windows',
  windows: 'https://downloads.atlasvpn.com/apps/windows/AtlasVPN-x64.msi', // Temporary solution, adding direct link to storage because bitly link is blocked
  windows32bit: 'https://atlasv.pn/get-app-for-windows-x86',
  mac: 'https://apps.apple.com/us/app/atlas-vpn-secure-vpn-proxy/id1525872949',
  appleTv: 'https://apps.apple.com/us/app/atlas-vpn-fast-vpn-for-a-tv/id6467854283',
  fireTv: 'https://www.amazon.com/gp/product/B09LVHKCY8',
  linux: 'https://downloads.atlasvpn.com/apps/linux/atlasvpn-repo.deb',
  universalLink: 'https://atlasvpn.onelink.me/Vesa/58f6a4cf',
};
