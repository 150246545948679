import React from 'react';
import { useTranslation } from 'react-i18next';

import { getNewsLogosRow } from '@shared/constants/constants';
import { Box, Grid } from '@core';
import { LinkWrapper, SectionContainerSC, Text } from '@components';
import { COLORS } from '@theme';

const NewsOutletsRow = ({
  title,
  titleProps,
  logos,
  wrapperProps,
  logoColor = COLORS.darkBlue_60,
  isWithLink = false,
}) => {
  const {
    i18n: { language },
  } = useTranslation();

  const renderedLogos = logos || getNewsLogosRow(language);

  return (
    <SectionContainerSC
      data-section-id="NewsOutlets"
      containerProps={{ overflowX: 'auto' }}
      wrapperProps={wrapperProps}
    >
      <Grid.Row
        alignItems="center"
        px={40}
        flexWrap="nowrap"
        width={{ _: 1024, lg: 'auto' }}
        columnGap={56}
        mx="auto"
      >
        <Box flexShrink={0}>
          <Text.Body2Strong color="darkBlue_60" {...titleProps}>
            {title}
          </Text.Body2Strong>
        </Box>
        {renderedLogos.map(({ logo: Logo, link }, index) => (
          <LinkWrapper link={isWithLink ? link : null} target="_blank" key={index}>
            <Box display="flex" height={32} justifyContent="center">
              <Logo color={logoColor} />
            </Box>
          </LinkWrapper>
        ))}
      </Grid.Row>
    </SectionContainerSC>
  );
};

export default NewsOutletsRow;
