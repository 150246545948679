import React from 'react';
import styled from 'styled-components';
import { ErrorText } from '@containers/checkout/components';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';
import { toSnakeCase } from '@shared/format-helpers';
import { Text } from '@components';

const StyledInput = styled.input`
  border: ${({ error, theme }) =>
    `solid 1px ${error ? theme.colors.danger : theme.colors.darkBlue_40}`};
  padding: 12px 16px;
  border-radius: 4px;
  height: 45px;
  font-size: 16px;
  width: 100%;
  font-family: ${({ theme }) => theme?.fonts?.default};

  &::placeholder {
    color: ${({ theme }) => theme.colors.darkBlue_40};
  }
  &:focus {
    outline: unset;
  }
  &:hover {
    border: ${({ error, theme }) =>
      `solid 1px ${error ? theme.colors.danger : theme.colors.darkBlue_60}`};
  }

  &.error {
    border: ${({ error, theme }) =>
      `solid 1px ${error ? theme.colors.danger : theme.colors.darkBlue_60}`};
  }
`;

const CheckoutInput = ({ name, label, error, onFocus, onBlur, ...rest }) => (
  <React.Fragment>
    {label && <Text.Body4 mb={8}>{label}</Text.Body4>}
    <StyledInput
      name={name}
      error={error}
      {...rest}
      onFocus={(e) => {
        onFocus && onFocus(e);
        name && trackCustomGA4Event({ eventName: GA4_EVENTS.fieldFocus(toSnakeCase(name)) });
      }}
      onBlur={(e) => {
        onBlur && onBlur(e);
        name &&
          trackCustomGA4Event({
            eventName: GA4_EVENTS.fieldFocusOut(toSnakeCase(name)),
            params: {
              error_title: error,
            },
          });
      }}
    />
    {error && <ErrorText dataTestId={`checkout-${name}-error`}>{error}</ErrorText>}
  </React.Fragment>
);

export default CheckoutInput;
