import React from 'react';

export const Blob1 = ({ color = '#BFE2FF', height = '100%' }) => {
  return (
    <svg height={height} viewBox="0 0 2918 788" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M674.107 35.3683C1296.26 -36.6317 2445.67 11.0383 2748.62 91.2183C2992.44 155.758 2921.29 561.468 2846.79 663.098C2718.17 838.568 1685.75 738.868 1119.27 777.788C620.167 812.068 395.597 748.268 258.757 724.128C-74.7733 665.288 -221.053 138.968 674.107 35.3683Z"
        fill={color}
      />
    </svg>
  );
};

export const Blob2 = ({ color = '#BFE2FF', height = '100%' }) => {
  return (
    <svg height={height} viewBox="0 0 1897 456" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M365.64 98.2186C365.64 98.2186 610.64 -12.3814 764.64 2.21862C918.64 16.8186 1355.96 123.399 1494.96 148.469C1633.96 173.539 1901.96 262.189 1896.54 314.779C1892.97 349.779 1646.54 396.429 1199.41 433.499C752.28 470.569 176.49 461.999 42.0299 396.279C-92.4301 330.559 129.11 195.949 239.25 149.519C349.39 103.089 365.64 98.2186 365.64 98.2186Z"
        fill={color}
      />
    </svg>
  );
};

export const Blob3 = ({ color = '#BFE2FF', height = '100%' }) => {
  return (
    <svg height={height} viewBox="0 0 2359 456" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1710.7 2.87819C1165.79 -7.85181 907.401 24.8783 631.011 69.7683C200.811 139.618 -55.1493 261.158 10.1007 348.068C152.461 537.698 598.791 237.828 1026.84 372.468C1409.49 492.818 1945.19 468.218 2187.18 385.238C2480.44 284.678 2442.56 17.2882 1710.7 2.87819Z"
        fill={color}
      />
    </svg>
  );
};

export const Blob4 = ({ color = '#BFE2FF', height = '100%' }) => {
  return (
    <svg height={height} viewBox="0 0 2182 409" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1617.13 0.620255C1072.13 -4.90974 838.926 48.1102 560.276 71.8702C51.9564 115.21 -47.8435 212.27 18.0865 286.8C161.946 449.4 502.417 282.8 942.977 356.29C1366.87 427.03 1716.61 419.66 1966.82 373.72C2257.81 320.3 2349.08 8.04026 1617.13 0.620255Z"
        fill={color}
      />
    </svg>
  );
};

export const Blob5 = ({ color = '#BFE2FF', height = '100%' }) => {
  return (
    <svg height={height} viewBox="0 0 1897 456" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1531.36 98.2186C1531.36 98.2186 1286.36 -12.3814 1132.36 2.21862C978.36 16.8186 541.04 123.399 402.04 148.469C263.04 173.539 -4.95979 262.189 0.460205 314.779C4.03021 349.779 250.46 396.429 697.59 433.499C1144.72 470.569 1720.51 461.999 1854.97 396.279C1989.43 330.559 1767.89 195.949 1657.75 149.519C1547.61 103.089 1531.36 98.2186 1531.36 98.2186Z"
        fill={color}
      />
    </svg>
  );
};

export const Blob6 = ({ color = '#BFE2FF', height = '100%' }) => {
  return (
    <svg height={height} viewBox="0 0 2359 456" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M648.299 2.87819C1193.21 -7.85181 1451.6 24.8783 1727.99 69.7683C2158.19 139.618 2414.15 261.158 2348.9 348.068C2206.54 537.698 1760.21 237.828 1332.16 372.468C949.509 492.818 413.809 468.218 171.819 385.238C-121.441 284.678 -83.5607 17.2882 648.299 2.87819Z"
        fill={color}
      />
    </svg>
  );
};

export const Blob7 = ({ color = '#BFE2FF', height = '100%' }) => {
  return (
    <svg height={height} viewBox="0 0 2410 592" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1560.08 0.796521C1075.84 -7.06348 662.304 44.9165 433.464 83.1765C-71.4461 167.617 -58.5361 405.387 89.4639 494.967C280.104 610.437 598.314 591.817 1006.93 589.137C1913.78 583.137 1932.93 557.057 2175.32 467.617C2555.03 327.497 2524.56 16.4365 1560.08 0.796521Z"
        fill={color}
      />
    </svg>
  );
};

export const Blob8 = ({ color = '#BFE2FF', height = '100%' }) => {
  return (
    <svg height={height} viewBox="0 0 2799 582" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1737.5 410.355C1349.5 401.265 1139.11 572.155 584.275 581.125C175.845 587.725 -81.2255 358.125 21.7345 275.785C154.505 169.715 653.445 107.455 979.075 69.2848C1815.27 -28.7152 2297.91 1.65477 2500.37 21.3348C2728.84 43.5348 3315.5 447.425 1737.5 410.355Z"
        fill={color}
      />
    </svg>
  );
};

export const Blob9 = ({ color = '#BFE2FF', height = '100%' }) => {
  return (
    <svg height={height} viewBox="0 0 1770 726" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1361.89 391.472C906.827 558.997 750.5 830.498 345 684.997C-11.4783 557.086 -61.0022 142.927 59.4293 -3.49493C287.698 -281.027 793.368 -117.294 1215.7 -148.027C1587.79 -175.096 1764.52 -101.815 1768.33 -3.49493C1779.33 280.497 1655.83 309.997 1361.89 391.472Z"
        fill={color}
      />
    </svg>
  );
};

export const Blob10 = ({ color = '#BFE2FF', height = '100%' }) => (
  <svg height={height} viewBox="0 0 1253 495" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M928.684 0.486378C615.701 -6.21258 481.779 58.015 321.756 86.7975C29.8376 139.299 -27.4756 256.876 10.3867 347.16C93.0027 544.132 288.528 342.315 541.533 431.34C784.965 517.033 985.813 508.105 1129.5 452.454C1296.61 387.742 1349.03 9.47485 928.684 0.486378Z"
      fill={color}
    />
  </svg>
);

export const Blob11 = ({ color = '#BFE2FF', height = '100%' }) => (
  <svg height={height} viewBox="0 0 1761 330" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1580.16 76.9658C1473.38 53.5614 1387.82 53.9515 1256.18 40.2989C1125.99 26.6463 1038.68 -2.09558 850.252 0.121256C750.786 1.29149 655.708 24.6959 535.764 40.2989C417.282 55.9019 276.86 63.7034 173.005 85.9376C69.1512 108.172 3.32799 144.449 0.402581 181.506C-3.9856 218.563 26.6775 221.486 133.177 277.464C232.177 329.5 327.27 329.89 448.677 329.5C570.085 329.11 752.19 282.535 831.177 277.464C911.628 272.003 1186.4 313.211 1332.68 317.502C1478.95 321.793 1543.69 317.502 1624.04 286.826C1746.68 240.002 1771.78 225.974 1755.69 183.456C1741.06 140.548 1686.94 100.37 1580.16 76.9658Z"
      fill={color}
    />
  </svg>
);

export const Blob12 = ({ color = '#BFE2FF', height = '100%' }) => (
  <svg height={height} viewBox="0 0 5555 883" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M4117.02 1.00512C2729.74 -11.4397 2136.14 107.877 1426.85 161.347C132.948 258.881 -121.088 477.306 46.7336 645.03C412.923 1010.95 1657.5 778.378 2392 818.064C2553 826.763 4370.24 944.021 5007.14 840.636C5747.84 720.419 5980.16 17.7032 4117.02 1.00512Z"
      fill={color}
    />
  </svg>
);

export const Blob13 = ({ color = '#BFE2FF', height = '100%' }) => (
  <svg height={height} viewBox="0 0 2200 460" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1630.57 459.548C1081.04 465.773 845.901 406.087 564.934 379.34C52.3884 330.55 -48.2413 221.287 18.2368 137.386C163.293 -45.6577 506.593 141.889 950.816 59.1592C1378.23 -20.4751 1730.88 -12.1785 1983.17 39.5376C2276.58 99.6742 2368.61 451.195 1630.57 459.548Z"
      fill={color}
    />
  </svg>
);

export const Blob14 = ({ color = '#BFE2FF', height = '100%' }) => (
  <svg height={height} viewBox="0 0 3489 826" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2320.82 694.43C1920.78 682.89 1453.13 806.67 881.216 824.4C-75.2344 854.06 -154.794 48.44 180.936 0L2937.66 0.000370026C3593.27 27.7204 3947.78 741.48 2320.82 694.43Z"
      fill={color}
    />
  </svg>
);

export const Blob15 = ({ color = '#BFE2FF', height = '100%' }) => (
  <svg height={height} viewBox="0 0 3084 382" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2063.49 325.169C1707.71 320.219 1291.79 373.349 783.146 380.959C-67.4943 393.689 -138.254 47.8693 160.336 27.0693C927.106 -26.3207 2391.34 15.0791 2578.09 18.8891C3161.17 30.7891 3510.47 345.359 2063.49 325.159V325.169Z"
      fill={color}
    />
  </svg>
);

export const Blob16 = ({ color = '#BFE2FF', height = '100%' }) => (
  <svg height={height} viewBox="0 0 4130 665" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1055.95 0C2109.29 0 2558.9 0 3114.37 0C3790.66 0 4154.24 60.21 4128.74 323.33C4101.08 608.74 3050.44 471.64 2340.68 584.07C1664 691.26 899.411 682.87 422.951 608C-131.179 520.95 -337.889 12.09 1055.95 0Z"
      fill={color}
    />
  </svg>
);
