import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'gatsby-plugin-react-i18next';

import { Box, Show } from '@core';
import { LinkWrapper, Tooltip } from '@components';
import { query } from '@shared/style-helpers';
import { GA4_EVENTS, TRACK_EVENTS } from '@shared/constants/google';
import { trackCustomEvent, trackCustomGA4Event } from '@shared/google-utils';

import BarButton from './bar-button';

const ContentContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1160px;
  margin-left: auto;
  margin-right: auto;
  font-family: ${({ theme }) => theme?.fonts?.default};
  color: white;
  height: 100%;
  line-height: 40px;
`;

const Column = styled('div')`
  @media ${query.maxTablet} {
    display: ${({ showOnMobile }) => (showOnMobile ? 'flex' : 'none')};
    margin: 0 8px;
  }

  @media ${query.minDesktop} {
    display: ${({ hideOnDesktop }) => (hideOnDesktop ? 'none' : 'unset')};
    margin: 0 12px;
  }
`;

const Label = styled('span')`
  color: ${({ theme }) => theme.colors.darkBlue_60};
  font-size: 14px;
  font-weight: 600;
`;

const Value = styled('span')`
  color: ${({ isWhite, theme }) => (isWhite ? theme.colors.darkBlue : theme.colors.white)};
  font-size: 14px;
  font-weight: 600;
`;

const TooltipWrapper = styled.div`
  display: inline-block;
`;

const HighlightedValue = styled('span')`
  color: ${({ color, theme }) => theme.colors[color] || theme.colors.darkBlue};
  font-weight: 700;
  font-size: 14px;
`;

const BarContent = ({ location, ip, isp, isProtected, isWhite, button }) => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <Column>
        <Label>{t('common:ipBar.yourLocation') + ':'}</Label>
        &nbsp;&nbsp;
        <Value isWhite={isWhite}>{location}</Value>
      </Column>
      <Column>
        <Label>{t('common:ipBar.yourIp') + ':'}</Label>
        &nbsp;&nbsp;
        <Value isWhite={isWhite}>{ip}</Value>
      </Column>
      <Column>
        <Label>{t('common:ipBar.internetServiceProvider') + ':'}</Label>
        &nbsp;&nbsp;
        <Value isWhite={isWhite}>{isp}</Value>
      </Column>
      <Column showOnMobile={true}>
        <Label>{t('common:ipBar.status') + ':'}</Label>
        &nbsp;&nbsp;
        {isProtected ? (
          <HighlightedValue color="success">{t('common:ipBar.protected')}</HighlightedValue>
        ) : (
          <LinkWrapper withParams>
            <Box
              display="inline-block"
              onClick={() => {
                trackCustomEvent({
                  label: 'ip_bar_status',
                  category: TRACK_EVENTS.category.buyApp,
                  action: TRACK_EVENTS.action.clickToBuy,
                });
                trackCustomGA4Event({
                  eventName: GA4_EVENTS.infobarUnprotectedClick,
                });
              }}
            >
              <TooltipWrapper>
                <Show.DesktopAndUp>
                  <Tooltip content={t('common:ipBar.unprotectedMessage')} offset="-120px">
                    <HighlightedValue color="danger">
                      {t('common:ipBar.unprotected')}
                    </HighlightedValue>
                  </Tooltip>
                </Show.DesktopAndUp>
              </TooltipWrapper>
              <TooltipWrapper>
                <Show.TabletLarge>
                  <Tooltip content={t('common:ipBar.unprotectedMessage')} offset="40px">
                    <HighlightedValue color="danger">
                      {t('common:ipBar.unprotected')}
                    </HighlightedValue>
                  </Tooltip>
                </Show.TabletLarge>
              </TooltipWrapper>
            </Box>
          </LinkWrapper>
        )}
      </Column>
      {!isProtected && button && (
        <Column showOnMobile={true} hideOnDesktop={true}>
          <LinkWrapper link={button.link} withParams>
            <BarButton
              onClick={() => {
                trackCustomGA4Event({
                  eventName: GA4_EVENTS.infobarUnprotectedClick,
                });
              }}
              background={isWhite ? '#3a3b4d' : 'white'}
              color={isWhite ? 'white' : '#3a3b4d'}
              fontSize="12px"
              padding="5px 5px"
              border="unset"
              minWidth="120px"
            >
              {button.title}
            </BarButton>
          </LinkWrapper>
        </Column>
      )}
    </ContentContainer>
  );
};

export default BarContent;
