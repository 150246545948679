import React from 'react';

const CheckBox = ({ isActive }) =>
  isActive ? (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#4D8EF8" />
      <rect x="4.5" y="4.5" width="15" height="15" rx="7.5" fill="#4D8EF8" stroke="#4D8EF8" />
    </svg>
  ) : (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <rect x="0.5" y="0.5" width="23" height="23" rx="11.5" stroke="#7F8193" />
    </svg>
  );

export default CheckBox;
