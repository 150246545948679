import styled from 'styled-components';
import { color, layout, margin } from 'styled-system';

import { filteredProps } from '@shared/style-helpers';

const Divider = styled.hr.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !filteredProps.includes(prop) && defaultValidatorFn(prop),
})`
  border: none;
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.white};
  margin: 24px 0;

  ${color}
  ${layout}
  ${margin}
`;

export default Divider;
