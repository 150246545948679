export const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export const sortDataByKey = (data, key, isReversed) => {
  return data.sort((a, b) => {
    return isReversed ? (a[key] < b[key] ? 1 : -1) : a[key] > b[key] ? 1 : -1;
  });
};

export const filterByUniqueKey = (array = [], key) => {
  const uniqueValues = new Set();
  const filteredArray = array.filter((item) => {
    const duplicate = uniqueValues.has(item[key]);
    uniqueValues.add(item[key]);
    return !duplicate;
  });

  return filteredArray;
};

// sorts array by given order and puts values that do not exist in defined order at the end of an array
export const mapOrder = (array, givenOrder, key) => {
  const order = givenOrder.reduce((acc, curr, index) => {
    acc[curr] = index + 1;
    return acc;
  }, {});
  const theSort = array.sort((a, b) => (order[a[key]] || Infinity) - (order[b[key]] || Infinity));
  return theSort;
};

export const isClient = typeof window !== 'undefined';

export const toCamelCase = (object) => {
  const newObj = {};
  for (const [key, value] of Object.entries(object)) {
    newObj[key.replace(/_(\w)/g, (match) => match[1].toUpperCase())] = value;
  }
  return newObj;
};

export const parseISO8601DurationToMonths = (duration) => {
  const matches = duration.match(/P(\d+Y)?(\d+M)?(\d+D)?/);

  const years = parseInt(matches[1]) || 0;
  const months = parseInt(matches[2]) || 0;
  const days = parseInt(matches[3]) || 0;

  return years * 12 + months + Math.round(days / 30);
};
