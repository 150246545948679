import React from 'react';
import styled, { css } from 'styled-components';
import { space, textAlign } from 'styled-system';

import { Text } from '@components';

const cellBaseStyles = css`
  padding-left: 40px;
  padding-right: 40px;
  height: 48px;
  vertical-align: middle;
  color: ${({ theme }) => theme.colors.darkBlue};
  text-align: left;

  ${textAlign}
  ${space}
`;

const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  background-color: ${({ theme }) => theme.colors.tableBackground};
`;

const TableRow = styled.tr`
  background-color: ${({ isOddRow }) => isOddRow && '#ffffff'};
`;

const HeaderCell = styled.th`
  && {
    ${cellBaseStyles}

    ${({ isHighlighted }) =>
      isHighlighted &&
      css`
        color: #ffffff;
        background-color: rgba(77, 142, 248, 1);

        ${({ isFirstRow }) =>
          isFirstRow &&
          css`
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;
          `}
      `}
  }
`;

const TableCell = styled.td`
  && {
    ${cellBaseStyles}

    ${({ isHighlighted }) =>
      isHighlighted &&
      css`
        color: #ffffff;
        background-color: ${({ isOddRow }) =>
          isOddRow ? 'rgba(77, 142, 248, 0.8)' : 'rgba(77, 142, 248, 1)'};

        ${({ isLastRow }) =>
          isLastRow &&
          css`
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
          `}
      `};
  }
`;

const Table = ({ children }) => <StyledTable>{children}</StyledTable>;

Table.Head = ({ children }) => <thead>{children}</thead>;

Table.Body = ({ children }) => <tbody>{children}</tbody>;

Table.Row = ({ children, isOddRow }) => <TableRow isOddRow={isOddRow}>{children}</TableRow>;

Table.HeaderCell = ({
  children,
  isHighlighted,
  isFirstRow,
  textAlign,
  colSpan,
  rowSpan,
  spacing,
}) => (
  <HeaderCell
    isHighlighted={isHighlighted}
    isFirstRow={isFirstRow}
    textAlign={textAlign}
    colSpan={colSpan}
    rowSpan={rowSpan}
    {...spacing}
  >
    <Text.Body3 as="span" color="inherit">
      {children}
    </Text.Body3>
  </HeaderCell>
);

Table.Cell = ({ children, isHighlighted, isOddRow, isLastRow, textAlign, spacing }) => (
  <TableCell
    isHighlighted={isHighlighted}
    isOddRow={isOddRow}
    isLastRow={isLastRow}
    textAlign={textAlign}
    {...spacing}
  >
    <Text.Body3Strong as="span" color="inherit">
      {children}
    </Text.Body3Strong>
  </TableCell>
);

export default Table;
