import React from 'react';
import loadable from '@loadable/component';
import { useTranslation } from 'react-i18next';

const CoreSlider = loadable(() => import('@atlas/core-atlas-vpn/dist/slider'));

const Slider = (props) => {
  const {
    i18n,
    i18n: { language },
  } = useTranslation();

  return <CoreSlider {...props} direction={i18n.dir(language)} />;
  // Note that the slider won't be flipped until the actual language is arabic AND detected by i18n
  // For testing you can set direction prop value to 'rtl'
};

export default Slider;
