import { useEffect, useState } from 'react';
import i18nCountriesProvider from 'i18n-iso-countries';

const useI18nCountries = (language = 'en') => {
  const [i18nCountries, setI18nCountries] = useState(null);

  useEffect(() => {
    import(`i18n-iso-countries/langs/${language.split('-')[0]}.json`).then((res) => {
      i18nCountriesProvider.registerLocale(res);

      // this has to be loaded always despite active language as links functionality in /servers page requires countries in english
      import(`i18n-iso-countries/langs/en.json`).then((res) => {
        i18nCountriesProvider.registerLocale(res);
        setI18nCountries(i18nCountriesProvider);
      });
    });
  }, [language]);

  return {
    i18nCountries,
    isLoading: !i18nCountries,
  };
};

export default useI18nCountries;
