import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Trans, useTranslation } from 'react-i18next';

import { Box, Button, Grid } from '@core';
import { ImageCore, Text } from '@components';
import { dealsTheme } from '@theme';

import atlasCouponExpired from '@images/deals/atlas-coupon-expired.svg';

const DealsCouponErrorModal = ({ couponCode, hideModal }) => {
  const { t } = useTranslation();

  return (
    <Grid.Container
      width={{ _: 328, sm: 528 }}
      py={{ _: 32, sm: 40 }}
      backgroundColor="white"
      borderRadius="md"
    >
      <Grid.Container width={{ _: 280, sm: 368 }}>
        <Box height={{ _: 137, sm: 180 }}>
          <ImageCore src={atlasCouponExpired} alt="coupon expired" />
        </Box>
        <Text.Heading2
          fontFamily="title"
          fontSize={25}
          lineHeight="32px"
          mt={24}
          color="darkBlue"
          textAlign="center"
        >
          {t('deals:checkoutErrorModal.title')}
        </Text.Heading2>
        <Text.Body4 textAlign="center" my={16} color="darkBlue">
          <Trans
            i18nKey="deals:checkoutErrorModal.description"
            values={{ couponCode }}
            components={[<Text.Body4Strong as="span" key={0} color="darkBlue" />]}
          />
        </Text.Body4>
        <ThemeProvider theme={dealsTheme}>
          <Button type="button" size="large" fullWidth onClick={hideModal}>
            {t('deals:checkoutErrorModal.getMeToTheDeal')}
          </Button>
        </ThemeProvider>
      </Grid.Container>
    </Grid.Container>
  );
};

export default DealsCouponErrorModal;
