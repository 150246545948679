import { isAndroid, isIOS, isMacOs } from 'react-device-detect';
import { formatLink } from '@shared/format-helpers';
import { APPS_LINKS } from '@shared/constants/apps';
import { isClient } from '@shared/helpers';

export const getAppLink = (t) => {
  if (isIOS) {
    return formatLink(t('navigation:paths.vpn-for-iphone'));
  } else if (isAndroid) {
    return formatLink(t('navigation:paths.vpn-for-android'));
  } else if (isMacOs) {
    return formatLink(t('navigation:paths.vpn-for-mac'));
  } else {
    return formatLink(t('navigation:paths.vpn-for-windows'));
  }
};

export const getDownloadLinkByDevice = () => {
  if (isClient) {
    if (isIOS) {
      return APPS_LINKS.ios;
    } else if (isAndroid) {
      if (navigator.userAgent.includes('AFT')) {
        // Amazon Fire TV runs on Android, react-device-detect doesn't cover this
        return APPS_LINKS.fireTv;
      } else {
        return APPS_LINKS.android;
      }
    } else if (isMacOs) {
      return APPS_LINKS.mac;
    } else if (navigator.userAgent.includes('Linux')) {
      return APPS_LINKS.linux;
    } else {
      return APPS_LINKS.windows;
    }
  }
};
