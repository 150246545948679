import React from 'react';
import { useTheme } from 'styled-components';

import { Box, Grid } from '@core';
import { ImageCore, LOADING_OPTIONS, LinkWrapper } from '@components';
import { GA4_EVENTS } from '@shared/constants/google';
import atlasLogoBlue from '@images/logos/atlas-logo.svg';
import atlasLogoWhite from '@images/logos/atlas-logo-white.svg';

const logoVariants = {
  white: atlasLogoWhite,
  blue: atlasLogoBlue,
};

const Wrapper = ({ children }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <Grid.Container height={{ _: 64, md: 80 }}>
        <Grid.Row
          px={{ _: 24, md: 40 }}
          py={8}
          maxWidth={1280}
          m="auto"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="nowrap"
        >
          <LinkWrapper link="/" customEvent={{ eventName: GA4_EVENTS.atlasLogoClick }}>
            <ImageCore
              src={logoVariants[theme.variants?.navBarLogo]}
              alt="atlasVPN"
              loading={LOADING_OPTIONS.eager}
            />
          </LinkWrapper>
          <Box display="flex" alignItems="center">
            {children}
          </Box>
        </Grid.Row>
      </Grid.Container>
    </React.Fragment>
  );
};

export default Wrapper;
