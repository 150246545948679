import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const AnimationWrapper = styled.div`
  overflow: hidden;
  // max-height not set until ref has the value
  max-height: ${({ maxHeight }) => maxHeight !== null && `${maxHeight}px`};
  transition-property: max-height;
  transition-duration: ${({ duration }) => `${duration}ms`};
  transition-timing-function: ease-in-out;
`;

const HeightAnimation = ({ children, visible, duration = 500 }) => {
  const wrapperRef = useRef(null);
  const [maxHeight, setMaxHeight] = useState(visible ? null : 0);

  useEffect(() => {
    if (wrapperRef.current) {
      if (visible) {
        setMaxHeight(wrapperRef.current.clientHeight);
      } else {
        setMaxHeight(0);
      }
    }
  }, [visible]);

  return (
    <AnimationWrapper duration={duration} maxHeight={maxHeight}>
      <div ref={wrapperRef}>{children}</div>
    </AnimationWrapper>
  );
};

export default HeightAnimation;
