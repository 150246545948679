import React from 'react';
import styled from 'styled-components';

const TextContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: stretch;
`;

const IconWrapper = styled('div')`
  margin: ${({ isRight }) => (isRight ? '0 0 0 12px' : '0 12px 0 0')};
  display: flex;
`;

const TextWithIcon = ({
  textComponent: Component = ({ children }) => children,
  IconLeft,
  IconRight,
  iconProps = { size: 20 },
  children,
  ...rest
}) => (
  <TextContainer>
    {IconLeft && (
      <IconWrapper>
        <IconLeft {...iconProps} />
      </IconWrapper>
    )}
    <Component {...rest}>{children}</Component>
    {IconRight && (
      <IconWrapper isRight>
        <IconRight {...iconProps} />
      </IconWrapper>
    )}
  </TextContainer>
);

export default TextWithIcon;
