import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { color, layout } from 'styled-system';

import { Box } from '@core';
import { Icon, Text } from '@components';
import { trackCustomGA4Event } from '@shared/google-utils';
import { theme } from '@theme';

const StepWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  white-space: nowrap;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
`;

const TransitionWrapper = styled.div`
  transition: 300ms;

  ${layout};
  ${color};
`;

const StepBubble = ({ title, isActive, isCompleted, handleClick, index }) => (
  <StepWrapper onClick={handleClick} data-testid={`step-indicator-${index + 1}`}>
    <Box
      height={24}
      width={24}
      border="solid 2px"
      borderColor={isCompleted || isActive ? 'primary' : 'primary_20'}
      borderRadius="50%"
      display="flex"
      justifyContent="center"
      backgroundColor={isCompleted ? 'primary' : 'white'}
    >
      <Icon.CheckSolid
        size={16}
        color={isCompleted ? 'white' : isActive ? theme.colors.primary : theme.colors.primary_20}
      />
    </Box>
    <Text.Body3 display={{ _: 'none', md: 'block' }} pl={16}>
      {title}
    </Text.Body3>
  </StepWrapper>
);

const Divider = ({ isPreviousCompleted }) => (
  <Box width="100%" height={2} borderRadius="sm" backgroundColor="primary_20" mx={16}>
    <TransitionWrapper
      height="100%"
      width={isPreviousCompleted ? '100%' : 0}
      backgroundColor="primary"
    />
  </Box>
);

const StepIndicator = ({ steps, onStepChange, externalCurrentStep }) => {
  const [currentStep, setCurrentStep] = useState(externalCurrentStep);

  useEffect(() => {
    setCurrentStep(externalCurrentStep);
  }, [externalCurrentStep]);

  const handleClick = (selectedStep) => {
    if (selectedStep !== currentStep) {
      setCurrentStep(selectedStep);
      onStepChange && onStepChange(selectedStep);

      const eventName = steps[selectedStep]?.eventName;
      eventName && trackCustomGA4Event({ eventName });
    }
  };

  const isCurrentStep = (index) => currentStep === index;
  const isStepCompleted = (index) => currentStep > index;

  return (
    <Box display="flex" alignItems="center">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          {index > 0 && (
            <Divider isPreviousCompleted={isCurrentStep(index) || isStepCompleted(index)} />
          )}
          <StepBubble
            title={step.title}
            isActive={isCurrentStep(index)}
            isCompleted={isStepCompleted(index)}
            handleClick={!step.disabled ? () => handleClick(index) : null}
            index={index}
          />
        </React.Fragment>
      ))}
    </Box>
  );
};

export default StepIndicator;
