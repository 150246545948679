import axios from './axios';

const ORDER = 'https://order.' + process.env.API_DOMAIN;
const ORDER_URL = ORDER + '/v1/orders';
const PAY_URL = ORDER + '/v1/pay';

export const updateOrder = ({
  uniqueId,
  email,
  products,
  tax,
  paymentPlatform,
  currency,
  coupon,
  metadata,
}) =>
  axios
    .post(ORDER_URL, {
      unique_id: uniqueId,
      email,
      products,
      tax,
      payment_platform: paymentPlatform,
      currency,
      coupon,
      metadata,
    })
    .then((response) => response.data);

export const pay = ({ uuid }) =>
  axios
    .get(PAY_URL, {
      params: {
        uid: uuid,
      },
    })
    .then((response) => response.data);

export const updateOrderAndPay = ({
  uniqueId,
  email,
  products,
  tax,
  paymentPlatform,
  currency,
  coupon,
  metadata,
}) =>
  updateOrder({ uniqueId, email, products, tax, paymentPlatform, currency, coupon, metadata }).then(
    () =>
      pay({ uuid: uniqueId }).then((data) => {
        if (data.error) {
          throw data.error;
        }
        return data;
      })
  );
