import React from 'react';
import styled, { css } from 'styled-components';
import { background, space } from 'styled-system';

import { Box } from '@core';
import { ArrowLink, Text } from '@components';
import { query } from '@shared/style-helpers';

const SectionContainer = styled(Box)`
  font-family: ${({ theme }) => theme?.fonts?.default};

  ${({ image }) =>
    image &&
    (typeof image === 'object'
      ? css`
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          @media ${query.maxMobile} {
            background-image: ${`url(${image.mobile})`};
          }
          @media ${query.tablet} {
            background-image: ${`url(${image.tablet})`};
          }
          @media ${query.minDesktop} {
            background-image: ${`url(${image.desktop})`};
          }
        `
      : css`
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          background-image: ${`url(${image})`};
        `)}

  ${background}
`;

const SectionContentWrapper = styled('div')`
  width: 100%;
  height: 100%;
  max-width: 1280px;
  padding: 40px 0;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${space}
`;

const SectionTextWrapper = styled('div')`
  width: 100%;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;

  ${space}
`;

const Section = ({
  containerProps,
  textWrapperProps,
  wrapperProps,
  title,
  titleProps,
  description,
  link,
  children,
  footer: Footer,
  ...rest
}) => (
  <SectionContainer {...containerProps} {...rest}>
    <SectionContentWrapper {...wrapperProps}>
      {(title || description) && (
        <SectionTextWrapper px={{ _: 24, sm: 40 }} pb={{ _: 48, sm: 64 }} {...textWrapperProps}>
          {title && (
            <Text.Heading2 textAlign="center" {...titleProps}>
              {title}
            </Text.Heading2>
          )}
          {description && (
            <Text.Body1 as="div" mt={32} textAlign="center">
              {description}
            </Text.Body1>
          )}
          {link?.link && link?.title && (
            <Box mt={8} display="flex" justifyContent="center">
              <ArrowLink link={link.link} customEvent={link?.customEvent}>
                {link.title}
              </ArrowLink>
            </Box>
          )}
        </SectionTextWrapper>
      )}
      {children}
      {Footer && <Footer />}
    </SectionContentWrapper>
  </SectionContainer>
);

export default Section;
