import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid } from '@core';
import { DoubleTextButton, LinkWrapper, Text } from '@components';
import { ImageCore } from '@components/image';

const CTAImage = styled(ImageCore)`
  object-fit: cover;
  transform: translateX(-50%);
`;

const HeaderTextBlock = ({ data }) => {
  const { t } = useTranslation();

  const { title, description, buttons } = data.text({
    t,
  });

  return (
    <Grid.Row>
      <Grid.Col maxWidth={588}>
        {title && <Text.Heading1 textAlign="left">{title}</Text.Heading1>}
        {description && (
          <Text.Body1 as="div" width={{ lg: 430, xl: 520 }} mt={48}>
            {description}
          </Text.Body1>
        )}
        {buttons && (
          <Grid.Row mt={32}>
            {buttons.map((button, index) => (
              <Box width={280} key={index} mr={index < buttons.length - 1 && 24}>
                <LinkWrapper link={button.link} withParams>
                  {button.subtitle ? (
                    <DoubleTextButton
                      type="button"
                      size="large"
                      fullWidth
                      title={button.title}
                      subtitle={button.subtitle}
                      {...button.buttonProps}
                    />
                  ) : (
                    <Button type="button" size="large" fullWidth {...button.buttonProps}>
                      {button.title}
                    </Button>
                  )}
                </LinkWrapper>
              </Box>
            ))}
          </Grid.Row>
        )}
      </Grid.Col>
    </Grid.Row>
  );
};

const HeaderDesktop = ({ data }) => {
  const { desktop: image } = data.image();

  return (
    <Grid.Container pt={120} pb={80} px={40} position="relative">
      <HeaderTextBlock data={data} />
      {image && (
        <CTAImage
          src={image?.src}
          alt={image?.alt}
          loading={image?.loading}
          position="absolute !important" // !important is for gatsby image to work properly in build
          zIndex={-1}
          left="50%"
          width="100%"
          {...image?.position}
        />
      )}
    </Grid.Container>
  );
};

export default HeaderDesktop;
