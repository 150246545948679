import React, { useEffect, useState } from 'react';
import { addMilliseconds, getTime } from 'date-fns';

import { useTimer } from '@hooks';

const TimerContext = React.createContext();

const isTimePassed = (milliseconds) => milliseconds - getTime(new Date()) < 0;

const getTimerDate = (milliseconds) => addMilliseconds(new Date(), milliseconds);

export const TimerProvider = ({ children, milliseconds, doubleDigits }) => {
  const [timerDate, setTimerDate] = useState(getTime(getTimerDate(milliseconds)));
  const [isClientSideReady, setIsClientSideReady] = useState(false);

  useEffect(() => {
    const previousTimerDate = parseInt(window.localStorage.getItem('timerDate'));
    if (previousTimerDate && !isTimePassed(previousTimerDate)) {
      setTimerDate(previousTimerDate);
    } else {
      window.localStorage.setItem('timerDate', timerDate.toString());
    }
    setIsClientSideReady(true);
  }, [timerDate]);

  const remainingTime = useTimer({
    toDate: timerDate,
    doubleDigits,
    isClientSideReady,
  });

  return <TimerContext.Provider value={remainingTime}>{children}</TimerContext.Provider>;
};

export const useTimerContext = () => {
  const context = React.useContext(TimerContext);

  if (context === undefined) {
    return null;
  }

  return context;
};
