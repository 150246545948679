import { graphql, useStaticQuery } from 'gatsby';

const plansQuery = graphql`
  {
    allSitePage(filter: { path: { eq: "/get" } }) {
      edges {
        node {
          pageContext
        }
      }
    }
  }
`;

export const usePlansQuery = () => {
  const context = {};
  const data = {
    defaultPlans: [],
  };
  return { data };
};
