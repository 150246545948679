import React from 'react';
import styled from 'styled-components';

import { query } from '@shared/style-helpers';
import { Text } from '@components';

const StickerContainer = styled('div')`
  color: ${({ theme }) => theme.colors.white};
  padding: 0 24px;
  border-radius: 8px;
  width: max-content;
  max-width: 220px;

  z-index: 2;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -55%);

  @media ${query.minDesktop} {
    padding: 0 28px;
  }

  background-color: ${({ isHighlighted, theme }) =>
    isHighlighted ? theme.colors.danger : theme.colors.darkBlue_40};
`;

const CardSticker = ({ isHighlighted, variant, children }) => (
  <StickerContainer isHighlighted={isHighlighted} variant={variant}>
    <Text.Body3 $lineHeight={'24px'} color="inherit" textAlign="center">
      {children}
    </Text.Body3>
  </StickerContainer>
);

export default CardSticker;
