import { Box } from '@core';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { DealModal } from '@containers';
import { Animation } from '@components';
import { usePlansValuesContext } from '@context';
import { formatLink } from '@shared/format-helpers';
import { trackCustomGA4Event } from '@shared/google-utils';
import { GA4_EVENTS } from '@shared/constants/google';

const handleClose = (modalElement) => {
  modalElement.setAttribute('style', 'display:none');
  trackCustomGA4Event({
    eventName: GA4_EVENTS.modalClose,
  });
};

const ModalWrapper = () => {
  const { suggestedPlan } = usePlansValuesContext();
  const { t } = useTranslation();
  const wrapperRef = useRef(null);
  return (
    <div ref={wrapperRef} id="deal-modal" style={{ display: 'none' }}>
      <Box position="fixed" width="100vw" height="100vh" zIndex={10002}>
        <Animation.FadeIn duration={300}>
          <Box
            backgroundColor="darkBlue_60"
            position="absolute"
            width="100vw"
            height="110vh"
            top="-10vh"
            opacity="0.8"
          />
          <Box display="flex" alignItems="center" justifyContent="center" height="80vh">
            <DealModal
              discountPercentage={suggestedPlan?.discountPercentage}
              hideModal={() => handleClose(wrapperRef.current)}
              buttonLink={formatLink(t('navigation:paths.get'))}
            />
          </Box>
        </Animation.FadeIn>
      </Box>
    </div>
  );
};

export default ModalWrapper;
