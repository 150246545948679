export const DEFAULT_COUPON_CODE = 'atlaswelcome';
export const DEALS_COUPON_CODE = 'PRIVACY24';

export const DEFAULT_CAMPAIGN_CODE = 'PRIVACY2024';

export const ERROR_MESSAGES = {
  validationError: 'validation_error',
  couponNotFound: 'coupon_not_found',
  couponNotValid: 'coupon_not_valid',
  couponExceededLimit: 'coupon_exceeded_limit',
  couponIsNotActive: 'coupon_is_not_active', // when coupon's active time hasn't started or has ended (deactivated coupon)
  couponIsNotApplicable: 'coupon_is_not_applicable',
  failedToApplyCoupon: 'failed_to_apply_coupon',
  failedToVerifyCoupon: 'failed_to_verify_coupon',
  couponRetrieveFailed: 'coupon_retrieve_failed',
  couponPercentValueIsInvalid: 'coupon_percent_value_is_invalid',
  couponAmountValueIsInvalid: 'coupon_amount_value_is_invalid',
  couponExtraTimeValueIsInvalid: 'coupon_extra_time_value_is_invalid',
  extraTimeValueIsInvalid: 'extra_time_value_is_invalid',
  periodParsingFailed: 'period_parsing_failed',
};
