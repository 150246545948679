import theme from './theme';

const klarnaTheme = {
  buttons: {
    ...theme.buttons,
    default: {
      primary: {
        color: '#000000',
        default: '#FFB3C7',
        hover: '#E5A8B7',
        active: '#E5A8B7',
        disabled: theme.colors.darkBlue_40,
      },
    },
  },
};

export default klarnaTheme;
