import React from 'react';

import { Box } from '@core';
import { Icon, Text } from '@components';

const CheckMarkBullet = ({ title }) => (
  <Box display="flex" my={16} columnGap={16}>
    <Icon.Check size={20} color="success" />
    <Text.Body4Strong as="h2" color="darkBlue_80">
      {title}
    </Text.Body4Strong>
  </Box>
);

export default CheckMarkBullet;
