import React from 'react';

const GlobalStateContext = React.createContext();
const GlobalDispatchContext = React.createContext();

export const GlobalContextProvider = ({ children, isInBuilder = false }) => {
  return (
    <GlobalStateContext.Provider
      value={{
        getIpLocation: {},
        isInBuilder,
      }}
    >
      <GlobalDispatchContext.Provider value={{}}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
};

export const useGlobalStateContext = () => {
  const context = React.useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalStateContext must be used within a GlobalContextProvider');
  }
  return context;
};

export const useGlobalDispatchContext = () => {
  const context = React.useContext(GlobalDispatchContext);
  if (context === undefined) {
    throw new Error('useGlobalDispatchContext must be used within a GlobalContextProvider');
  }
  return context;
};
