import React from 'react';
import styled from 'styled-components';
import { space } from 'styled-system';

const ContentWrapper = styled.div`
  max-width: ${({ isBlogPost }) => (isBlogPost ? '760px' : '1160px')};
  margin-left: auto;
  margin-right: auto;
  font-family: ${({ theme }) => theme?.fonts?.default};
  color: #3b3b4d;
  ${space}
`;
export const PageContentWrapper = ({ children, isBlogPost, ...rest }) => (
  <ContentWrapper isBlogPost={isBlogPost} {...rest}>
    {children}
  </ContentWrapper>
);

export default PageContentWrapper;
