import React from 'react';

import { Box, Grid, Show } from '@core';
import {
  AvailablePlatforms,
  SectionContainerSC as SectionContainer,
  Shape,
  ShapeWrapper,
  StoreRatings,
} from '@components';
import { theme } from '@theme';

import { HeaderDesktop, HeaderMobile } from './layouts';

const HeaderWrapper = ({ shape, children }) => (
  <ShapeWrapper
    shape={Shape[shape] || Shape.Blob8}
    shapeProps={{ height: { _: '100%', md: '95%' } }}
  >
    <SectionContainer wrapperProps={{ pt: { _: 80, lg: 0 }, pb: { _: 40, lg: 20 } }}>
      {children}
    </SectionContainer>
  </ShapeWrapper>
);

const Header = ({ shape, showRatingsAndPlatforms, ...props }) => (
  <Box data-section-id="CTA">
    <Show.TabletLarge>
      <HeaderWrapper shape={shape}>
        <HeaderMobile {...props} showRatingsAndPlatforms={showRatingsAndPlatforms} />
      </HeaderWrapper>
      {showRatingsAndPlatforms && (
        <Grid.Row justifyContent="center" mb={40}>
          <AvailablePlatforms variant={theme?.variants?.availablePlatforms} centered />
        </Grid.Row>
      )}
    </Show.TabletLarge>

    <Show.DesktopAndUp>
      <HeaderWrapper shape={shape}>
        <HeaderDesktop {...props} />
      </HeaderWrapper>
      {showRatingsAndPlatforms && (
        <SectionContainer>
          <Grid.Row px={40} justifyContent="space-between">
            <Box display="flex" columnGap={64}>
              <StoreRatings.Apple variant={theme.variants?.storeRatings} />
              <StoreRatings.Google variant={theme.variants?.storeRatings} />
            </Box>
            <AvailablePlatforms variant={theme.variants?.availablePlatforms} />
          </Grid.Row>
        </SectionContainer>
      )}
    </Show.DesktopAndUp>
  </Box>
);

export default Header;
