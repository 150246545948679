import theme from './theme';

const dealsTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    expandedNavBarItemTitle: theme.colors.darkBlue_20,
    fontDefault: theme.colors.white,
    backgroundColor: theme.colors.darkBlue_140,
    pickerBackground: theme.colors.darkBlue,

    timer: {
      backgroundColor: '#000720',
      borderColor: '#273858',
      numberBackground: theme.colors.primary_20,
      numberColor: theme.colors.darkBlue_140,
      titleColor: theme.colors.white,
      numberLabelColor: '#9093A2',
    },
    timerRedesigned: {
      backgroundColor: theme.colors.darkBlue,
      numberColor: theme.colors.white,
      numberColorSeconds: theme.colors.danger,
      titleColor: theme.colors.white,
    },
    cardBackground: theme.colors.darkBlue_120,
    planCardBackground: '#EAF2FF',
    footerGradientColor: theme.colors.footerDefault,
  },
  buttons: {
    ...theme.buttons,
    default: {
      primary: {
        color: theme.colors.white,
        default: theme.colors.danger,
        hover: theme.colors.danger_80,
        active: theme.colors.danger_120,
        disabled: theme.colors.darkBlue_40,
      },
    },
  },
  variants: {
    timerBarLogo: 'white',
    navBarLogo: 'white',
    storeRatings: 'light',
    availablePlatforms: 'light',
    footer: 'default',
    featuresTabs: 'dark',
  },
  newsOutlets: {
    titleColor: theme.colors.darkBlue_20,
    logoColor: theme.colors.darkBlue_20,
  },
};

export default dealsTheme;
