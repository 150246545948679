import React from 'react';
import styled from 'styled-components';

import { ImageCore } from '@components';
import closeButton from '@images/deals/close-button.svg';

const HeaderContainer = styled('div')`
  width: 100%;
  height: 44px;
  padding-left: 28px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #ff7373;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;

  @media (min-width: 1024px) {
    height: 56px;
  }
`;

const HeaderLabelContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const HeaderLabel = styled('p')`
  font-size: 24px;
  font-weight: bold;
  margin-right: ${({ marginRight }) => marginRight && `${marginRight}px`};
`;

const HeaderButton = styled('button')`
  padding: 14px;
  cursor: pointer;
  border: none;
  background: none;
  display: flex;

  &:focus {
    outline: 0;
  }

  @media (min-width: 1024px) {
    padding: 20px;
  }
`;

const DealsModalHeader = ({ hideModalHandler, label, timer }) => {
  const { hours, minutes, seconds } = timer || {};
  const timeLeft = `${hours} : ${minutes} : ${seconds}`;

  return (
    <HeaderContainer>
      <HeaderLabelContainer>
        {label && <HeaderLabel marginRight={36}>{label}</HeaderLabel>}
        {timer && <HeaderLabel>{timeLeft}</HeaderLabel>}
      </HeaderLabelContainer>
      <HeaderButton onClick={hideModalHandler}>
        <ImageCore src={closeButton} alt="close" />
      </HeaderButton>
    </HeaderContainer>
  );
};

export default DealsModalHeader;
