// custom typefaces
import '@fontsource/poppins/latin.css';
import '@fontsource/poppins/latin-ext.css';
import '@fontsource-variable/sora';
import '@fontsource/noto-sans/cyrillic.css';
import '@fontsource/noto-sans/latin.css';
import '@fontsource/noto-sans/latin-ext.css';

import firebaseConfig from './firebase.json';

import React from 'react';
import { ThemeProvider } from 'styled-components';

import { PageWrapper } from '@containers';
import { GlobalContextProvider } from '@context';
import { toast } from '@core';

import theme from '@theme/theme';

import 'normalize.css';
import './src/styles/global-styles.css';
import { navigate } from 'gatsby';

export const wrapPageElement = ({ element, props }) => {
  return <PageWrapper {...props}>{element}</PageWrapper>;
};

export const wrapRootElement = ({ element }) => (
  <GlobalContextProvider>
    <ThemeProvider theme={theme}>{element}</ThemeProvider>
  </GlobalContextProvider>
);

export const onRouteUpdate = ({ location, prevLocation }) => {
  firebaseConfig.hosting.redirects.forEach((redirects) => {
    return (
      !(location.pathname === '/blog/announcement' || location.pathname === '/blog/faq') &&
      (location.pathname === redirects.source || location.pathname + '/' === redirects.source) &&
      navigate(redirects.destination, { replace: true })
    );
  });
};

export const onPreRouteUpdate = ({ location, prevLocation }) => {
  if (location?.pathname !== prevLocation?.pathname) {
    toast.dismiss();
  }
};
