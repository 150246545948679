import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const StyledContainer = styled('div')`
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(41, 44, 77, 0.4);

  transition-duration: 200ms;
  transition-property: opacity;
  opacity: ${({ wasMounted }) => (wasMounted ? 1 : 0)};
`;

const ModalContainer = ({ children, hideModal }) => {
  const [wasMounted, setWasMounted] = useState(false);

  useEffect(() => {
    setWasMounted(true);
  }, []);

  return (
    <StyledContainer
      wasMounted={wasMounted}
      onClick={() => {
        // To close modal, when pressing outside of it
        hideModal();
      }}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </div>
    </StyledContainer>
  );
};

export default ModalContainer;
