import { useEffect, useState } from 'react';
import {
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInSeconds,
  isBefore,
  isSameSecond,
} from 'date-fns';

const convertToString = (value) => `${value}`;

const getFormattedNumber = (number) => {
  return number < 10 ? `0${number}` : convertToString(number);
};

const getConvertedTime = (timeDiff) => ({
  days: convertToString(timeDiff.days),
  hours: convertToString(timeDiff.hours),
  minutes: convertToString(timeDiff.minutes),
  seconds: convertToString(timeDiff.seconds),
});

const withDoubleDigits = (timeDiff) => ({
  days: getFormattedNumber(timeDiff.days),
  hours: getFormattedNumber(timeDiff.hours),
  minutes: getFormattedNumber(timeDiff.minutes),
  seconds: getFormattedNumber(timeDiff.seconds),
});

const getDiff = (currentTime, deadlineTime) => {
  return isBefore(currentTime, deadlineTime)
    ? {
        days: differenceInDays(deadlineTime, currentTime),
        hours: differenceInHours(deadlineTime, currentTime) % 24,
        minutes: differenceInMinutes(deadlineTime, currentTime) % 60,
        seconds: differenceInSeconds(deadlineTime, currentTime) % 60,
      }
    : { days: 0, hours: 0, minutes: 0, seconds: 0 };
};

const useTimer = ({ toDate, doubleDigits = false, isClientSideReady }) => {
  const [deadlineTime, setDeadlineTime] = useState(new Date(toDate));
  const [currentTime, setCurrentTime] = useState(new Date());
  const [timeDiff, setTimeDiff] = useState(getDiff(currentTime, deadlineTime));
  const [isCalculationsReadyOnClientSide, setIsCalculationsReadyOnClientSide] = useState(false);

  useEffect(() => {
    if (!isSameSecond(deadlineTime, new Date(toDate))) {
      setDeadlineTime(new Date(toDate));
      setCurrentTime(new Date());
    }
  }, [toDate, deadlineTime]);

  useEffect(() => {
    setTimeDiff(getDiff(currentTime, new Date(toDate)));
    setIsCalculationsReadyOnClientSide(isClientSideReady);
  }, [currentTime, isClientSideReady, toDate]);

  useEffect(() => {
    if (isBefore(currentTime, deadlineTime)) {
      const timer = setTimeout(() => setCurrentTime(new Date()), 1000);
      return () => clearTimeout(timer);
    }
  });

  return doubleDigits
    ? { ...withDoubleDigits(timeDiff), isClientSideReady: isCalculationsReadyOnClientSide }
    : { ...getConvertedTime(timeDiff), isClientSideReady: isCalculationsReadyOnClientSide };
};

export default useTimer;
