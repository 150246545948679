import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Grid } from '@core';
import { ImageCore, Text } from '@components';
import { query } from '@shared/style-helpers';

import PayPal from '@images/payment-icons/paypal.svg';
import PayPalWhite from '@images/payment-icons/paypal-white.svg';
import Visa from '@images/payment-icons/visa.svg';
import VisaWhite from '@images/payment-icons/visa-white.svg';
import MasterCard from '@images/payment-icons/mastercard.svg';
import MasterCardWhite from '@images/payment-icons/mastercard-white.svg';
import Amex from '@images/payment-icons/amex.svg';
import AmexWhite from '@images/payment-icons/amex-white.svg';
import Discover from '@images/payment-icons/discover.svg';
import DiscoverWhite from '@images/payment-icons/discover-white.svg';
import DinersClub from '@images/payment-icons/diners-club.svg';
import DinersClubWhite from '@images/payment-icons/diners-club-white.svg';
import JCB from '@images/payment-icons/jcb.svg';
import JCBWhite from '@images/payment-icons/jcb-white.svg';
import GooglePay from '@images/payment-icons/google-pay.svg';
import GooglePayWhite from '@images/payment-icons/google-pay-white.svg';
import UnionPay from '@images/payment-icons/union-pay.svg';
import UnionPayWhite from '@images/payment-icons/union-pay-white.svg';

import { textVariants } from './variants';
import Link from './link';

const whiteLogos = [
  { logo: PayPalWhite, alt: 'PayPal' },
  { logo: VisaWhite, alt: 'Visa' },
  { logo: MasterCardWhite, alt: 'MasterCard' },
  { logo: UnionPayWhite, alt: 'Union Pay' },
  { logo: AmexWhite, alt: 'Amex' },
  { logo: DiscoverWhite, alt: 'Discover' },
  { logo: DinersClubWhite, alt: 'Diners Club' },
  { logo: JCBWhite, alt: 'JCB' },
  { logo: GooglePayWhite, alt: 'Google Pay' },
];

const coloredLogos = [
  { logo: PayPal, alt: 'PayPal' },
  { logo: Visa, alt: 'Visa' },
  { logo: MasterCard, alt: 'MasterCard' },
  { logo: UnionPay, alt: 'Union Pay' },
  { logo: Amex, alt: 'Amex' },
  { logo: Discover, alt: 'Discover' },
  { logo: DinersClub, alt: 'Diners Club' },
  { logo: JCB, alt: 'JCB' },
  { logo: GooglePay, alt: 'Google Pay' },
];

const logoVariants = {
  default: whiteLogos,
  light: coloredLogos,
  blue: coloredLogos,
};

const PaymentLogosWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;

  @media ${query.maxTabletLarge} {
    margin-bottom: 8px;
    justify-content: center;
  }

  @media ${query.maxMobile} {
    flex-wrap: wrap;
    width: 320px;
  }
`;

const Copyright = ({ elements, variant }) => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();

  return (
    <Grid.Container>
      <Grid.Row
        justifyContent={{ _: 'center', lg: 'space-between' }}
        flexDirection={{ _: 'column', lg: 'row' }}
        alignItems="center"
        flexWrap="nowrap"
        mb={{ lg: 24 }}
      >
        <Grid.Col
          size={{ _: 12, lg: 6 }}
          display="flex"
          flexWrap={{ _: 'wrap', md: 'unset' }}
          justifyContent={{ _: 'center', lg: 'space-between' }}
          columnGap={{ _: 16, md: 40 }}
          rowGap={8}
          mb={{ _: 8, lg: 0 }}
        >
          {elements.map(({ label, link, customEvent, onClick }) => (
            <Link
              mb={0}
              key={label}
              label={label}
              link={link}
              variant={variant}
              customEvent={customEvent}
              onClick={onClick}
            />
          ))}
        </Grid.Col>
        <PaymentLogosWrapper>
          {logoVariants[variant].map(({ logo, alt }) => (
            <ImageCore src={logo} alt={alt} key={alt} m={{ _: 8, lg: 'unset' }} />
          ))}
        </PaymentLogosWrapper>
      </Grid.Row>
      <Grid.Row justifyContent="center">
        <Text.Body3 color={textVariants[variant]} textAlign="center">
          {t('navigation:footer.rightsReserved', { year: currentYear })}
        </Text.Body3>
      </Grid.Row>
    </Grid.Container>
  );
};

export default Copyright;
