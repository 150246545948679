import theme from './theme';

const sofortTheme = {
  buttons: {
    ...theme.buttons,
    default: {
      primary: {
        color: '#FFFFFF',
        default: '#2E3235',
        hover: '#464B50',
        active: '#0E1011',
        disabled: theme.colors.darkBlue_40,
      },
    },
  },
};

export default sofortTheme;
