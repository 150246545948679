import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMutation } from 'react-query';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';

import { OFFERINGS, STORES, getPlans } from '@api/checkout';
import { updateOrder } from '@api/order';
import {
  ERROR_MESSAGES as COUPON_ERROR_MESSAGES,
  DEFAULT_COUPON_CODE,
} from '@shared/constants/coupons';
import { DEFAULT_CURRENCY } from '@shared/constants/currencies';
import { defaultErrorHandler, isCouponError } from '@shared/checkout-error-helpers';
import { CANCELED_REQUEST } from '@shared/constants/api';
import { getUUID } from '@shared/local-storage-tracking';
import { toCamelCase } from '@shared/helpers';
import { useDebounce } from '@hooks';

const getNotEqualDefault = (parameter, defaultParameter) =>
  !!(parameter && parameter.toLowerCase() !== defaultParameter.toLowerCase());

const PlansValuesContext = React.createContext({ plans: [] }); // default value needed for LandingPageHandler to handle cases when LandingPagePreview doesn't render context provider

const CouponErrorContext = React.createContext();

const SelectedCardContext = React.createContext();

const PlansParametersAndActionsContext = React.createContext({ plansParameters: {}, actions: {} }); // default value to avoid errors for components inside LandingPageHandler until plans context provider loads

export const PlansProvider = ({
  initialPlans = [],
  initialOffer,
  initialCoupon,
  initialCurrency,
  customDefaultCoupon,
  children,
}) => {
  // destructuring separate parameters, for this useEffect so it doesn't get called when plansParameters are set to object with the same values

  const plansValues = useMemo(
    () => ({
      isLoading: false,
      plans: {},
      bestDefaultPrice: {},
      bestDefaultDiscountPercentage: {},
      suggestedPlan: {},
    }),
    []
  );

  const selectedCardData = useMemo(
    () => ({ selectedCard: {}, selectedAddon: {}, orderData: {} }),
    []
  );

  const parametersAndActions = useMemo(
    () => ({
      plansParameters: {},
      actions: {
        clearOrder: () => '',
        setSelectedCard: () => '',
        setSelectedAddon: () => '',
        setEmail: () => '',
        setPaymentPlatform: () => '',
        getByStore: () => '',
        getByOffer: () => '',
        getByCoupon: () => '',
        getByCurrency: () => '',
      },
    }),
    []
  );

  return (
    <PlansValuesContext.Provider value={plansValues}>
      <CouponErrorContext.Provider value={null}>
        <SelectedCardContext.Provider value={selectedCardData}>
          <PlansParametersAndActionsContext.Provider value={parametersAndActions}>
            {children}
          </PlansParametersAndActionsContext.Provider>
        </SelectedCardContext.Provider>
      </CouponErrorContext.Provider>
    </PlansValuesContext.Provider>
  );
};

export const usePlansValuesContext = () => {
  const context = React.useContext(PlansValuesContext);

  return context;
};

export const useCouponErrorContext = () => {
  const context = React.useContext(CouponErrorContext);

  return context;
};

export const useSelectedCardContext = () => {
  const context = React.useContext(SelectedCardContext);

  return context;
};

export const usePlansParametersAndActionsContext = () => {
  const context = React.useContext(PlansParametersAndActionsContext);

  return context;
};
