// UPDATE COMPETITOR PRICES IN COMPARISON TABLE AFTER ADDING A NEW CURRENCY!
export const CURRENCIES = {
  USD: {
    code: 'USD',
    sign: '$',
  },
  AED: {
    code: 'AED',
    sign: 'د.إ',
  },
  AUD: {
    code: 'AUD',
    sign: '$',
  },
  CAD: {
    code: 'CAD',
    sign: '$',
  },
  CNY: {
    code: 'CNY',
    sign: '¥',
  },
  EUR: {
    code: 'EUR',
    sign: '€',
  },
  GBP: {
    code: 'GBP',
    sign: '£',
  },
  IDR: {
    code: 'IDR',
    sign: '₹',
  },
  JPY: {
    code: 'JPY',
    sign: '¥',
  },
  KRW: {
    code: 'KRW',
    sign: '₩',
  },
  NOK: {
    code: 'NOK',
    sign: 'kr',
  },
  PLN: {
    code: 'PLN',
    sign: 'zł',
  },
};

export const DEFAULT_CURRENCY = 'USD';
