import React from 'react';
import styled from 'styled-components';
import { layout, position, space } from 'styled-system';
import loadable from '@loadable/component';

import { filteredProps } from '@shared/style-helpers';

const LazyImage = loadable(() => import('../lazy-image/lazy-image'));

export const LOADING_OPTIONS = {
  eager: 'eager',
  lazy: 'lazy',
};

const StyledImage = styled.img.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !filteredProps.includes(prop) && defaultValidatorFn(prop),
})`
  ${space}
  ${layout}
  ${position}
`;

const Image = ({ src, alt, loading = 'lazy', ...rest }) => {
  return src && src.childImageSharp ? (
    <LazyImage
      image={src.childImageSharp.gatsbyImageData}
      alt={alt}
      loading={loading}
      imageProps={rest}
    />
  ) : (
    <StyledImage src={src} alt={alt} loading={loading} {...rest} />
  );
};

export default Image;
