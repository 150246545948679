import { breakpoints } from '@shared/style-helpers';

export const DEFAULT_FONT = 'Poppins, Noto Sans, sans-serif';
export const TITLE_FONT = 'Sora Variable, Noto Sans, sans-serif';

export const COLORS = {
  primary_120: '#3576DF',
  primary: '#4D8EF8',
  primary_80: '#7BA5F4',
  primary_60: '#9CBCF7',
  primary_40: '#BDD2F9',
  primary_20: '#DEE9FC',

  darkBlue_140: '#0e1535',
  darkBlue_120: '#1A274C',
  darkBlue: '#292D4D',
  darkBlue_80: '#54576F',
  darkBlue_60: '#7F8193',
  darkBlue_40: '#A9ABB7',
  darkBlue_20: '#D4D5DB',

  lightBlue: '#E7F7F9',
  lightBlue_80: '#ECF9FA',
  lightBlue_60: '#F1FAFB',
  lightBlue_40: '#F5FCFD',
  lightBlue_20: '#FAFDFE',

  success: '#9DC445',
  success_80: '#BCCE7B',
  success_60: '#C9D895',
  success_40: '#D7E2B0',
  success_20: '#E4EBCA',

  danger_120: '#B90C15',
  danger: '#D3313A',
  danger_80: '#D46767',
  danger_60: '#DF8D8D',
  danger_40: '#E9B3B3',
  danger_20: '#F4D9D9',

  warning: '#F2D15C',
  warning_80: '#F1DA8C',
  warning_60: '#F4E3A9',
  warning_40: '#F8EDC5',
  warning_20: '#FBF6E2',

  // Not specified in figma but used in couple of places, adding it here for now, can be discussed with designers later on.
  white_120: '#fafafa',
  white: '#ffffff',

  modalBackground: 'rgba(0, 0, 0, 0.3)',

  fontDefault: '#292D4D',

  footerDefault: '#3E4063',
  footerLight: 'white',

  backgroundColor: 'white',
  tableBackground: '#F2F6FE',
  pickerBackground: 'white',
  bannerBackground: '#171B41',

  addonBackground: 'rgba(222, 233, 252, 0.4)',
  addonCardsBackground: 'rgba(222, 233, 252, 0.2)',
};

const theme = {
  space: [], // To remove predefined styled-system spacing
  breakpoints: {
    sm: `${breakpoints.tabletSmall}`,
    md: `${breakpoints.tablet}`,
    lg: `${breakpoints.desktop}`,
    xl: `${breakpoints.widescreen}`,
    xxl: `${breakpoints.fullHd}`,
  },
  colors: COLORS,
  buttons: {
    default: {
      primary: {
        color: COLORS.white,
        default: COLORS.primary,
        hover: COLORS.primary_80,
        active: COLORS.primary_120,
        disabled: COLORS.darkBlue_40,
      },
      secondary: {
        color: COLORS.primary,
        default: COLORS.white,
        hover: COLORS.primary_80,
        active: COLORS.primary_120,
        disabled: COLORS.darkBlue_40,
      },
    },
    outlined: {
      primary: {
        color: COLORS.white,
        default: COLORS.primary,
        hover: COLORS.primary_80,
        active: COLORS.primary_120,
        disabled: COLORS.darkBlue_40,
      },
      secondary: {
        color: COLORS.primary,
        default: COLORS.white,
        hover: COLORS.primary_80,
        active: COLORS.primary_120,
        disabled: COLORS.darkBlue_40,
      },
    },
    text: {
      primary: {
        color: COLORS.white,
        default: COLORS.primary,
        hover: COLORS.primary_80,
        active: COLORS.primary_120,
        disabled: COLORS.darkBlue_40,
      },
      secondary: {
        color: COLORS.primary,
        default: COLORS.white,
        hover: COLORS.primary_80,
        active: COLORS.primary_120,
        disabled: COLORS.darkBlue_40,
      },
    },
    fontWeights: {
      small: 600,
      default: 600,
      large: 600,
      textVariant: 600,
    },
  },
  toasts: {
    default: {
      backgroundColor: COLORS.white,
      color: COLORS.darkBlue,
    },
    dark: {
      backgroundColor: COLORS.darkBlue,
      color: COLORS.white,
    },
    error: {
      backgroundColor: COLORS.danger,
      color: COLORS.white,
    },
    warning: {
      backgroundColor: COLORS.warning,
      color: COLORS.darkBlue,
    },
    success: {
      backgroundColor: COLORS.success,
      color: COLORS.darkBlue,
    },
    info: {
      backgroundColor: COLORS.lightBlue,
      color: COLORS.darkBlue,
    },
  },
  radii: {
    sm: 4,
    md: 8,
  },
  fonts: {
    default: DEFAULT_FONT,
    title: TITLE_FONT,
  },
  variants: {
    navBarLogo: 'blue',
  },
};

export default theme;
