import React from 'react';
import styled, { css } from 'styled-components';

import { LinkWrapper, Text } from '@components';
import { Box } from '@core';

import { getVariant } from '@shared/style-helpers';

const variants = {
  default: css`
    color: ${({ theme }) => theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  `,
  light: css`
    color: ${({ theme }) => theme.colors.darkBlue};

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  `,
  blue: css`
    color: ${({ theme }) => theme.colors.darkBlue};

    &:hover {
      color: ${({ theme }) => theme.colors.primary};
    }
  `,
};

const LinkText = styled.span`
  ${getVariant(variants)}
`;

const Link = ({ label, link, variant, customEvent, onClick, ...rest }) => (
  <Box mb={18} {...rest} onClick={onClick}>
    <LinkWrapper link={link} customEvent={customEvent}>
      <Text.Body2 as="span" textAlign="left">
        <LinkText variant={variant}>{label}</LinkText>
      </Text.Body2>
    </LinkWrapper>
  </Box>
);

export default Link;
