import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { Grid } from '@core';
import { Divider, SectionContainerSC as SectionContainer, Text } from '@components';
import { HELP_CENTER_LINK } from '@shared/constants/constants';

import { Copyright, Link } from './components';
import { backgroundVariants, dividerVariants, textVariants } from './components/variants';

const GridRowWithGap = styled(Grid.Row)`
  column-gap: 16px;
`;

const FooterDesktop = ({ elements = {}, variant, compact }) => {
  const { t } = useTranslation();

  return (
    <SectionContainer
      containerProps={{ backgroundColor: backgroundVariants[variant] }}
      wrapperProps={{ py: 0 }}
    >
      <Grid.Container px={40}>
        {!compact && elements.top && (
          <GridRowWithGap flexWrap="nowrap" pt={48} mb={48}>
            {elements.top.map(({ title, links }) => (
              <Grid.Col flexGrow={1} display="flex" flexDirection="column" key={title}>
                <Text.Body1Strong mb={24} color={textVariants[variant]}>
                  {title}
                </Text.Body1Strong>
                {links.map(({ link, label }) => (
                  <Link key={label} link={link} label={label} variant={variant} />
                ))}
              </Grid.Col>
            ))}
            <Grid.Col flexGrow={1} display="flex" flexDirection="column">
              <Text.Body1Strong mt={8} mb={16} color={textVariants[variant]}>
                {t('navigation:footer.needHelp')}
              </Text.Body1Strong>
              <Link
                label={t('navigation:footer.helpCenter')}
                link={HELP_CENTER_LINK}
                variant={variant}
              />
              <Link
                label={t('navigation:footer.contactUs')}
                link="https://help.atlasvpn.com/hc/en-us/articles/4410036471570-How-to-reach-Atlas-VPN-customer-support"
                variant={variant}
              />
            </Grid.Col>
          </GridRowWithGap>
        )}
        <Grid.Row pb={16}>
          <Divider
            data-testid="footer-divider-desktop"
            mt={0}
            mb={24}
            backgroundColor={dividerVariants[variant]}
          />
          <Copyright elements={elements.bottom} variant={variant} />
        </Grid.Row>
      </Grid.Container>
    </SectionContainer>
  );
};

export default FooterDesktop;
