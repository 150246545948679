// specifically ordered by priority of importance in which plan period should be selected for highlighting
// e.g plan with period P3Y is highlighted, unless such plan doesn't exist, then it's P2Y and so on.
export const HIGHLIGHTED_PLANS = ['P3Y', 'P2Y', 'P1Y', 'P1M'];

export const ONE_MONTH_PLAN = 'P1M';

export const COUNTRY_CODES_FOR_QIWI = ['AM', 'AZ', 'CY', 'GE', 'KZ', 'KG', 'MD', 'TM', 'UA', 'UZ'];

export const COUNTRY_CODES_FOR_KLARNA = ['US'];

export const COUNTRY_CODES_FOR_IDEAL = ['NL'];

export const COUNTRY_CODES_FOR_SOFORT = ['DE'];

export const COUNTRY_CODES_FOR_KAKAOPAY = ['KR'];

export const PAYMENT_METHODS = {
  APPLE_PAY: 'APPLE_PAY',
  CARD_PAYMENT: 'CARD_PAYMENT',
  GOOGLE_PAY: 'GOOGLE_PAY',
  PAYPAL: 'PAYPAL',
  QIWI: 'QIWI',
  CRYPTO: 'CRYPTO',
  KLARNA: 'KLARNA',
  IDEAL: 'IDEAL',
  SOFORT: 'SOFORT',
  KAKAOPAY: 'KAKAOPAY',
};

export const STRIPE_PAYMENT_TYPES = {
  CARD_PAYMENT: 'card',
  KLARNA: 'klarna',
  IDEAL: 'ideal',
  SOFORT: 'sofort',
};

export const PAYMENTWALL_PAYMENT_TYPES = {
  QIWI: 'qiwiwallet',
  KAKAOPAY: 'kakaopaykr',
};

export const PAYMENT_PLATFORMS = {
  APPLE_PAY: 'stripe',
  CARD_PAYMENT: 'stripe',
  GOOGLE_PAY: 'stripe',
  KLARNA: 'stripe',
  IDEAL: 'stripe',
  SOFORT: 'stripe',
  PAYPAL: 'paypal',
  QIWI: 'paymentwall',
  KAKAOPAY: 'paymentwall',
  CRYPTO: 'coinpayments',
};
