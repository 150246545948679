import loadable from '@loadable/component';

export { Slider } from './slider';
export { Show } from './show';
export { Button } from './button';

export const Box = loadable(() =>
  import(/* webpackChunkName: 'layout' */ '@atlas/core-atlas-vpn/dist/box')
);

const GridContainer = loadable(
  () => import(/* webpackChunkName: 'layout' */ '@atlas/core-atlas-vpn/dist/grid'),
  {
    resolveComponent: (components) => components.Grid.Container,
  }
);

const GridRow = loadable(
  () => import(/* webpackChunkName: 'layout' */ '@atlas/core-atlas-vpn/dist/grid'),
  {
    resolveComponent: (components) => components.Grid.Row,
  }
);

const GridCol = loadable(
  () => import(/* webpackChunkName: 'layout' */ '@atlas/core-atlas-vpn/dist/grid'),
  {
    resolveComponent: (components) => components.Grid.Col,
  }
);

export const Grid = {
  Container: GridContainer,
  Row: GridRow,
  Col: GridCol,
};

export const Accordion = loadable(() =>
  import(/* webpackChunkName: 'accordion' */ '@atlas/core-atlas-vpn/dist/accordion')
);

export const AccordionGroup = loadable(() =>
  import(/* webpackChunkName: 'accordion' */ '@atlas/core-atlas-vpn/dist/accordion-group')
);

export const Form = loadable(() => import('@atlas/core-atlas-vpn/dist/form'), {
  resolveComponent: (components) => components.Form,
});

export const Field = loadable(() => import('@atlas/core-atlas-vpn/dist/form'), {
  resolveComponent: (components) => components.Field,
});

const TextInput = loadable(() => import('@atlas/core-atlas-vpn/dist/form'), {
  resolveComponent: (components) => components.Input.Text,
});

export const Input = {
  Text: TextInput,
};

const Checkbox = loadable(() => import('@atlas/core-atlas-vpn/dist/form'), {
  resolveComponent: (components) => components.Option.Checkbox,
});

export const Option = {
  Checkbox,
};

export const SliderIndicator = loadable(() =>
  import(/* webpackChunkName: 'slider' */ '@atlas/core-atlas-vpn/dist/slider-indicator')
);

export { ToastContainer, toast } from '@atlas/core-atlas-vpn/dist/toast';
